<template>
  <div class="container mt-4 bg-white p-5 rounded-lg shadow-lg">
    <h3 class="text-primary">Add Candidate</h3>
    <ValidationObserver ref="add_candidate" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="required">First Name</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <TextInput
                  name="first_name"
                  :value="form.first_name"
                  @input="updateField"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="required">Last Name</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <TextInput
                  name="surname"
                  :value="form.surname"
                  @input="updateField"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="required">Email</label>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <TextInput
                  name="email"
                  type="email"
                  :value="form.email"
                  @input="updateField"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="required">Confirm Email</label>
              <ValidationProvider
                rules="required|email|emailConfirmed:email"
                v-slot="{ errors }"
              >
                <TextInput
                  name="confirm_email"
                  type="email"
                  :value="form.confirm_email"
                  @input="updateField"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="required">Phone</label>
              <ValidationProvider
                ref="phone"
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                }"
              >
                <PhoneInputwithCountry
                  name="phone"
                  :value="form.phone"
                  :options="
                    options && options['dialingCode']
                      ? options['dialingCode']
                      : []
                  "
                  @change="updateField"
                  :error="errors[0]"
                  ref="phoneNo"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="required"> Profession or Field of Study </label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <Select
                  name="candidate_type_id"
                  :value="form.candidate_type_id"
                  :options="(options && options['candidateType']) || []"
                  :taggable="false"
                  :multiple="false"
                  :clearable="false"
                  @input="updateField"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="required">Organisation</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <Select
                  name="organisation_id"
                  :value="form.organisation_id"
                  :options="(options && options['organisationList']) || []"
                  :taggable="false"
                  :multiple="false"
                  :clearable="false"
                  @input="updateField"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="form-group d-flex justify-content-center mt-5">
          <CButton
            class="mr-2"
            type="submit"
            color="secondary"
            @click="$router.push('/recruiter-dashboard')"
          >
            Cancel
          </CButton>
          <CButton type="submit" color="primary"> Submit </CButton>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { confirmed, regex } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";
import Select from "@/components/reusable/Fields/Select";
import { getLocalOrgDetail } from "@/helpers/helper";

extend("emailConfirmed", {
  ...confirmed,
  message:
    "The email addresses entered do not match. Please correct and try again.",
});
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});

export default {
  components: {
    TextInput,
    PhoneInputwithCountry,
    Select,
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapGetters([
      "dialingCodeWithCountryCode",
      "candidateType",
      "organisationList",
      "getCustomerId",
    ]),
    options() {
      return {
        dialingCode: this.dialingCodeWithCountryCode || [],
        candidateType: this.candidateType || [],
        organisationList:
          this.organisationList.map((v) => {
            return {
              label: v.organisation.name,
              code: v.organisation_id,
            };
          }) || [],
      };
    },
    getPathAcronym() {
      let { path_acronym } = getLocalOrgDetail();
      return path_acronym;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "initQuickRegisterFetchOptions",
      "createCandidateByAdmin",
      "fetchOrganisationListForCustomer",
    ]),
    async onSubmit() {
      const isValid = await this.$refs.add_candidate.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let payload = {
        ...this.form,
        candidate_type_id: this.form.candidate_type_id.code,
        organisation_id: this.form.organisation_id.code,
        path_acronym: this.getPathAcronym
      };
      await this.createCandidateByAdmin(payload).then(() => {
        this.$router.push("/candidate-list");
      });
    },
    updateField(name, value) {
      this.form[name] = value;
    },
    fetchOrganisationList() {
      this.fetchOrganisationListForCustomer({
        customer_uid: this.getCustomerId,
        queryPayload: {
          organisations___organisation_type_id: 13,
        },
      });
    },
  },
  mounted() {
    this.initQuickRegisterFetchOptions();
    this.fetchOrganisationList();
  },
};
</script>
