import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const candidate = {
  candidateLevel: (query) => axios.get(`${BASE_URL}/candidate/level/?order_by=-seniority&${query}`),
  recruitingCandidateLevel: () => axios.get(`${BASE_URL}/candidate/level/jobs/`),
  candidateType: () => axios.get(`${BASE_URL}/candidate/type/?order_by=candidate_type_id`),
  create: (customer, payload) => axios.post(`${BASE_URL}/customer/${customer}/candidate/`, payload),
  update: (customer, payload) => axios.put(`${BASE_URL}/customer/${customer}/candidate/`, payload),
  document: (candidateUid, queryString, payload) => axios.post(`${BASE_URL}/candidate/${candidateUid}/document?${queryString}`, payload),
  getCandidate: (customer, id) => axios.get(`${BASE_URL}/customer/${customer}/candidate/id/${id}/`),
  getTitles: () => axios.get(`${BASE_URL}/title/`),
  getLocations: () => axios.get(`${BASE_URL}/location/location/?order_by=location`),
  getOrganisationBasedLocation: (query) => axios.get(`${BASE_URL}/customer/organisation/locations?${query}`),
  getUAELocations: () => axios.get(`${BASE_URL}/location/location/?location_id__notin=7`),
  getUserMe: (customer, path_acronym) => axios.get(`${BASE_URL}/customer/${customer}/candidate/my?acronym=${path_acronym}`),
  getDocumentType: (query) => axios.get(`${BASE_URL}/documents/types?${query}`),
  getUploadedDocument: (candidateUid, query) => axios.get(`${BASE_URL}/candidate/${candidateUid}/document${query ? query : ``}`),
  updateDocument: (candidateUid, candidate_document_id, payload) => axios.put(`${BASE_URL}/candidate/${candidateUid}/document/${candidate_document_id}`, `comments=${payload}`),
  updateDocument_foraction: (customerId, candidateUid, candidate_document_id, payload) => axios.put(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/document/${candidate_document_id}`, `actionable=${payload}`),
  deleteDocument: (candidateUid, candidate_document_id) => axios.delete(`${BASE_URL}/candidate/${candidateUid}/document/${candidate_document_id}/`),
  getLicense: (candidateUid) => axios.get(`${BASE_URL}/candidate/${candidateUid}/licenses/`),
  getSkills: (customerId, candidateUid) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/skill/`),
  createLicense: (candidateUid, payload) => axios.post(`${BASE_URL}/candidate/${candidateUid}/licenses/`, payload),
  createSkill: (customer, candidateUid, payload) => axios.post(`${BASE_URL}/customer/${customer}/candidate/${candidateUid}/skill/`, payload),
  getCandidateLanguage: (candidateUid) => axios.get(`${BASE_URL}/candidate/${candidateUid}/language/`),
  createCandidateLanguage: (candidateUid, payload) => axios.post(`${BASE_URL}/candidate/${candidateUid}/language/`, payload),
  deleteLanguage: (candidateUid, candidate_language_id) => axios.delete(`${BASE_URL}/candidate/${candidateUid}/language/${candidate_language_id}`),
  getCandidateQualification: ( candidateUid) => axios.get(`${BASE_URL}/candidate/${candidateUid}/qualification/`),
  createQualification: (candidateUid, payload) => axios.post(`${BASE_URL}/candidate/${candidateUid}/qualification/`, payload),
  deleteSkills: (customerId, candidateUid, candidate_skill_id) => axios.delete(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/skill/${candidate_skill_id}`),
  deleteLicense: (candidateUid, candidate_license_id) => axios.delete(`${BASE_URL}/candidate/${candidateUid}/licenses/${candidate_license_id}`),
  deleteQualification: (candidateUid, candidate_qualification_id, query) => axios.delete(`${BASE_URL}/candidate/${candidateUid}/qualification/${candidate_qualification_id}?${query}`,),
  createSpecialInterest: (candidateUid, payload) => axios.post(`${BASE_URL}/candidate/${candidateUid}/speciality/`, payload),
  deleteSpecialInterest: (candidateUid, candidate_speciality_id) => axios.delete(`${BASE_URL}/candidate/${candidateUid}/speciality/${candidate_speciality_id}`),
  getCandidateSpecialInterest: (candidateUid) => axios.get(`${BASE_URL}/candidate/${candidateUid}/speciality/`),
  getCandidatePQRLevels: (customerId, candidateUid) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/pqr_levels/`),
  checkPQRLevel: ({ customerId, candidateUid }) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/pqr_levels/check_pqr_levels`),
  updateLanguagesSpoken: (candidateUid, candidate_language_id, payload) => axios.put(`${BASE_URL}/candidate/${candidateUid}/language/${candidate_language_id}`, payload),
  updateCandidateLicense: (candidateUid, payload) => axios.put(`${BASE_URL}/candidate/${candidateUid}/licenses/`, payload),
  updateCandidateSplInterest: (candidateUid, payload) => axios.put(`${BASE_URL}/candidate/${candidateUid}/speciality/`, payload),
  updateCandidateSkill: (customerId, candidateUid, payload) => axios.put(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/skill/`, payload),
  updateCandidateQualification: ( candidateUid, candidate_qualification_id, payload) => axios.put(`${BASE_URL}/candidate/${candidateUid}/qualification/${candidate_qualification_id}`, payload),
  getDisplayData: () => axios.get(`${BASE_URL}/display_data/`),
  createCandidatePreferredRegions: ({ customerId, candidateUid, payload }) => axios.post(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/region/`, payload),
  createCandidatePreferredCountry: ({ customerId, candidateUid, payload }) => axios.post(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/preferred_country/`, payload),
  getCandidatePreferredRegionList: ({ customerId, candidateUid }) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/region/`),
  getCandidatePreferredCountryList: ({ customerId, candidateUid }) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/preferred_country/`),
  deleteCandidatePreferredRegion: ({ customerId, candidateUid, preferred_region_id }) => axios.delete(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/region/${preferred_region_id}`),
  deleteCandidatePreferredCountry: ({ customerId, candidateUid, preferred_country_id }) => axios.delete(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/preferred_country/${preferred_country_id}`),
  jobListForCandidateID: ({ candidate_uid, statusSubStatus, queryString }) => { return axios.post(`${BASE_URL}/jobcandidate/match/candidate/${candidate_uid}?${queryString}`, statusSubStatus) },
  candidateJobMatch: ({ customer_uid, candidate_uid }) => axios.post(`${BASE_URL}/jobcandidate/match/${customer_uid}/candidate/${candidate_uid}`),
  jobListForCandidateIDCount: ({ candidate_uid, status_id }) => { return axios.get(`${BASE_URL}/jobcandidate/match/count/?candidate_uid=${candidate_uid}&status_id=${status_id}`) },
  getCandidateIDByMail: (email) => { return axios.get(`${BASE_URL}/candidate/candidate_uid/?email=${email}`) },
  getNonMedicalQualNames: (query) => axios.get(`${BASE_URL}/non_medical_qualification/?${query}`),
  getScoreByNonMedicalQualName: (qualification_name, customer_id) => axios.get(`${BASE_URL}/non_medical_qualification/${qualification_name}?customer_id=${customer_id}`),
  getScoreByNonMedicalQualID: (qualification_id, customer_id) => axios.get(`${BASE_URL}/non_medical_qualification/id/${qualification_id}?customer_id=${customer_id}`),
  getCandidateNonMedicalQual: (customer_id, candidate_id) => axios.get(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/non_medical_qualification`),
  createCandidateNonMedicalInfo: (customer_id, payload) => axios.post(`${BASE_URL}/customer/${customer_id}/candidate/non_medical_qualification/`, payload),
  updateNonMedicalInfo: (customer_id, candidate_non_medical_qual_id, payload) => axios.put(`${BASE_URL}/customer/${customer_id}/candidate/non_medical_qualification/${candidate_non_medical_qual_id}/`, payload),
  deleteNonMedicalInfo: (customer_id, candidate_id, candidate_info_id) => axios.delete(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/non_medical_qualification/${candidate_info_id}`),
  getUpdateStatusSubStatus: ({ tab_id, filter_id, action_id, queryString }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/get_update_status?${queryString}${action_id ? `&action_id=${action_id}` : ''}`),
  updateJobCandidateStatus: ({ job_id, candidate_uid, payload }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_uid}`, payload),
  getCandidateComments: (candidate_id) => axios.get(`${BASE_URL}/candidate/${candidate_id}/job_candidate_comment/?all_rows=true`),
  createCandidateComment: (candidate_id, payload) => axios.post(`${BASE_URL}/candidate/${candidate_id}/job_candidate_comment/`, payload),
  deleteCandidateComment: (candidate_id, comment_id) => axios.delete(`${BASE_URL}/candidate/${candidate_id}/job_candidate_comment/${comment_id}`),
  department: (organisation_id) => axios.get(`${BASE_URL}/department/${organisation_id}`),
  getPreferredDepartments: (customer_uid, candidate_uid) => axios.get(`${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uid}/preferred/department/by/candidate`),
  addPreferredDepartment: (customer_uid, candidate_uid, payload) => axios.post(`${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uid}/preferred/department/`, payload),
  updatePreferredDepartment: ({ customer_uid, candidate_uid, candidate_preferred_departments_id, payload }) => axios.put(`${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uid}/preferred/department/${candidate_preferred_departments_id}`, payload),
  deletePreferredDepartment: ({ customer_uid, candidate_uid, candidate_preferred_departments_id }) => axios.delete(`${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uid}/preferred/department/${candidate_preferred_departments_id}`),
  getNurseRegistrationType: ({ customer_id }) => axios.get(`${BASE_URL}/customer/${customer_id}/candidate/nurse/registration/type`),
  getRegisteredHospitalsByEmail: ({ queryString }) => axios.get(`${BASE_URL}/candidate/email/registered/hospital?${queryString}`),
  registerCommonCandidate: (payload) => axios.post(`${BASE_URL}/shared/candidate/create`, payload),
  registerCommonCandidateWithoutPwd: (payload) => axios.post(`${BASE_URL}/shared/candidate/create/withoutpassword`, payload),
  generateRandomUuid: () => axios.get(`${BASE_URL}/customer/static/files/generate/random/uuid`),
  uploadTempDoc: ({ uuid, formData }) => axios.post(`${BASE_URL}/customer/static/files/candidate/temporary/docs?uuid=${uuid}`, formData),
  getTempDocs: (queryString) => axios.get(`${BASE_URL}/customer/static/files/candidate/temporary/docs?${queryString}`),
  deleteTempDocs: (uuid) => axios.delete(`${BASE_URL}/customer/static/files/candidate/temporary/docs?uuid=${uuid}`),
  getCandidateExpOrgTypes: () => axios.get(`${BASE_URL}/organisation/type/?experience=true`),
  getEnumLabels: ({ enum_name, query }) => axios.get(`${BASE_URL}/get_enum_labels/${enum_name}?${query}`),
  getOrgNature: (query) => axios.get(`${BASE_URL}/get_enum_labels/organisation_nature?${query}`),
  getOrgAdditionalInfo: (query) => axios.get(`${BASE_URL}/get_enum_labels/organisation_additional_info?${query}`),
  getCandidateExperiences: (customer_id, candidate_id) => axios.get(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/experience/`),
  createCandidateExperience: (customer_id, candidate_id, payload) => axios.post(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/experience/`, payload),
  updateCandidateExperience: (customer_id, candidate_id, payload) => axios.put(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/experience/`, payload),
  deleteCandidateExperience: (customer_id, candidate_id, experience_id) => axios.delete(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/experience/${experience_id}`),
  getCandidateExperiencesCount: (customer_id, candidate_id) => axios.get(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/experience/total_count`),
  getCandidateExperienceById: (customer_id, candidate_id, experience_id) => axios.get(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/experience/${experience_id}`),
  getJob_UploadedDocument: (job_id) => axios.get(`${BASE_URL}/job/${job_id}/document`),
  getOffers: (job_id, candidate_uid) => axios.get(`${BASE_URL}/offer?job_id=${job_id}&candidate_uid=${candidate_uid}`),
  updateJobOffer1: (job_id, candidate_uid, payload) => axios.put(`${BASE_URL}/offer/job/${job_id}/candidate/${candidate_uid}`, payload),
  fetchOffersforcandidate: (candidate_uid) => axios.get(`${BASE_URL}/offer/alloffer?candidate_uid=${candidate_uid}`),
  fetchActionDocsForCandidate: (customer_uid, query) => axios.get(`${BASE_URL}/customer/${customer_uid}/document/action/?${query}`),
  getOnboardOfferId: (offer_id) => axios.get(`${BASE_URL}/onboard/allonboard?offer_id=${offer_id}`),
  getOnboardByCandidateAndJob: (offer_id) => axios.get(`${BASE_URL}/onboard/allonboard?offer_id=${offer_id}`),
  updateOnboardStatus: (offer_id, payload) => axios.put(`${BASE_URL}/onboard/offer/${offer_id}`, payload),
  getJob_UploadedDocument: (job_id) => axios.get(`${BASE_URL}/job/${job_id}/document`),
  getOffers: (job_id, candidate_uid) => axios.get(`${BASE_URL}/offer?job_id=${job_id}&candidate_uid=${candidate_uid}`),
  updateJobOffer: (job_id, candidate_uid, payload) => axios.put(`${BASE_URL}/offer/job/${job_id}/candidate/${candidate_uid}`, `status=${payload}`),
  fetchCandidateBands: () => axios.get(`${BASE_URL}/candidate/band/?all_rows=true&fetch_row_count=false`),
  getRegisteredOhrCandidateByEmail: ({ queryString }) => axios.get(`${BASE_URL}/migratedcandidate/candidate/email/?${queryString}`),
  createCandidateRecord: (job_id, candidate_uid, customer_id, organisation_id, organisation_document_id, actionable) => axios.post(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_uid}/document/insert_candidate_record?job_id=${job_id}&organisation_id=${organisation_id}&organisation_document_id=${organisation_document_id}`, `actionable=${actionable}`),
  deleteCandidaterecord: (customer_id, candidate_uid, candidate_document_id) => axios.delete(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_uid}/document/${candidate_document_id}/record`),
  createDocumentAction: (customer_uid, organisation_id, payload) => axios.post(`${BASE_URL}/customer/${customer_uid}/organisation/${organisation_id}/document/action/`, payload),
  createAgencyCVRequest: (payload) => axios.post(`${BASE_URL}/agency/cv/transaction`, payload),
  getJobCandidateFrontSheet: (queryString) => axios.get(`${BASE_URL}/job_candidate_front_sheet/?${queryString}`),
  addJobCandidateFrontSheet: (payload) => axios.post(`${BASE_URL}/job_candidate_front_sheet/`, payload),
  jcpNegativeFlow: (query) => axios.get(`${BASE_URL}/action_detail/negative_flow/?${query}`),
  jobListForCandidate: (query) => axios.get(`${BASE_URL}/jobcandidate/match/all/jobs?${query}`),
  checkCandidateInTFW: (query) => axios.get(`${BASE_URL}/candidate/email/registered/tfw?${query}`),
  getCandidateOrganisations: () => axios.get(`${BASE_URL}/candidate/get_all_candidate_organisations`),
  getCandidateOrganisationsByAdmin: (user_id) => axios.get(`${BASE_URL}/candidate/get_candidate_registered_organisations/${user_id}`),
  candidateLoginAccessToken: (query='') => axios.post(`${BASE_URL}/login/switch-access-token/candidate?${query}`),
  getStudentQualifications: (professional_type) => axios.get(`${BASE_URL}/qualification/student_qualification/${professional_type}`),
  getSponsorsList: ()  => axios.get(`${BASE_URL}/sponsorship_deals/`),
  getCourseTestTypes: ()  => axios.get(`${BASE_URL}/course_test_types/`),
  getSponsoredDetails: (candidate_uid) => axios.get(`${BASE_URL}/sponsored_candidates/${candidate_uid}`),
  getLicenseRegTypeDetails: () => axios.get(`${BASE_URL}/license_reg_type/`),
  getCourseCount: (customer_uid, organisation_id) => axios.get(`${BASE_URL}/training_courses/get_training_courses_stats/${customer_uid}/${organisation_id}`),
  createCandidateByAdmin: (payload) => axios.post(`${BASE_URL}/customer/${payload.customer_id}/candidate/create_user_and_candidate/`, payload)
}

export default candidate;
