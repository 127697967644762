import agencyCustomer from "@/services/agencyCustomer";
import customer from "@/services/customer";
import router from "../router/index";
import {
  getFilterQueryStringWithoutArray,
  isObject,
  isEmptyObjectCheck,
} from "@/helpers/helper.js";

const getDefaultState = () => {
  return {
    customerTypeList: [],
    customerList: [],
    isCustomerFetch: false,
    selectedCustomer: {},
    organisationList: [],
    isOrganisationFetch: false,
    districts: [],
    allOrganisationList: [],
    getUserUploadedDocuments: [],
    selectedCustomerVariant: {},
    getJobuploadedDocument: [],
    documentTypeforAdmin: [],
    documentTypeforOffer: [],
    documentTypeForOnboard: [],
    uploadedJob: false,
    getparentdetails: {},
    getcomments: {},
    getorgdocsid: {},
    getCustomerTemplates: [],
    getCustomerTemplate: [],
    order_by_column: "-created_on"
  };
};

const state = getDefaultState();

const getters = {
  getorgdocsid: (state) => state.getorgdocsid,
  uploadedJob: (state) => state.uploadedJob,
  getCustomerTypeList: (state) =>
    state.customerTypeList && state.customerTypeList.length
      ? state.customerTypeList.map((val) => ({
          label: val.comments,
          code: val.customer_type_id,
        }))
      : [],
  getUserUploadedDocuments: (state) => state.getUserUploadedDocuments,
  getJobuploadedDocument: (state) => state.getJobuploadedDocument,
  customerList: (state) => state.customerList,
  isCustomerFetch: (state) => state.isCustomerFetch,
  selectedCustomer: (state) => state.selectedCustomer,
  organisationList: (state) => state.organisationList,
  isOrganisationFetch: (state) => state.isOrganisationFetch,
  districts: (state) => state.districts,
  getDistrictsByLocationsID:
    (state, getters, rootState) =>
    ({ location_id }) => {
      return state.districts && state.districts.length
        ? state.districts
            .filter((val) =>
              Array.isArray(location_id)
                ? location_id.includes(val?.location_id)
                : val?.location_id === location_id
            )
            .map((val) => ({
              code: val?.id,
              label: val?.district,
              location_id: val?.location_id,
            }))
        : [];
    },
  getAllOrganisationList: (state) => state.allOrganisationList,
  getSelectedCustomerVariant: (state) => state.selectedCustomerVariant,
  getparentdetails: (state) => state.getparentDetails,
  documentTypeforAdmin: (state) =>
    state.documentTypeforAdmin && state.documentTypeforAdmin.length
      ? state.documentTypeforAdmin.map((val) => ({
          label: val.document_name,
          code: val.document_type_id,
          documenttitle_id: val.documenttitle_id,
          allowed_extensions: val.allowed_extensions,
        }))
      : [],
  documentTypeforOffer: (state) =>
    state.documentTypeforOffer && state.documentTypeforOffer.length
      ? state.documentTypeforOffer.map((val) => ({
          label: val.document_name,
          code: val.document_type_id,
          documenttitle_id: val.documenttitle_id,
          allowed_extensions: val.allowed_extensions,
        }))
      : [],
  documentTypeForOnboard: (state) =>
    state.documentTypeForOnboard && state.documentTypeForOnboard.length
      ? state.documentTypeForOnboard.map((val) => ({
          label: val.document_name,
          code: val.document_type_id,
          documenttitle_id: val.documenttitle_id,
          allowed_extensions: val.allowed_extensions,
        }))
      : [],
  getActionComments: (state) => state.getcomments,
  actionComments: (state) =>
    state.getcomments && state.getcomments.length
      ? state.getcomments.map((val) => ({
          label: val?.comments,
          // code: val?.comments_type_id,
        }))
      : [],
  getCustomerTemplates: (state) => state.getCustomerTemplates,
  getCustomerTemplate: (state) => state.getCustomerTemplate,
  getOrderByColum: (state) => state.order_by_column,
};

const mutations = {
  ["SET_UPLOADEDJOB"](state) {
    state.uploadedJob = true;
    setTimeout(() => {
      state.uploadedJob = false;
    }, 500);
  },
  ["SET_DOCUMENTADMIN_TYPE"](state, payload) {
    state.documentTypeforAdmin = payload;
  },
  ["SET_DOCUMENTOFFER_TYPE"](state, payload) {
    state.documentTypeforOffer = payload;
  },
  ["SET_DOCUMENTONBOARD_TYPE"](state, payload) {
    state.documentTypeForOnboard = payload;
  },
  ["SET_JOB_UPLOADED_DOCUMENT"](state, data) {
    state.getJobuploadedDocument = data;
  },
  ["SET_USER_UPLOADED_DOCUMENT"](state, data) {
    state.getUserUploadedDocuments = data;
  },
  ["SET_CUSTOMER_TYPE_LIST"](state, payload) {
    state.customerTypeList = payload;
  },
  ["SET_CUSTOMER_LIST"](state, payload) {
    state.customerList = payload;
  },
  ["SET_IS_CUSTOMER_FETCH"](state, payload) {
    state.isCustomerFetch = payload;
  },
  ["SET_SELECTED_CUSTOMER"](state, payload) {
    state.selectedCustomer = payload;
  },
  ["SET_ORGANISATION_LIST"](state, payload) {
    state.organisationList = payload;
  },
  ["RESET_ORGANISATION_LIST"](state) {
    state.organisationList = [];
  },
  ["SET_IS_ORGANISATION_FETCH"](state, payload) {
    state.isOrganisationFetch = payload;
  },
  ["SET_DISTRICTS"](state, payload) {
    state.districts = payload;
  },
  ["SET_ALL_ORGANISATION_LIST"](state, payload) {
    state.allOrganisationList = payload;
  },
  ["SELECTED_CUSTOMER_VARIANT"](state, payload) {
    let variant = Array.isArray(payload)
      ? payload?.filter((val) => val.key == "VARIANT")[0]
      : payload;
    state.selectedCustomerVariant = variant.value;
  },
  ["RESET_JOB_UPLOADED_DOCUMENT"](state, data) {
    state.getjobuploadedDocument = [];
  },
  ["SET_PARENT_DETAILS"](state, data) {
    state.getparentDetails = data;
  },
  ["RESET_PARENT_DETAILS"](state, data) {
    state.getparentDetails = [];
  },
  ["SET_COMMENTS"](state, data) {
    state.getcomments = data;
  },
  ["RESET_COMMENTS"](state, data) {
    state.getcomments = [];
  },
  ["SET_ORG_DOCUMENT_ID"](state, data) {
    state.getorgdocsid = data;
  },
  ["SET_CUSTOMER_TEMPLATES"](state, data) {
    state.getCustomerTemplates = data;
  },
  ["SET_CUSTOMER_TEMPLATE"](state, data) {
    state.getCustomerTemplate = data;
  },
  ["UPDATE_ORDER_BY_COLUMN"](state, value) {
    state.order_by_column = value;
  }
};

const actions = {
  initUserFetchOptions: ({ state, commit, rootState, dispatch, getters }) => {
    let {
      getLocationCountryInfo,
      getCustomerTypeList,
      locations,
      getOrganisationTypeList,
      getIssueAuthority,
      districts,
    } = getters;
    commit("SET_FETCHING", true);
    let appendFilterAction = [];
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("locationCountryInfo"),
      ];
    }
    if (!getCustomerTypeList.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("fetchCustomerTypeList"),
      ];
    }
    if (!locations.length) {
      appendFilterAction = [...appendFilterAction, dispatch("getLocations")];
    }
    if (!getOrganisationTypeList.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("fetchOrganisationTypeList"),
      ];
    }
    if (!getIssueAuthority.length) {
      appendFilterAction = [...appendFilterAction, dispatch("issueAuthority")];
    }
    if (!districts.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("fetchAllDistricts"),
      ];
    }

    return Promise.all(appendFilterAction).then((res) => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  uploadUserPhoto({ commit, dispatch }, payload) {
    const {
      customer_uid,
      user_id,
      file,
      file: { src },
      document_type,
      fileName,
    } = payload;
    const type = src.split(";")[0].split(":")[1];
    dispatch("showToast", { message: "Uploading..." });
    if (user_id && customer_uid && file) {
      let query = `customer_id=${customer_uid}&user_id=${user_id}`;
      file.getCroppedCanvas().toBlob(function (blob) {
        let formData = new FormData();
        formData.append("file", blob, fileName);
        formData.append("document_type_id", document_type);
        customer
          .uploadUserPhoto(query, formData)
          .then((res) => {
            const { data } = res;
            commit("SET_UPLOADED", true);
            commit("SET_LAST_UPLOADED_DOCS", data);
            dispatch("fetchUserUploadedDocument", data.user_id);
            dispatch("showToast", {
              class: "bg-success text-white",
              message: "Uploaded successfully",
            });
            return res;
          })
          .catch((err) => {
            commit("SET_UPLOADED", true);
            let msg = "Error while uploading photo";
            dispatch("showToast", {
              class: "bg-danger text-white",
              message: msg,
            });
            return err;
          });
      }, type);
    }
  },
  deleteUserPhoto({ commit, dispatch }, payload) {
    customer
      .deleteUserPhoto(payload)
      .then((res) => {
        dispatch("fetchUserUploadedDocument", payload.user_id);
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Deleted successfully",
        });
        return res;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error while deleting",
        });
        console.log("Error while deleting", err);
        return err;
      });
  },
  fetchUserUploadedDocument({ commit, getters }, user_id) {
    const { getUser } = getters;
    let userId = getUser.id;
    if (!userId) userId = user_id;
    return customer
      .getUsersUploadedDocument(userId)
      .then((res) => {
        commit("SET_USER_UPLOADED_DOCUMENT", res.data);
      })
      .catch((err) => {
        console.log("error fetching document type", err);
        return err;
      })
      .finally(() => {});
  },
  fetchCustomerTypeList: ({ state, commit, rootState, dispatch, getters }) => {
    return agencyCustomer
      .getCustomerType()
      .then((res) => {
        commit("SET_CUSTOMER_TYPE_LIST", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error fetching customer type list", err);
        return err;
      });
  },
  addCustomer: ({ state, commit, dispatch, getters }, payload) => {
    dispatch("showToast", { message: "Saving..." });
    return agencyCustomer
      .addCustomer(payload)
      .then((res) => {
        dispatch("clearAllToast");
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Customer saved successfully",
        });
        router.push("/customer-list");
        return res;
      })
      .catch((err) => {
        let msg = "Error while Register";
        console.log("error while register customer", err);
        const { status, data } = err.response;
        if (
          status === 409 &&
          data.detail.indexOf("email") > 0 &&
          data.detail.indexOf("already exists") > 0
        ) {
          msg = "Email already exists";
        }
        dispatch("clearAllToast");
        dispatch("showToast", { class: "bg-danger text-white", message: msg });
        return err;
      });
  },
  fetchCustomerList: ({ state, commit, dispatch, getters }) => {
    commit("SET_IS_CUSTOMER_FETCH", true);
    return agencyCustomer
      .getCustomerList()
      .then((res) => {
        commit("SET_CUSTOMER_LIST", res.data);
        commit("SET_IS_CUSTOMER_FETCH", false);
        return res;
      })
      .catch((err) => {
        console.log("error fetching customer list", err);
        commit("SET_IS_CUSTOMER_FETCH", false);
        return err;
      });
  },
  updateCustomer: ({ state, commit, dispatch, getters }, customerPayload) => {
    const { customerId, payload } = customerPayload;
    dispatch("showToast", {
      class: "bg-success text-white",
      message: "Updating...",
    });
    return agencyCustomer
      .updateCustomer({ customerId, payload })
      .then((res) => {
        dispatch("clearAllToast");
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Updated successfully",
        });
        return res;
      })
      .catch((err) => {
        let msg = "Error while Customer Update";
        console.log("error while Customer Update", err);
        dispatch("clearAllToast");
        dispatch("showToast", { class: "bg-danger text-white", message: msg });
        return err;
      });
  },
  initOrganisationFetchOptions: ({
    state,
    commit,
    rootState,
    dispatch,
    getters,
  }) => {
    let {
      getLocationCountryInfo,
      locations,
      getOrganisationTypeList,
      getIssueAuthority,
      districts,
    } = getters;
    commit("SET_FETCHING", true);
    let appendFilterAction = [];
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("locationCountryInfo"),
      ];
    }
    if (!locations.length) {
      appendFilterAction = [...appendFilterAction, dispatch("getLocations")];
    }
    if (!getOrganisationTypeList.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("fetchOrganisationTypeList"),
      ];
    }
    if (!getIssueAuthority.length) {
      appendFilterAction = [...appendFilterAction, dispatch("issueAuthority")];
    }
    if (!districts.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("fetchAllDistricts"),
      ];
    }
    return Promise.all(appendFilterAction).then((res) => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  addOrganisation: ({ state, commit, dispatch, getters }, payload) => {
    dispatch("showToast", { message: "Adding..." });
    const { getOrgID: parent_org_id, getCustomerId: customer_uid } = getters;
    payload = { ...payload, parent_org_id };

    return agencyCustomer
      .addOrganisation({ customer_uid, payload })
      .then((res) => {
        const { data } = res;
        dispatch("clearAllToast");
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Organisation Added Successfully",
        });
        router.push("/organisation-list");
        return res;
      })
      .catch((err) => {
        const msg = "Error While Add Organisation";
        console.log(msg, err);
        dispatch("clearAllToast");
        dispatch("showToast", { class: "bg-danger text-white", message: msg });
        return err;
      });
  },

  addChildOrganisation: ({ dispatch }, payload) => {
    dispatch("showToast", { message: "Adding..." });
    return agencyCustomer
      .addOrganisation({ customer_uid: payload.customer_uid, payload })
      .then((res) => {
        const { data } = res;
        dispatch("clearAllToast");
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Organisation Added Successfully",
        });
        router.push("/customer-list");
        return res;
      })
      .catch((err) => {
        const msg = "Error While Add Organisation";
        console.log(msg, err);
        dispatch("clearAllToast");
        dispatch("showToast", { class: "bg-danger text-white", message: msg });
        return err;
      });
  },

  fetchOrganisationList: ({ getters, dispatch, commit }) => {
    const {
      getCustomerId: customer_uid,
      getOrgID: parent_org_id,
      getUser,
    } = getters;
    commit("SET_IS_CUSTOMER_FETCH", true);
    let queryPayload = {};
    if (parent_org_id)
      queryPayload = {
        ...queryPayload,
        parent_org_id,
        include_advertiser: true,
      };
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    return agencyCustomer
      .fetchOrganisationList({ customer_uid, queryString })
      .then((res) => {
        const { data } = res;
        commit("SET_ORGANISATION_LIST", data);
        return data;
      })
      .catch((err) => {
        console.log("Error while Fetch Organisation List", err);
        return err;
      })
      .finally((res) => {
        commit("SET_IS_CUSTOMER_FETCH", false);
      });
  },
  fetchOrganisationListForCustomer: (
    { getters, dispatch, commit },
    payload
  ) => {
    commit("SET_IS_ORGANISATION_FETCH", true);
    commit("RESET_ORGANISATION_LIST");
    let { customer_uid, parent_org_id, queryPayload } = payload;
    delete payload.queryPayload;
    let queryParams = queryPayload || {};
    if (parent_org_id)
      queryParams = {
        ...queryParams,
        parent_org_id,
        include_advertiser: true,
      };
    const queryString = getFilterQueryStringWithoutArray(queryParams);
    return agencyCustomer
      .fetchOrganisationList({ customer_uid, queryString })
      .then((res) => {
        const { data } = res;
        commit("SET_ORGANISATION_LIST", data);
        return data;
      })
      .catch((err) => {
        console.log("Error while Fetch Organisation List", err);
        return err;
      })
      .finally((res) => {
        commit("SET_IS_ORGANISATION_FETCH", false);
      });
  },
  updateOrganisation({ state, getters, dispatch, commit }, payload) {
    dispatch("showToast", {
      class: "bg-success text-white",
      message: "Updating...",
    });
    return agencyCustomer
      .updateOrganisation(payload)
      .then((res) => {
        dispatch("clearAllToast");
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Updated successfully",
        });
        return res;
      })
      .catch((err) => {
        let msg = "Error while Organisation Update";
        console.log(msg, err);
        dispatch("clearAllToast");
        dispatch("showToast", { class: "bg-danger text-white", message: msg });
        return err;
      });
  },
  getDistrictsByLocationID({ state, getters, commit }, location_id) {
    return agencyCustomer
      .getDistrictsByLocationID(location_id)
      .then((res) => {
        const { data } = res;
        commit("SET_DISTRICTS", data);
        return data;
      })
      .catch((err) => {
        console.log("error while fetch district", err);
        return err;
      });
  },
  fetchAllDistricts({ commit }) {
    return agencyCustomer
      .getDistricts()
      .then((res) => {
        const { data } = res;
        commit("SET_DISTRICTS", data);
        return data;
      })
      .catch((err) => {
        console.log("error while fetch district", err);
        return err;
      });
  },
  fetchAllOrganisationList({ commit }) {
    return customer
      .getAllOrganisation()
      .then((res) => {
        let { data } = res;
        commit("SET_ALL_ORGANISATION_LIST", data);
        return data;
      })
      .catch((err) => {
        console.log("Error while fetching Organisation", err);
        return err;
      });
  },
  getCustomerVariant({ commit }, customer_id) {
    return customer
      .getCustomerVariant(customer_id)
      .then((res) => {
        let { data } = res;
        commit("SELECTED_CUSTOMER_VARIANT", data);
        return data;
      })
      .catch((err) => {
        console.log("Error while fetching customer variant", err);
        return err;
      });
  },
  getCustomerAdvertisement({ commit }, customer_id) {
    return customer
      .getCustomerAdvertisement(customer_id)
      .then((res) => {
        let { data } = res;
        commit("SELECTED_CUSTOMER_VARIANT", data);
        return data;
      })
      .catch((err) => {
        console.log("Error while fetching customer variant", err);
        return err;
      });
  },
  updateCustomerVariant({ commit, dispatch }, payload) {
    let { customer_id } = payload;
    dispatch("showToast", {
      class: "bg-success text-white",
      message: "Updating...",
    });
    return customer
      .updateCustomerVariant(customer_id, payload)
      .then((res) => {
        let { data } = res;
        commit("SELECTED_CUSTOMER_VARIANT", data);
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Updated successfully",
        });
        return data;
      })
      .catch((err) => {
        console.log("Error while updating customer variant", err);
        return err;
      });
  },
  uploadPhotobycustomer({ commit, dispatch }, payload) {
    const {
      job_id,
      customer_uid,
      file,
      file: { src },
      document_type,
      fileName,
      documenttitle_id,
      comments,
    } = payload;
    const type = src.split(";")[0].split(":")[1];
    dispatch("showToast", { message: "Uploading..." });
    if (job_id && file) {
      file.getCroppedCanvas().toBlob(function (blob) {
        let formData = new FormData();
        formData.append("file", blob, fileName);
        formData.append("document_type_id", document_type);
        formData.append("documenttitle_id", documenttitle_id);
        formData.append("comments", comments);
        customer
          .createJobDocument(job_id, customer_uid, formData)
          .then((res) => {
            dispatch("fetchJobUploadedDocument", job_id);
            commit("SET_UPLOADEDJOB", true);
            commit("SET_LAST_UPLOADED_DOCS", res.data);
            dispatch("showToast", {
              class: "bg-success text-white",
              message: "Uploaded successfully",
            });
            return res;
          })
          .catch((err) => {
            commit("SET_UPLOADEDJOB", true);
            let msg = "Error while uploading photo";
            dispatch("showToast", {
              class: "bg-danger text-white",
              message: msg,
            });
            return err;
          })
          .finally(() => {});
      }, type);
    }
  },
  uploadDocumentbycustomer: ({ state, commit, dispatch }, payload) => {
    let { job_id, customer_uid, documenttitle_id, comments, ...rest } = payload;
    commit("SET_UPLOADEDJOB", true);
    dispatch("showToast", { message: "Uploading..." });
    const file = new FormData();
    file.append("file", rest.file, rest.file.name);
    file.append("document_type_id", rest.document_type);
    file.append("documenttitle_id", documenttitle_id);
    file.append("comments", comments);
    console.log(file);
    return customer
      .createJobDocument(job_id, customer_uid, file)
      .then((res) => {
        commit("SET_UPLOADEDJOB", true);
        dispatch("fetchJobUploadedDocument", job_id);
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "File Uploaded Successfully",
        });
        return res;
      })
      .catch((err) => {
        commit("SET_UPLOADEDJOB", true);
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error While Uploading",
        });
        console.log("Error while creating document", err);
        return err;
      });
  },
  fetchJobUploadedDocument({ commit, dispatch, getters }, job_id) {
    commit("RESET_JOB_UPLOADED_DOCUMENT");
    return customer
      .getJobUploadedDocument(job_id)
      .then(async (res) => {
        commit("SET_JOB_UPLOADED_DOCUMENT", res.data);
      })
      .catch((err) => {
        console.log("error fetching document type", err);
        return err;
      })
      .finally(() => {});
  },
  deleteJobDocument({ commit, dispatch, getters }, payload) {
    let { job_id, job_document_id } = payload;
    return customer
      .deleteJobDocument(job_id, job_document_id)
      .then(async (res) => {
        if (res.status == 200) {
          dispatch("showToast", {
            class: "bg-success text-white",
            message: "Document deleted successfully",
          });
          dispatch("fetchJobUploadedDocument", job_id);
        }
      })
      .catch((err) => {
        if (err.response.status == 500) {
          dispatch("showToast", {
            class: "bg-danger text-white",
            message: "Error while deleting document",
          });
        }
        console.log("error in deleting document", err);
        return err;
      });
  },
  // downloadDocument(
  //   { state, commit, rootState, dispatch, getters },
  //   { url, document_name }
  // ) {
  //   dispatch("showFixedToast",
  //     { class: 'bg-secondary text-white', message: 'Preparing to Download...' })
  //   return customer
  //     .downloadDocumentFile(url)
  //     .then(res => {
  //       let { data } = res;
  //       const fileName = document_name;
  //       const link = document.createElement("a");
  //       if (link.download !== undefined) {
  //         const url = URL.createObjectURL(data);
  //         link.setAttribute("href", url);
  //         link.setAttribute("download", fileName);
  //         link.style.visibility = "hidden";
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //       }
  //       dispatch("hideFixedToast")
  //     })
  //     .catch(err => {
  //       dispatch("hideFixedToast")
  //       dispatch("showToast",
  //         { class: 'bg-danger text-white', message: 'Download failed. Try again.' })
  //       console.error("error in Candidate Details", err);
  //       return err;
  //     });
  // },
  updateJobDocument({ commit, dispatch, getters }, payload) {
    const { job_id, job_document_id, comments } = payload;
    return customer
      .updateJobDocument(job_id, job_document_id, comments)
      .then(async (res) => {
        if (res.status == 200) {
          dispatch("showToast", {
            class: "bg-success text-white",
            message: "Document updated successfully",
          });
          dispatch("fetchJobUploadedDocument", job_id);
        }
        return res.data;
      })
      .catch((err) => {
        if (err.response.status == 500) {
          dispatch("showToast", {
            class: "bg-danger text-white",
            message: "Error while updating document",
          });
        }
        console.log("error in updating document", err);
        return err;
      });
  },

  getCustomerDetails({ state, getters, commit }, customer_id) {
    return customer
      .getCustomer_Details(customer_id)
      .then((res) => {
        const { data } = res;
        return data;
      })
      .catch((err) => {
        console.log("Error while fetching Organisation Additional Info", err);
      });
  },
  create_offer: ({ state, commit, dispatch, getters }, payload) => {
    let { job_id, customer_uid, candidate_uid, ...rest } = payload;
    const { getCustomerId, getOrgIDFromAccessToken } = getters;
    return customer
      .createOffer(job_id, candidate_uid, {
        ...rest,
        customer_uid: getCustomerId,
        organisation_id: getOrgIDFromAccessToken,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("Error while creating Offer", err);
        return err;
      });
  },

  create_Onboard: ({ state, commit, dispatch }, payload) => {
    let { ...rest } = payload;
    return customer
      .createOnboard({ ...rest })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("Error while creating Onboard", err);
        return err;
      });
  },
  getOrgDetailsById: ({ state, commit, getter }, payload) => {
    let { organisation_id } = payload;
    return customer
      .getOrgDetails(organisation_id)
      .then((res) => {
        commit("SET_PARENT_DETAILS", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("Error while Getting Organisation", err);
        return err;
      });
  },
  fetchCommentsfor_recruiter: ({ state, commit, getter }, payload) => {
    commit("RESET_COMMENTS");
    let { status_id, sub_status_id } = payload;
    let queryPayload = {};
    if (status_id) queryPayload = { ...queryPayload, status_id };
    if (sub_status_id) queryPayload = { ...queryPayload, sub_status_id };
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    return customer
      .getCommentsfor_recruiter({ queryString })
      .then((res) => {
        commit("SET_COMMENTS", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("Error while Getting Comments", err);
        return err;
      });
  },
  fetchCommentsfor_candidateInterviewer: (
    { state, commit, getter },
    payload
  ) => {
    commit("RESET_COMMENTS");
    let { status_id, sub_status_id } = payload;
    let queryPayload = {};
    if (status_id) queryPayload = { ...queryPayload, status_id };
    if (sub_status_id) queryPayload = { ...queryPayload, sub_status_id };
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    return customer
      .getCommentsfor_candidateInterviewer({ queryString })
      .then((res) => {
        commit("SET_COMMENTS", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("Error while Getting Comments", err);
        return err;
      });
  },
  fetchCommentsfor_jobapproval: ({ state, commit, getter }) => {
    return customer
      .getCommentsfor_jobapproval()
      .then((res) => {
        commit("SET_COMMENTS", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("Error while Getting Comments", err);
        return err;
      });
  },
  fetchDocumentIdBytitleId: ({ state, commit, getters }, payload) => {
    const { documenttitle_id } = payload;
    const { getOrgIDFromAccessToken } = getters;
    return customer
      .getOrgDocumentsbytitleId(getOrgIDFromAccessToken, documenttitle_id)
      .then((res) => {
        commit("SET_ORG_DOCUMENT_ID", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("Error while Getting Org Docs Id", err);
        return err;
      });
  },
  createjobRecord: ({ state, commit, getter, dispatch }, payload) => {
    const { job_id, customer_uid, organisation_id, organisation_document_id } =
      payload;
    return customer
      .createjobRecord(
        job_id,
        customer_uid,
        organisation_id,
        organisation_document_id
      )
      .then((res) => {
        dispatch("fetchJobUploadedDocument", job_id);
        commit("SET_UPLOADEDJOB", true);
        commit("SET_LAST_UPLOADED_DOCS", res.data);
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Uploaded successfully",
        });
        return res;
      })
      .catch((err) => {
        commit("SET_UPLOADEDJOB", true);
        let msg = "Error while uploading photo";
        dispatch("showToast", { class: "bg-danger text-white", message: msg });
        return err;
      })
      .finally(() => {});
  },
  deletejobrecord: ({ state, commit, getter, dispatch }, payload) => {
    let { job_id, job_document_id } = payload;
    return customer
      .deletejobrecord(job_id, job_document_id)
      .then(async (res) => {
        if (res.status == 200) {
          dispatch("showToast", {
            class: "bg-success text-white",
            message: "Document deleted successfully",
          });
          dispatch("fetchJobUploadedDocument", job_id);
        }
      })
      .catch((err) => {
        if (err.response.status == 500) {
          dispatch("showToast", {
            class: "bg-danger text-white",
            message: "Error while deleting document",
          });
        }
        console.log("error in deleting document", err);
        return err;
      });
  },
  fetchCustomerTemplates: ({ state, commit, getter }) => {
    return customer
      .getCustomerTemplates()
      .then((res) => {
        const groupedData = res.data.reduce((acc, item) => {
          const key = `${item.customer_id}-${item.organisation_id}`;

          if (!acc[key]) {
            acc[key] = {
              customer_id: item.customer_id,
              organisation_id: item.organisation_id,
              customer_name: item?.customer?.name || "",
              organisation_name: item?.organisation?.name || "",
              templates: [],
            };
          }

          acc[key].templates.push({
            customer_template_id: item.customer_template_id,
            template_id: item.template_id,
            template_order: item.template_order,
            status: item.status,
            template_fields: item.template_fields,
          });

          return acc;
        }, {});

        const result = Object.values(groupedData);

        commit("SET_CUSTOMER_TEMPLATES", result);
        return res.data;
      })
      .catch((err) => {
        console.log("Error while Getting Customer Templates", err);
        return err;
      });
  },

  fetchCustomerTemplate: ({ state, commit, getters }, payload) => {
    let { customer_id, org_id } = payload;
    return customer
      .getCustomerTemplate(customer_id, org_id)
      .then((res) => {
        if (res.status == 200) {
          const sortedData = res.data.sort(
            (a, b) => a.template_order - b.template_order
          );
          commit("SET_CUSTOMER_TEMPLATE", sortedData ?? []);
        } else {
          commit("SET_CUSTOMER_TEMPLATE", []);
        }

        return res.data;
      })
      .catch((err) => {
        commit("SET_CUSTOMER_TEMPLATE", []);
        console.log("Error while Getting Customer Template", err);
        return err;
      });
  },
  createCustomerTemplate: ({ state, commit, getters, dispatch }, finalPayload) => {
    let { customer_id, org_id, payload } = finalPayload;
    return customer
      .createCustomerTemplate(customer_id, org_id, payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch("showToast", {
            class: "bg-success text-white",
            message: "Template added successfully",
          });
        }
        return res.data;
      })
      .catch((err) => {
        console.log("Error while creating Customer Template", err);
        return err;
      });
  },
  deleteCustomerTemplate: ({ state, commit, getters, dispatch }, template_id) => {
    const { getCustomerId, getOrgIDFromAccessToken } = getters;
     return customer
       .deleteCustomerTemplate(template_id)
       .then((res) => {
         if (res.status == 200) {
           dispatch("showToast", {
             class: "bg-danger text-white",
             message: "Template deleted successfully",
           });
           const payload = {
             customer_id: getCustomerId,
             org_id: getOrgIDFromAccessToken,
           };
           dispatch("fetchCustomerTemplate", payload);
         }
         return res.data;
       })
       .catch((err) => {
         console.log("Error while deleting Customer Template", err);
         return err;
       });
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
