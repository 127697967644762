<template>
  <CHeaderNav
    v-if="
      currentUserRole !== Role.systemCandidate &&
      currentUserRole !== Role.customerCandidate &&
      currentUserRole !== Role.customerUser
    "
    class="d-md-down-none mr-auto ml-3 d-flex align-items-center"
    style="max-height: 45px; min-height: 45px"
  >
    <CHeaderNavItem v-for="item in getAcl" :key="item.menu">
      <CDropdown
        class="nav-dropdown px-2 pt-2"
        add-menu-classes="pt-0"
        :ref="item.menu"
        :click="toggleClass(item.menu)"
        v-on:mouseover.native="show(item.menu)"
        v-on:mouseleave.native="hide(item.menu)"
        :disabled="item.disabled"
      >
        <template #toggler-content>
          <div
            class="d-flex align-items-center text"
            v-if="
              ['dashboard', 'students'].includes(item.menu) &&
              isEducationalInstitute
            "
          >
            <div
              v-if="item.menu === 'dashboard'"
              @click="navigateTo('/recruiter-dashboard', item.menu)"
            >
              <em :class="`${getMenuIcon(item.menu)} mr-1`"></em>
              <span class="ml-2 header-nav">{{ item.label }}</span>
            </div>
            <div
              v-if="item.menu === 'students'"
              @click="navigateTo('/candidate-list', item.menu)"
            >
              <em :class="`${getMenuIcon(item.menu)} mr-1`"></em>
              <span class="ml-2 header-nav">{{ item.label }}</span>
            </div>
          </div>
          <div
            v-else-if="['profile_page'].includes(item.menu)"
            @click="navigateTo('/profile-page', item.menu)"
          >
            <em :class="`${getMenuIcon(item.menu)} mr-1`"></em>
            <span class="ml-2 header-nav">{{ item.label }}</span>
          </div>
          <div class="d-flex align-items-center text" v-else>
            <em :class="`${getMenuIcon(item.menu)} mr-1`"></em>
            <span class="ml-2 header-nav">{{ item.label }}</span>
          </div>
        </template>
        <div v-if="item.menu_items.length">
          <div v-for="sub_menu in item.menu_items" :key="sub_menu.item">
            <CDropdown
              v-if="
                sub_menu.menu === 'view/edit_job_board' ||
                sub_menu.menu === 'view_job_board'
              "
              addTogglerClasses="sub-dropdown-item"
              placement="right-start"
              :ref="sub_menu.menu"
              v-on:mouseover.native="show(sub_menu.menu)"
              v-on:mouseleave.native="hide(sub_menu.menu)"
              :disabled="sub_menu.disabled"
            >
              <template #toggler-content>
                <div class="d-flex align-items-center">
                  <em class="fas fa-list-alt" style="margin-right: 10px"></em>
                  <span>{{
                    sub_menu.menu === "view_job_board"
                      ? "View Job Board"
                      : "View/Edit Job Board"
                  }}</span>
                </div>
              </template>
              <CDropdownItem
                to="/jobs-list/status/active"
                :click="toggleClass('jobs')"
              >
                <em class="fas fa-briefcase" style="margin-right: 10px"></em>
                Active Jobs
              </CDropdownItem>
              <CDropdownItem
                to="/jobs-list/status/inactive"
                :click="toggleClass('jobs')"
              >
                <em
                  class="fas fa-calendar-times"
                  style="margin-right: 10px"
                ></em>
                Inactive Jobs
              </CDropdownItem>
              <CDropdownItem
                to="/jobs-list/status/archive"
                :click="toggleClass('jobs')"
              >
                <em class="fas fa-square-xmark" style="margin-right: 10px"></em>
                Archive Jobs
              </CDropdownItem>
            </CDropdown>

            <CDropdown
              v-else-if="sub_menu.menu === 'organisation'"
              addTogglerClasses="sub-dropdown-item"
              placement="right-start"
              :ref="sub_menu.menu"
              v-on:mouseover.native="show(sub_menu.menu)"
              v-on:mouseleave.native="hide(sub_menu.menu)"
              :disabled="sub_menu.disabled"
            >
              <template #toggler-content>
                <div class="d-flex align-items-center">
                  <em class="fas fa-hospital" style="margin-right: 10px"></em>
                  <span>Organisation</span>
                </div>
              </template>
              <CDropdownItem to="/add-organisation">
                <em class="fas fa-hospital" style="margin-right: 10px"></em>
                Add Organisation
              </CDropdownItem>
              <CDropdownItem to="/organisation-list">
                <em class="fas fa-hospital" style="margin-right: 10px"></em>
                {{
                  isEducationalInstitute
                    ? "Organisation List"
                    : "List Organisation"
                }}
              </CDropdownItem>
            </CDropdown>

            <CDropdown
              v-else-if="sub_menu.menu === 'users'"
              addTogglerClasses="sub-dropdown-item"
              placement="right-start"
              :ref="sub_menu.menu"
              v-on:mouseover.native="show(sub_menu.menu)"
              v-on:mouseleave.native="hide(sub_menu.menu)"
              :disabled="sub_menu.disabled"
            >
              <template #toggler-content>
                <div class="d-flex align-items-center">
                  <em
                    class="fas fa-user-friends"
                    style="margin-right: 10px"
                  ></em>
                  <span>Users</span>
                </div>
              </template>
              <CDropdownItem
                to="/add-contactuser"
                :click="toggleClass('settings')"
              >
                <em class="fas fa-user-plus" style="margin-right: 10px"></em>
                Add User
              </CDropdownItem>
              <CDropdownItem to="/list-contactuser">
                <em class="fas fa-users" style="margin-right: 10px"></em>
                {{ isEducationalInstitute ? "User List" : "List User" }}
              </CDropdownItem>
            </CDropdown>
            <CDropdown
              v-else-if="sub_menu.menu === 'tutorial_groups'"
              addTogglerClasses="sub-dropdown-item"
              placement="right-start"
              :ref="sub_menu.menu"
              v-on:mouseover.native="show(sub_menu.menu)"
              v-on:mouseleave.native="hide(sub_menu.menu)"
              :disabled="sub_menu.disabled"
            >
              <template #toggler-content>
                <div class="d-flex align-items-center">
                  <em
                    class="fas fa-user-friends"
                    style="margin-right: 10px"
                  ></em>
                  <span>Tutorial Groups</span>
                </div>
              </template>
              <CDropdownItem>
                <em
                  class="fas fa-people-group"
                  style="margin-right: 10px; font-size: 15px"
                ></em>
                Create Group
              </CDropdownItem>
            </CDropdown>

            <CDropdown
              v-else-if="sub_menu.menu === 'config'"
              addTogglerClasses="sub-dropdown-item"
              placement="right-start"
              :ref="sub_menu.menu"
              v-on:mouseover.native="show(sub_menu.menu)"
              v-on:mouseleave.native="hide(sub_menu.menu)"
              :disabled="sub_menu.disabled"
            >
              <template #toggler-content>
                <div class="d-flex align-items-center">
                  <em class="fas fa-cog mr-1"></em>
                  <span>Config</span>
                </div>
              </template>
              <CDropdownItem
                to="/theme-and-logo"
                :click="toggleClass('config')"
              >
                <em class="fas fa-images mr-2"></em>
                Theme and Logo
              </CDropdownItem>
              <CDropdownItem
                to="/config-public-job-board"
                :click="toggleClass('config')"
                v-if="!isEducationalInstitute"
              >
                <em class="fas fa-briefcase mr-2"></em>
                Public Job Board
              </CDropdownItem>
              <CDropdownItem
                to="/onboarding-documents"
                :click="toggleClass('config')"
                v-if="!isEducationalInstitute"
              >
                <em class="fas fa-folder-open mr-2"></em>
                Document library
              </CDropdownItem>
              <CDropdownItem
                to="/email-templates"
                :click="toggleClass('config')"
              >
                <em class="fas fa-envelope mr-2"></em>
                Email Config
              </CDropdownItem>
              <CDropdownItem
                to="/campaign-register"
                :click="toggleClass('config')"
              >
                <em class="fas fa-file-pen mr-2"></em>
                Campaign Form
              </CDropdownItem>
            </CDropdown>
            <CDropdownItem
              v-else
              :to="getPathForMenu(sub_menu.menu, item)"
              :disabled="sub_menu.disabled"
            >
              <em :class="`${getSubMenuIcon(sub_menu.menu)} mr-2`"></em
              >{{ sub_menu.label }}
            </CDropdownItem>
          </div>
        </div>
      </CDropdown>
    </CHeaderNavItem>
  </CHeaderNav>
</template>

<script>
import { Role, TFW_CANDIDATE_URL } from "@/helpers/helper";
import { mapGetters } from "vuex";

export default {
  name: "TheDynamicNavHeaders",
  data() {
    return {
      Role,
    };
  },
  computed: {
    ...mapGetters([
      "getRoleScopes",
      "getUserAcl",
      "getCandidateListUrlBasedOnRole",
      "isJobSupplierFromAccessToken",
      "isSuperCustomer",
      "isServiceProvider",
      "getCustomerTypeIDFromAccessToken",
    ]),
    getAcl() {
      let to_disabled_menus = ["Profile Page"];
      if (this.isJobSupplierFromAccessToken)
        to_disabled_menus = [
          ...to_disabled_menus,
          { menu: "Jobs", menu_items: ["Create Job"] },
          { menu: "Settings", menu_items: ["Job Templates"] },
        ];
        
      return this.getUserAcl?.map((val) => {
        const disabled_menus =
          to_disabled_menus.filter(({ menu }) => menu === val?.menu) || [];
        const isMenuDisabled =
          disabled_menus.length > 0 &&
          disabled_menus[0].menu_items.length === 0;
        return {
          label: val?.menu,
          menu: val.menu?.toLowerCase().replaceAll(" ", "_"),
          menu_items: val?.menu_items?.map((item) => {
            const disabled =
              (disabled_menus.length &&
                disabled_menus[0].menu_items.includes(item)) ||
              false;
            return {
              label: item,
              menu: item.toLowerCase().replaceAll(" ", "_"),
              disabled,
            };
          }),
          disabled: isMenuDisabled,
        };
      });
    },
    isCurrentUserTypeRecruiter() {
      return (this.currentUserType && this.currentUserType.code === 0) || false;
    },
    currentUserRole() {
      const curRole = this.getRoleScopes.length
        ? this.isCustomerRecruiterUser && !this.isCurrentUserTypeRecruiter
          ? "customer_user"
          : this.getRoleScopes[0]
        : "";
      const roleCheck = {
        customer_recruiter: "customer_recruiter",
        customer_admin: "customer_admin",
      };
      return this.getRoleScopes.length
        ? this.currentUserType?.code
          ? roleCheck[this.currentUserType?.code] || "customer_user"
          : this.getRoleScopes[0]
        : "";
    },
    isEducationalInstitute() {
      return this.getCustomerTypeIDFromAccessToken === 5 || false;
    },
  },
  methods: {
    getMenuIcon(menu) {
      switch (menu) {
        case "dashboard":
          return "fas fa-list";
        case "candidates":
          return "fas fa-briefcase-medical";
        case "categories":
          return "fas fa-briefcase-medical";
        case "customers":
          return "fas fa-user-friends";
        case "jobs":
          return "fas fa-briefcase";
        case "courses":
          return "fas fa-briefcase";
        case "settings":
          return "fas fa-users-cog";
        case "talentfind":
          return "tfw-icon";
        case "students":
          return "fas fa-graduation-cap";
        case "courses":
          return "fas fa-chalkboard-user";
        case "profile_page":
          return "fas fa-users-cog";
        default:
          return "fas fa-list";
      }
    },
    getSubMenuIcon(menu) {
      switch (menu) {
        case "recruiter_dashboard":
          return "fas fa-chalkboard-teacher";
        case "management_report":
          return "fas fa-chart-line";
        case "talent_pool":
          return "fas fa-user-friends";
        case "students":
          return "fas fa-user-friends";
        case "recruitment_status":
          return "fas fa-user-check";
        case "add_customer":
          return "fas fa-user-plus";
        case "customer_list":
          return "fas fa-users";
        case "create_job":
          return "fas fa-laptop-medical";
        case "job_list":
          return "fas fa-list";
        case "allocate_job":
          return "fas fa-briefcase-medical";
        case "programmes":
          return "fas fa-list-alt";
        case "job_templates":
          return "fas fa-list-alt";
        case "add_candidate":
          return "fas fa-user-plus";
        case "onboarding_docs":
          return "fas fa-folder-open";
        case "theme_and_logo":
          return "fas fa-images";
        case "email_template":
          return "fas fa-envelope";
        case "campaign_form":
          return "fas fa-file-pen mr-2";
        default:
          return "fas fa-list";
      }
    },
    getPathForMenu(menu, item) {
      let talent_pool_url = this.getCandidateListUrlBasedOnRole;
      if (item?.menu === "talentfind" || this.isSuperCustomer) {
        talent_pool_url = TFW_CANDIDATE_URL;
      }
      switch (menu) {
        case "system_dashboard":
          return "/dashboard";
        case "dashboard":
          return this.isServiceProvider
            ? "/spc-dashboard"
            : "/recruiter-dashboard";
        case "management_report":
          return "/management-report";
        case "add_candidate":
          return "/add-candidate";
          // return "/add-new-candidate";
        case "talent_pool":
          return talent_pool_url;
        case "recruitment_status":
          return "/job-candidates-status?tabIndex=0&tab=allTab";
        case "add_customer":
          return "/add-customer";
        case "customer_list":
          return "/customer-list";
        case "create_job":
          return "/add-job?step=0";
        case "job_list":
          return "/job-list?published=%5B27,28,29%5D";
        case "allocate_job":
          return "/job-allocation";
        case "job_templates":
          return "/list-job-templates";
        case "onboarding_docs":
        case "document_library":
          return "/onboarding-documents";
        case "campaign_form":
          return "/campaign-register";
        case "theme_and_logo":
          return "/theme-and-logo";
        case "email_templates":
          return "/email-templates";
        case "course_list":
          return "/list-course";
        case "create_course":
          return "/add-course";
        case "list_courses":
          return "/list-course";
        case "add_course":
          return "/add-course";
        case "customer_template":
          return "/customer_template";
        default:
          return this.getCandidateListUrlBasedOnRole;
      }
    },
    toggleClass(ref) {
      if (this.$refs[ref] && this.$refs[ref][0]) {
        this.$refs[ref][0].hide(true);
      }
    },
    show(ref) {
      if (
        !(
          ["students", "dashboard"].includes(ref) && this.isEducationalInstitute
        ) &&
        !["profile_page"].includes(ref)
      ) {
        if (this.$refs[ref]) this.$refs[ref][0]._data.visible = true;
      }
    },
    hide(ref) {
      if (
        !(
          ["students", "dashboard"].includes(ref) && this.isEducationalInstitute
        ) &&
        !["profile_page"].includes(ref)
      ) {
        if (this.$refs[ref]) this.$refs[ref][0]._data.visible = false;
      }
    },
    navigateTo(path, ref) {
      this.$refs[ref][0]._data.visible = true;
      return this.$router.push(path);
    },
  },
};
</script>
