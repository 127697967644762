import candidate from "@/services/candidate";
import tabFilters from "../services/tabFilter";
import activityService from "@/services/activityList";
import moment from "moment";
import { getFilterQueryStringWithoutArray, getCustomerId, getLocalOrgDetail, LOGIN_URL, Role } from "@/helpers/helper.js";
import router from "../router/index";


const getDefaultState = () => {
  return {
    scrollTop: false,
    uploaded: false,
    toast: false,
    toastMessage: null,
    toastClass: null,
    toastHide: null,
    candidateProfile: null,
    ohrcandidateProfile: null,
    documentType: [],
    documentTypeOffer: [],
    getUploadedDocument: [],
    uploadResponse: 0,
    getLicense: [],
    getSkills: [],
    getCandidateLanguage: [],
    getNonMedicalQual: [],
    getCandidateNonMedicalQuals: [],
    getCandidateQualification: [],
    getCandidateSpecialInterest: [],
    getCandidatePQRLevels: [],
    candidatePQRLevel: null,
    isFetchingPqr: false,
    candidatePreferredRegionList: [],
    candidatePreferredCountryList: [],
    showErrorMsgModal: {},
    multiFormEdited: {
      specializationInformation: false,
      personalInformation: false,
      splInterest: false,
      licenseInformation: false,
      qualification: false,
      langInfo: false,
      NonMedicalInfos: false,
      showError: false,
      fieldValidateError: false,
      preferredDeptInformation: false,
      ExperienceInfos: false,
      languageQualification: false,
      competencyInfo: false,
    },
    candidateJobPagination: {
      limit: 6,
      skip: 0,
      noMoreJobs: false
    },
    jobListForCandidateID: [],
    jobListForCandidateIDCount: 0,
    isFetchingJobsForCandidateID: false,
    jobDetailsForCandidateID: null,
    lastUploadedDocs: null,
    getCandidateJobPageInProcessStatus: [],
    getCandidateJobPageInProcessList: [],
    candidateJobInProcessPagination: {
      limit: 4,
      skip: 0,
      noMoreCandidates: false,
    },
    isFetchingCandidateJobInProcessList: false,
    candidateCommonComments: [],
    preferredDepartments: [],
    block: 0,
    registeredHospitals: [],
    randomUuid: null,
    candidateExperiences: [],
    candidateExpOrgTypes: [],
    orgNature: [],
    orgAdditionalInfo: [],
    candidateInterviewSchedules: [],
    getOfferDetailsforCandidate: [],
    getOnboardDetailsforCandidate: [],
    fetchOffer: [],
    candidateBands: [],
    jobCandidateFrontSheet: [],
    jcpNegativeFlows: [],
    preOfferCandidateDocs: [],
    visaCandidateDocs: [],
    joiningCandidateDocs: [],
    jobListForSpecificCandidate: [],
    getCandidateOrganisation: [],
    getStudentQualifications: [],
    sponsorsList: [],
    courseTestTypes: [],
    getSponsoredCandidatesList: [],
    getLicenseRegTypeDetails: [],
    getCourseCountStats: {},
    campaignCandidateDetails: {},
    getCandidateOrganisationByAdmin: [],
  };
}

const state = getDefaultState()


const getters = {
  scrollTop: state => state.scrollTop,
  uploaded: state => state.uploaded,
  toast: state => state.toast,
  toastMessage: state => state.toastMessage,
  toastClass: state => state.toastClass,
  toastHide: state => state.toastHide,
  candidateProfile: state => state.candidateProfile,
  ohrcandidateProfile: state => state.ohrcandidateProfile,

  getUploadedDocument: state => state.getUploadedDocument,
  getOfferDetailsforCandidate: state => state.getOfferDetailsforCandidate,
  getOnboardDetailsforCandidate: state => state.getOnboardDetailsforCandidate,
  fetchOffer: state => state.fetchOffer,
  getCandidateOffers: state => state.fetchOffer,
  getCandidateOnBoardDocs: state => state.getOnboardDetailsforCandidate,
  documentType: state => state.documentType && state.documentType.length
    ? state.documentType.map(val => ({
      label: val.comments,
      code: val.document_type_id,
      allowed_extensions: val.allowed_extensions
    }))
    : [],
  documentTypeOffer: state => state.documentType && state.documentType.length
    ? state.documentType.map(val => ({
      label: val.document_name,
      code: val.document_type_id,
      documenttitle_id: val.documenttitle_id,
      allowed_extensions: val.allowed_extensions
    }))
    : [],
  documentTypeExcPhoto: state => state.documentType && state.documentType.length
    ? state.documentType.map(val => ({
      label: val.comments,
      code: val.document_type_id,
      allowed_extensions: val.allowed_extensions
    })).filter(({ code }) => code != 2)
    : [],
  uploadResponse: state => state.uploadResponse,
  getLicense: state => state.getLicense,
  getSkills: state => state.getSkills,
  getCandidateLanguage: state => state.getCandidateLanguage,
  getNonMedicalQual: (state) =>(qual_type_id = null) =>{
    return state.getNonMedicalQual ? state.getNonMedicalQual
      .map(val => ({
        code: val.non_medical_qual_type?.id,
        label: val.name,
        non_medical_qual_id: val.id,
        qual_type_id: val.non_medical_qual_type?.id,
        _label: val.name
      })).filter(v=>qual_type_id ? v.qual_type_id == qual_type_id : true ).sort(function (a, b) {
        let x = a.label.toLowerCase();
        let y = b.label.toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
      })
      : []
  },
  getNonMedicalQualforNonClinic: (state) => {
    return state.getNonMedicalQual ? state.getNonMedicalQual
      .filter(val => val.non_medical_qual_type?.id == 1)
      .map(val => ({
        code: val.non_medical_qual_type?.id,
        label: val.non_medical_qual_type?.type_name + " - " + val.name,
        non_medical_qual_id: val.id,
        qual_type_id: val.non_medical_qual_type?.id,
      })).sort(function (a, b) {
        let x = a.label.toLowerCase();
        let y = b.label.toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
      })
      : []
  },
  getCandidateNonMedicalQuals: state => state.getCandidateNonMedicalQuals,
  getCandidateQualification: state => state.getCandidateQualification,
  getCandidateSpecialInterest: state => state.getCandidateSpecialInterest,
  getCandidatePQRLevels: state => state.getCandidatePQRLevels,
  getCandidatePQRLevel: state => state.candidatePQRLevel,
  isFetchingPqr: (state, getters, rootState) => state.isFetchingPqr,
  PreferredCountryFilterByRegion: (state, getters, rootState) => ({ regions }) => {
    let { getRegionCountry } = getters;
    return getRegionCountry.length &&
      getRegionCountry.filter((val) => regions.includes(val.region_id))
        .map(val => {
          return {
            label: val.country.country_name,
            code: val.country.country_id,
            region_id: val.country.region_id
          }
        }) || [];
  },
  getCandidatePreferredRegionList: (state, getters, rootState) => {
    return state.candidatePreferredRegionList && state.candidatePreferredRegionList.map(val => ({
      label: val.region.region_name,
      code: val.region.region_id,
      preferred_region_id: val.candidate_region_id
    })) || [];
  },
  getCandidatePreferredCountryList: (state, getters, rootState) => {
    return state.candidatePreferredCountryList && state.candidatePreferredCountryList.map(val => ({
      label: val.country.country_name,
      code: val.country.country_id,
      preferred_country_id: val.candidate_preferred_country_id,
      region_id: val.country.region_id,
      country_code: val.country.country_code,
    })) || [];
  },
  showErrorMsgModal: (state, commit, getters) => {
    return state.showErrorMsgModal;
  },
  getMultiFormEdited: state => state.multiFormEdited,
  getResumeDocument: state => state.getUploadedDocument.length &&
    state.getUploadedDocument
      .map(
        ({
          document_type_id,
          uuid_link_to_doc,
          document_name,
          document_type,
          created_on,
          document_ext,
          candidate_document_id,
          comments,
        }) => {
          return {
            document_type_id,
            link: uuid_link_to_doc,
            lable: document_type.comments,
            name: document_name,
            updatedDate: moment(created_on).format("DD-MM-YYYY"),
            document_ext: document_ext?.document_ext,
            document_id: candidate_document_id,
            comments: comments,
          };
        }
      )
      .find(({ document_type_id }) => document_type_id === 1) || [],
  getJobListForCandidateID: state => state.jobListForCandidateID,
  getJobListForCandidateIDCount: state => state.jobListForCandidateIDCount,
  noMoreJobs: state => state.candidateJobPagination.noMoreJobs,
  getCandidateJobPagination: state => state.candidateJobPagination,
  isFetchingJobsForCandidateID: state => state.isFetchingJobsForCandidateID,
  getJobDetailsForCandidateID: (state) => state.jobDetailsForCandidateID,
  lastUploadedDocs: (state) => state.lastUploadedDocs,
  getCandidateJobPageInProcessStatus: (state) => state.getCandidateJobPageInProcessStatus,
  getCandidateJobPageInProcessList: (state) => state.getCandidateJobPageInProcessList,
  getCandidateJobInProcessPagination: (state) => state.candidateJobInProcessPagination,
  isFetchingCandidateJobInProcessList: (state) => state.isFetchingCandidateJobInProcessList,
  getCandidateCommonComments: state => state.candidateCommonComments,
  preferredDepartments: state => state.preferredDepartments,
  getRegisteredParent: state => state.registeredHospitals && state.registeredHospitals.length
    ? _(state.registeredHospitals).map().value().toString()
    : null,
  randomUuid: state => state.randomUuid,
  getCandidateExperiences: state => state.candidateExperiences,
  getCandidateExpOrgTypes: state => state.candidateExpOrgTypes && state.candidateExpOrgTypes.length
    ? state.candidateExpOrgTypes.map(val => ({
      label: val.comments,
      code: val.organisation_type_id
    }))
    : [],
  getOrgNature: state => state.orgNature && state.orgNature.length
    ? state.orgNature.map((val) => ({
      id: val,
      label: _.startCase(val),
    }))
    : [],
  getOrgAdditionalInfo: state => state.orgAdditionalInfo && state.orgAdditionalInfo.length
    ? state.orgAdditionalInfo.map((val) => ({
      code: val,
      label: _.startCase(val) + ' Hospital/Facility ',
    }))
    : [],
  getCandidateInterviewSchedules: state => state.candidateInterviewSchedules,
  getCandidateBands: state => state.candidateBands?.map(val => ({
    code: val.band_id,
    label: val.band_name,
  })) || [],
  jobCandidateFrontSheet: state => state.jobCandidateFrontSheet,
  jcpNegativeFlows: state => state.jcpNegativeFlows,
  preOfferCandidateDocs: state => state.preOfferCandidateDocs,
  visaCandidateDocs: state => state.visaCandidateDocs,
  joiningCandidateDocs: state => state.joiningCandidateDocs,
  jobListForSpecificCandidate: state => state.jobListForSpecificCandidate || [],
  getCandidateOrganisation: state => state.getCandidateOrganisation || [], 
  getStudentQualifications: state => state.getStudentQualifications || [], 
  getSponsorsList: state => state.sponsorsList || [],
  getCourseTestTypes: state => state.courseTestTypes || [],
  getSponsoredCandidates: state => state.getSponsoredCandidatesList || [],
  getLicenseRegTypes: state => state.getLicenseRegTypeDetails || [],
  getCandidateCourseCount: state => state.getCourseCountStats || [],
  getCampaignCandidateDetails: state => state.campaignCandidateDetails || [],
  getCandidateOrganisationByAdmin: state => state.getCandidateOrganisationByAdmin || [],
};

const mutations = {
  ["SET_SCROLLTOP"](state) {
    state.scrollTop = true;
    setTimeout(() => {
      state.scrollTop = false;
    }, 500);
  },
  ['RESET_CANDIDATE'](state, data) {
    Object.assign(state, getDefaultState())
  },
  ["SET_UPLOADED"](state, data) {
    state.uploaded = true;
    setTimeout(() => {
      state.uploaded = false;
    }, 500);
  },
  ["SET_TOAST"](state, payload) {
    state.toast = payload;
  },
  ["SET_TOAST_MESSAGE"](state, payload) {
    state.toastMessage = payload;
  },
  ["SET_TOAST_CLASS"](state, payload) {
    state.toastClass = payload;
  },
  ["SET_TOAST_HIDE"](state, timeout) {
    state.toastHide = timeout;
    setTimeout(() => {
      state.toast = false;
      state.toastMessage = null;
      state.toastClass = null;
      state.toastHide = null;
    }, timeout);
  },
  ["SET_CANDIDATE"](state, payload) {
    state.candidateProfile = payload;
  },
  ["SET_OHR_CANDIDATE"](state, payload) {
    state.ohrcandidateProfile = payload;
  },
  ["SET_DOCUMENT_TYPE"](state, payload) {
    state.documentType = payload;
  },
  ["SET_FETCHING"](state, data) {
    state.isFetchingFilter = data;
  },
  ["SET_UPLOADED_DOCUMENT"](state, data) {
    state.getUploadedDocument = data;
  },
  ["SET_WAIT_RESPONSE"](state) {
    state.uploadResponse += 1;
  },
  ["SET_CLOSE_RESPONSE"](state) {
    state.uploadResponse -= 1;
  },
  ["SET_LICENSE"](state, data) {
    state.getLicense = data;
  },
  ["SET_SKILLS"](state, data) {
    state.getSkills = data;
  },
  ["SET_CANDIDATE_LANGUAGE"](state, data) {
    state.getCandidateLanguage = data;
  },
  ["SET_NON_MEDICAL_QUALIFICATION"](state, data) {
    state.getNonMedicalQual = data;
  },
  ["CANDIDATE_NON_MEDICAL_QUALIFICATIONS"](state, data) {
    state.getCandidateNonMedicalQuals = data;
  },
  ["SET_CANDIDATE_QUALIFICATION"](state, data) {
    state.getCandidateQualification = data;
  },
  ["SET_CANDIDATE_SPECIALINTEREST"](state, data) {
    state.getCandidateSpecialInterest = data;
  },
  ["SET_CANDIDATE_PQRLEVELS"](state, data) {
    state.getCandidatePQRLevels = data;
  },
  ["SET_CANDIDATE_PQRLEVEL"](state, data) {
    state.candidatePQRLevel = data;
  },
  ["RESET_CANDIDATE_PQRLEVEL"](state, data) {
    state.candidatePQRLevel = null;
  },
  ["SET_IS_FETCHING_PQR"](state, data) {
    state.isFetchingPqr = data;
  },
  ["RESET_CANDIDATE_PQRLEVELS"](state, data) {
    state.getCandidatePQRLevels = [];
  },
  ["SET_CANDIDATE_PREFERRED_COUNTRY_LIST"](state, data) {
    state.candidatePreferredCountryList = data;
  },
  ["SET_CANDIDATE_PREFERRED_REGION_LIST"](state, data) {
    state.candidatePreferredRegionList = data;
  },
  ["RESET_CANDIDATE_PREFERRED_COUNTRY_LIST"](state, data) {
    state.candidatePreferredCountryList = [];
  },
  ["RESET_CANDIDATE_PREFERRED_REGION_LIST"](state, data) {
    state.candidatePreferredRegionList = [];
  },
  ["RESET_UPLOADED_DOCUMENT"](state, data) {
    state.getUploadedDocument = [];
  },
  ["SHOW_ERROR_MSG_MODAL"](state, data) {
    state.showErrorMsgModal = {
      modalTitle: data.modalTitle,
      modalColor: data.modalColor,
      modalContent: data.modalContent,
      isShowPopup: data.isShowPopup
    };
  },
  ["SET_MULTI_FORM_EDITED"](state, data) {
    state.multiFormEdited = data;
  },
  ["RESET_MULTI_FORM_EDITED"](state) {
    state.multiFormEdited = {
      specializationInformation: false,
      personalInformation: false,
      splInterest: false,
      licenseInformation: false,
      qualification: false,
      langInfo: false,
      showError: false,
      fieldValidateError: false,
      preferredDeptInformation: false,
      languageQualification: false,
    };
  },
  ['SET_CANDIDATE_JOB_PAGINATION'](state, data) {
    state.candidateJobPagination = { ...state.candidateJobPagination, ...data };
  },
  ['SET_JOB_LIST_FOR_CANDIDATE_ID'](state, payload) {
    state.jobListForCandidateID = payload;
  },
  ['SET_JOB_LIST_FOR_CANDIDATE_ID_COUNT'](state, payload) {
    state.jobListForCandidateIDCount = payload;
  },
  ['RESET_JOB_LIST_FOR_CANDIDATE_ID'](state, data) {
    state.jobListForCandidateID = [];
  },
  ['IS_FETCHING_JOBS_FOR_CANDIDATE_ID'](state, data) {
    state.isFetchingJobsForCandidateID = data;
  },
  ['SET_JOB_DETAILS_FOR_CANDIDATE_ID'](state, payload) {
    state.jobDetailsForCandidateID = payload;
  },
  ['SET_LAST_UPLOADED_DOCS'](state, payload) {
    state.lastUploadedDocs = payload
  },
  ["SET_CANDIDATE_JOB_PAGE_INPROCESS_STATUS"](state, payload) {
    state.getCandidateJobPageInProcessStatus = payload
  },
  ["SET_CANDIDATE_JOB_PAGE_INPROCESS_LIST"](state, payload) {
    state.getCandidateJobPageInProcessList = payload
  },
  ["IS_FETCHING_CANDIDATE_JOB_INPROCESS_LIST"](state, payload) {
    state.isFetchingCandidateJobInProcessList = payload
  },
  ["SET_CANDIDATE_JOB_INPROCESS_PAGINATION"](state, payload) {
    state.candidateJobInProcessPagination = {
      ...state.candidateJobInProcessPagination,
      ...payload
    }
  },
  ['CANDIDATE_COMMENTS'](state, payload) {
    state.candidateCommonComments = payload;
  },
  ['SET_PREFERRED_DEPARTMENTS'](state, payload) {
    state.preferredDepartments = payload;
  },
  ['SET_REGISTERED_HOSPITALS'](state, payload) {
    state.registeredHospitals = payload;
  },
  ["SET_RANDOM_UUID"](state, payload) {
    state.randomUuid = payload
  },
  ["SET_CANDIDATE_EXPERIENCES"](state, payload) {
    state.candidateExperiences = payload
  },
  ["SET_CANDIDATE_EXP_ORG_TYPES"](state, payload) {
    state.candidateExpOrgTypes = payload
  },
  ["SET_ORG_NATURE"](state, payload) {
    state.orgNature = payload
  },
  ["SET_ORG_ADDITIONAL_INFO"](state, payload) {
    state.orgAdditionalInfo = payload
  },
  ['SET_CANDIDATE_INTERVIEW_SCHEDULES'](state, payload) {
    state.candidateInterviewSchedules = payload
  },
  ["SET_OFFER_DETAILS_FOR_CANDIDATE"](state, data) {
    state.getOfferDetailsforCandidate = data;
  },
  ["RESET_OFFER_DETAILS_FOR_CANDIDATE"](state, data) {
    state.getOfferDetailsforCandidate = [];
  },
  ["SET_ONBOARD_DETAILS_FOR_CANDIDATE"](state, data) {
    state.getOnboardDetailsforCandidate = data;
  },
  ["RESET_ONBOARD_DETAILS_FOR_CANDIDATE"](state, data) {
    state.getOnboardDetailsforCandidate = [];
  },
  ["SET_CANDIDATE_OFFER"](state, data) {
    state.fetchOffer = data;
  },
  ["RESET_CANDIDATE_OFFER"](state, data) {
    state.fetchOffer = [];
  },
  ["SET_CANDIDATE_BAND"](state, data) {
    state.candidateBands = data;
  },
  ["APPEND_DOCUMENT"](state, data) {
    const docs = state.getUploadedDocument;
    state.getUploadedDocument = [...docs, data];
  },
  ["OVERRIDE_DOCUMENT"](state, data) {
    const { document_type_id } = data;
    let docs = state.getUploadedDocument;
    const index = docs.findIndex(v => v.document_type_id === document_type_id);
    if (index > -1)
      docs[index] = data;
    else
      docs.push(data);
    state.getUploadedDocument = [...docs];
  },
  ["SET_JOB_CANDIDATE_FRONT_SHEET"](state, data) {
    state.jobCandidateFrontSheet = data;
  },
  ["RESET_JOB_CANDIDATE_FRONT_SHEET"](state) {
    state.jobCandidateFrontSheet = [];
  },
  ["SET_JCP_NEGATIVE_FLOW"](state, data) {
    state.jcpNegativeFlows = data;
  },
  ["RESET_JCP_NEGATIVE_FLOW"](state) {
    state.jcpNegativeFlows = [];
  },
  ["SET_PRE_OFFER_CANDIDATE_DOCS"](state, data) {
    state.preOfferCandidateDocs = data;
  },
  ["SET_VISA_CANDIDATE_DOCS"](state, data) {
    state.visaCandidateDocs = data;
  },
  ["SET_JOINING_CANDIDATE_DOCS"](state, data) {
    state.joiningCandidateDocs = data;
  },
  ["SET_JOB_LIST_FOR_SPECIFIC_CANDIDATE"](state, data) {
    state.jobListForSpecificCandidate = data;
  },
  ["SET_CANDIDATE_ORGANISATION"](state, data) {
    state.getCandidateOrganisation = data;
  },
  ["SET_CANDIDATE_ORGANISATION_BY_ADMIN"](state, data) {
    state.getCandidateOrganisationByAdmin = data;
  },
  ["SET_QUALIFICATIONS"](state, data) {
    state.getStudentQualifications = data;
  },
  ["SET_SPONSORS_LIST"](state, data) {
    state.sponsorsList = data;
  },
  ["SET_COURSE_TEST_TYPES"](state, data) {
    state.courseTestTypes = data;
  },
  ["SET_SPONSORED_CANDIDATES"](state, data) {
    state.getSponsoredCandidatesList = data;
  },
  ["SET_LICENSE_REG_TYPE"](state, data) {
    state.getLicenseRegTypeDetails = data;
  },
  ["SET_COURSE_COUNT_STATS"](state, data) {
    state.getCourseCountStats = data;
  },
  ["SET_CAMPAIGN_CANDIDATE_DETAILS"](state, data) {
    state.campaignCandidateDetails = data;
  },
};

const actions = {
  scrollTop: ({ commit }) => {
    commit("SET_SCROLLTOP");
  },
  resetCandidate({ state, commit }, payload) {
    commit("RESET_CANDIDATE")
  },
  getUserMe: ({ commit, getters, dispatch }, { customer }) => {
    let { getPathAcronymFromAccessToken } = getters;
    let path_acronym = getPathAcronymFromAccessToken;
    let { getRoleScopes } = getters;
    return candidate
      .getUserMe(customer, path_acronym)
      .then(res => {
        let { data } = res;
        if (data) {
          commit("SET_CANDIDATE", data);
          if (getRoleScopes.length && ["system_candidate", "customer_candidate"].includes(getRoleScopes[0])) {
            dispatch("fetchMyUploadedDocument");
          }
          return res;
        }
      })
      .catch(err => {
        return err;
      });
  },
  getCandidate: ({ commit }, payload) => {
    let isdeleteSplInt = payload?.delete_spl_int || false;
    commit("SET_TOAST", isdeleteSplInt ? false : true);
    commit("SET_TOAST_MESSAGE", "Loading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .getCandidate(payload.customer, payload.id)
      .then(res => {
        let { data } = res;
        if (data && data.candidate_uid) {
          commit("SET_TOAST_MESSAGE", "Loaded");
          commit("SET_TOAST_CLASS", "bg-success text-white");
          commit("SET_TOAST_HIDE", 3000);
          commit("SET_CANDIDATE", data);
          return res;
        }
      })
      .catch(err => {
        console.log("Error fetching candidate", err);
        commit("SET_TOAST_MESSAGE", "Error fetching candidate");
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  createCandidate: ({ commit }, payload) => {
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.create(payload.customer_id, payload).then(async res => {
      commit("SET_TOAST_MESSAGE", "Success");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      await commit("SET_CANDIDATE", res.data);
      return res;
    })
      .catch(err => {
        let msg = "Error while saving";
        if (err.message.indexOf("409") > 0) {
          msg = "A user with same email already exists";
        }
        console.log("error creating candidate", err);
        if (err.response.status == 406) {
          let errMsg = err?.response?.data?.detail
          msg = errMsg ? errMsg : msg
        }
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  updateCandidate: ({ commit, getters }, payload) => {
    const { customer_uid, data } = payload;
    const { getCandidates } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .update(customer_uid, data)
      .then(res => {
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
        commit("SET_CANDIDATE", res.data);
        if (getCandidates.length) {
          const CandidateIndex = getCandidates.findIndex(e => e.candidate_uid === data.candidate_uid);
          const CandidateInfo = res.data
          if (CandidateIndex >= 0) {
            commit("REPLACE_CANDIDATE_BY_INDEX", { CandidateIndex, CandidateInfo })
          }
        }
        return res.data
      })
      .catch(err => {
        let msg = "Error while saving";
        if (err.message.indexOf("409") > 0) {
          msg = "User already exists";
        }
        console.log("error updating candidate", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  async uploadResume({ commit, dispatch }, payload) {
    const { candidate_uid, user_id, file, document_type, job_id, comments, documenttitle_id, re_uploaded } = payload
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    dispatch("showToast", { message: 'Uploading...' })
    if (candidate_uid && file) {
      const document = new FormData();
      document.append("file", file, file.name);
      document.append("document_type_id", document_type);
      if (documenttitle_id) {
        document.append("documenttitle_id", documenttitle_id);
      }
      if (comments) document.append("comments", comments);
      if (re_uploaded) document.append("re_uploaded", re_uploaded);
      let queryString = `user_id=${user_id}`;
      if (job_id) queryString += `&job_id=${job_id}`
      return await candidate
        .document(candidate_uid, queryString, document)
        .then(res => {
          commit("SET_UPLOADED", true);
          commit("SET_TOAST_MESSAGE", "Uploaded");
          commit("SET_TOAST_CLASS", "bg-success text-white");
          commit("SET_TOAST_HIDE", 3000);
          commit("SET_LAST_UPLOADED_DOCS", res.data)
          dispatch("showToast", { class: 'bg-success text-white', message: 'Uploaded successfully' })
          return res;
        })
        .catch(err => {
          let msg = "Error while uploading resume";
          commit("SET_UPLOADED", true);
          commit("SET_TOAST_MESSAGE", msg);
          commit("SET_TOAST_CLASS", "bg-danger text-white");
          commit("SET_TOAST_HIDE", 3000);
          return err;
        }).finally(() => {
        });
    }
  },

  uploadPhoto({ commit, dispatch }, payload) {
    const { customer_uid, candidate_uid, user_id, file, document_type, fileName, job_id, comments, documenttitle_id, re_uploaded, blobData } = payload
    dispatch("showToast", { message: 'Uploading...' })
    if (candidate_uid && blobData) {
      let formData = new FormData();
      formData.append("file", blobData, fileName);
      formData.append("document_type_id", document_type);
      if (documenttitle_id) {
        formData.append("documenttitle_id", documenttitle_id);
      }
      if (comments) formData.append("comments", comments);
      if (re_uploaded) formData.append("re_uploaded", re_uploaded);
      let queryString = `user_id=${user_id}`;
      if (job_id) queryString += `&job_id=${job_id}`
      return candidate.document(candidate_uid, queryString, formData)
        .then(res => {
          commit("SET_UPLOADED", true);
          commit("SET_LAST_UPLOADED_DOCS", res.data)
          dispatch("showToast", { class: 'bg-success text-white', message: 'Uploaded successfully' })
          return res;
        })
        .catch(err => {
          commit("SET_UPLOADED", true);
          let msg = "Error while uploading photo";
          dispatch("showToast", { class: 'bg-danger text-white', message: msg })
          return err;
        }).finally(() => { });
    } else if (candidate_uid && file) {
      const { src } = file;
      const type = src.split(';')[0].split(':')[1];
      return file.getCroppedCanvas().toBlob(function (blob) {
        let formData = new FormData();
        formData.append("file", blob, fileName);
        formData.append("document_type_id", document_type);
        if (documenttitle_id) {
          formData.append("documenttitle_id", documenttitle_id);
        }
        if (comments) formData.append("comments", comments);
        if (re_uploaded) formData.append("re_uploaded", re_uploaded);
        let queryString = `user_id=${user_id}`;
        if (job_id) queryString += `&job_id=${job_id}`
        return candidate.document(candidate_uid, queryString, formData)
          .then(res => {
            commit("SET_UPLOADED", true);
            commit("SET_LAST_UPLOADED_DOCS", res.data)
            dispatch("showToast", { class: 'bg-success text-white', message: 'Uploaded successfully' })
            return res;
          })
          .catch(err => {
            commit("SET_UPLOADED", true);
            let msg = "Error while uploading photo";
            dispatch("showToast", { class: 'bg-danger text-white', message: msg })
            return err;
          }).finally(() => { });
      }, type);
    }
  },
  createDocumentActionFiles({ commit, dispatch }, payload) {
    const { customer_uid, organisation_id, file, file_name, ...rest } = payload
    dispatch("showFixedToast", { message: 'Uploading...' })
    if (!file) return;
    const document = new FormData();
    document.append("file", file, file_name);
    for (let key in rest) {
      document.append(key, payload[key]);
    }
    return candidate
      .createDocumentAction(customer_uid, organisation_id, document)
      .then((res) => {
        commit("SET_LAST_UPLOADED_DOCS", res.data);
        dispatch("hideFixedToast");
        dispatch("showToast", { class: 'bg-success text-white', message: 'Uploaded successfully' })
        return res;
      })
      .catch((err) => {
        dispatch("hideFixedToast");
        dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while uploading document' })
        return err;
      })
  },
  fetchCandidateAndJobStatus: ({ dispatch, commit }, payload) => {
    const queryPayload = { ...payload };
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return activityService
      .fetchActivity({ queryString })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("Error while action status of candidate", err);
        return err;
      })
  },
  acceptOfferByCandidate({ commit, dispatch, getters }, payload) {
    const { payloadData: {
      job_id,
      candidate_uid,
      payload: {
        comments,
      }
    }, updateStatusSubStatus } = payload;
    dispatch("clearAllToast");
    dispatch("showLoader")
    let queryPayload = {};
    if (comments)
      queryPayload['comments'] = comments;
    // let queryString = getFilterQueryStringWithoutArray(queryPayload);
    return candidate
      .updateJobCandidateStatus({ job_id, candidate_uid, payload: { ...updateStatusSubStatus, ...queryPayload, job_id, candidate_uid } }).then(res => {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Offer acceptance sent to recruiter!' })
        return res;
      })
      .catch((err) => {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Error while accepting offer' })
        return err;
      })
      .finally(() => {
        dispatch("hideLoader");
        return;
      })
  },
  initFetchOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      documentType,
      getLocationRegion
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!documentType.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchDocumentType")];
    }
    if (!getLocationRegion.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationRegion")];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
    })
  },
  fetchDocumentType({ commit, getters }) {
    const { getUserId: user_id, isUKMainAgency, isCandidateSupplierFromAccessToken } = getters;
    let queryPayload = {
      order_by: 'document_type',
      entity_id: 1,
    };
    if (isCandidateSupplierFromAccessToken || isUKMainAgency)
      queryPayload = { ...queryPayload, user_id };
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    return candidate
      .getDocumentType(queryString)
      .then(async res => {
        commit("SET_DOCUMENT_TYPE", res.data);
      })
      .catch(err => {
        console.log("error fetch document type", err);
        return err;
      });
  },
  fetchUploadedDocument({ commit, dispatch, getters }) {
    // commit("RESET_UPLOADED_DOCUMENT")
    const { candidateProfile: { customer_uid, candidate_uid }, getRole, isJobSupplierFromAccessToken } = getters;
    const { customerAdmin, customerRecruiter, candidateRole } = Role;
    let queryPayload = {};
    if (getRole && [customerRecruiter, customerAdmin, ...candidateRole].includes(getRole)) {
      queryPayload = {
        ...queryPayload,
        only_latest_versions: false,
        latest_versions_for: [2, 11, 14]
      }
    }
    if (isJobSupplierFromAccessToken) {
      queryPayload = { ...queryPayload, latest_versions_for: [2, 11, 14, 20] }
    }
    let query = getFilterQueryStringWithoutArray(queryPayload);
    query = query ? `?${query}` : query
    return candidate
      .getUploadedDocument(candidate_uid, query)
      .then(res => {
        const { data } = res;
        commit("SET_UPLOADED_DOCUMENT", data);
        return data;
      })
      .catch(err => {
        console.log("error fetching uploaded document ", err);
        return err;
      })
  },
  commitCandidateInfo({ commit, getters }, payload) {
    commit("SET_CANDIDATE", payload);
    commit("SET_IS_FETCHING_CANDIDATE_DETAILS", true);
    return payload;
  },
  updateDocument({ commit, dispatch, getters }, payload) {
    const { candidateProfile: { candidate_uid } } = getters;
    const { candidate_document_id, comments } = payload
    return candidate.updateDocument(candidate_uid, candidate_document_id, comments).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Document updated successfully' });
        dispatch("fetchUploadedDocument")
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while updating document' });
        }
        console.log("error in updating document", err);
        return err;
      });
  },
  deleteDocument({ commit, dispatch, getters }, candidate_document_id) {
    const { candidateProfile: { candidate_uid } } = getters;
    return candidate.deleteDocument(candidate_uid, candidate_document_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Document deleted successfully' });
        dispatch("fetchUploadedDocument")
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting document' });
        }
        console.log("error in deleting document", err);
        return err;
      });
  },
  async multipleFileUpload({ commit, dispatch }, payload) {
    const { file, document_type, candidate_uid, user_id } = payload;
    commit("SET_WAIT_RESPONSE")
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading Documents...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    if (candidate_uid && user_id && file) {
      const document = new FormData();
      document.append("file", file, file.name);
      document.append("document_type_id", document_type);
      let queryString = `user_id=${user_id}`;
      await candidate
        .document(candidate_uid, queryString, document)
        .then(res => {
          commit("SET_TOAST_MESSAGE", "Uploaded");
          commit("SET_TOAST_CLASS", "bg-success text-white");
          commit("SET_TOAST_HIDE", 3000);
          commit("SET_CLOSE_RESPONSE")
          commit("SET_LAST_UPLOADED_DOCS", res.data)
          return res;
        })
        .catch(err => {
          let msg = "Error while uploading resume";
          commit("SET_UPLOADED", true);
          commit("SET_TOAST_MESSAGE", msg);
          commit("SET_TOAST_CLASS", "bg-danger text-white");
          commit("SET_TOAST_HIDE", 3000);
          commit("SET_CLOSE_RESPONSE")
          return err;
        }).finally(() => {

        });
    }
  },
  fetchMyUploadedDocument({ commit, dispatch, getters }) {
    const { getCandidateId, getCustomerId } = getters;
    return candidate
      .getUploadedDocument(getCandidateId)
      .then(async res => {
        commit("SET_UPLOADED_DOCUMENT", res.data);
      })
      .catch(err => {
        console.log("error fetching document type", err);
        return err;
      })
      .finally(() => {

      });
  },
  fetchLicense({ commit, dispatch, getters }) {
    const { candidateProfile: { candidate_uid } } = getters;
    return candidate
      .getLicense(candidate_uid)
      .then(async res => {
        commit("SET_LICENSE", res.data);
      })
      .catch(err => {
        console.log("error fetching license", err);
        return err;
      })
      .finally(() => {

      });
  },
  fetchSkills({ commit, dispatch, getters }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .getSkills(customer_uid, candidate_uid)
      .then(async res => {
        commit("SET_SKILLS", res.data);
      })
      .catch(err => {
        console.log("error fetching skills", err);
        return err;
      })
      .finally(() => {

      });
  },
  uploadLicense: ({ commit, getters, dispatch }, payload) => {
    const { candidateProfile: { candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.createLicense (candidate_uid, payload).then(async res => {
      dispatch("fetchUploadedDocument")
      commit("SET_UPLOADED", true);
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        let msg = "Error while uploading License";
        if (err.response.status == 409) {
          commit("SET_TOAST_HIDE", 100);
          let errorMsg = {
            modalTitle: 'Duplicate Record',
            modalColor: "primary",
            modalContent: 'Requested Candidate License already exist! Please edit the existing record!',
            isShowPopup: true
          }
          commit("SHOW_ERROR_MSG_MODAL", errorMsg)
          return err;
        }
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      }).finally(() => {

      });
  },
  uploadSkill: ({ commit, getters }, payload) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.createSkill(customer_uid, candidate_uid, payload).then(async res => {
      commit("SET_UPLOADED", true);
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        let msg = "Error while uploading Skill";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      }).finally(() => {

      });
  },
  fetchCandidateLanguage({ commit, getters }) {
    const { candidateProfile: { candidate_uid } } = getters;
    return candidate
      .getCandidateLanguage(candidate_uid)
      .then(async res => {
        commit("SET_CANDIDATE_LANGUAGE", res.data);
      })
      .catch(err => {
        console.log("error fetching candidate language", err);
        return err;
      })
      .finally(() => {

      });
  },
  fetchNonMedicalQualNames({ commit, getters }) {
    const { getCustomerId } = getters;
    let queryPayload = {};
    if (getCustomerId)
      queryPayload = { ...queryPayload, customer_id: getCustomerId };
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return candidate
      .getNonMedicalQualNames(queryString)
      .then(async res => {
        commit("SET_NON_MEDICAL_QUALIFICATION", res.data);
      })
      .catch(err => {
        console.log("error fetching non-medical qualifications", err);
        return err;
      })
  },
  fetchCandidateNonMedicalQuals({ commit, getters }, payload) {
    let candidate_uid
    let customer_uid
    if (payload) {
      candidate_uid = payload.candidate_uid
      customer_uid = payload.customer_uid
    } else {
      candidate_uid = getters.candidateProfile.candidate_uid;
      customer_uid = getters.candidateProfile.customer_uid;
    }
    return candidate
      .getCandidateNonMedicalQual(customer_uid, candidate_uid)
      .then(async res => {
        commit("CANDIDATE_NON_MEDICAL_QUALIFICATIONS", res.data);
      })
      .catch(err => {
        console.log("error fetching non-medical qualifications", err);
        return err;
      })
  },
  uploadCandidateNonMedicalInfo: ({ commit, getters, dispatch }, payload) => {
    const { candidateProfile: { customer_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.createCandidateNonMedicalInfo(customer_uid, payload).then(async res => {
      dispatch("fetchCandidateNonMedicalQuals");
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        if (err?.response?.status === 422 && err?.response?.data?.detail === "expired") {
          let msg = "Can't be Uploaded!";
          commit("SET_UPLOADED", true);
          commit("SET_TOAST_MESSAGE", msg);
          commit("SET_TOAST_CLASS", "bg-danger text-white");
          commit("SET_TOAST_HIDE", 2000);
          return err;
        } else {
          let msg = "Error while uploading";
          commit("SET_UPLOADED", true);
          commit("SET_TOAST_MESSAGE", msg);
          commit("SET_TOAST_CLASS", "bg-danger text-white");
          commit("SET_TOAST_HIDE", 3000);
          return err;
        }
      }).finally(() => {

      });
  },
  uploadCandidateLanguage: ({ commit, getters, dispatch }, payload) => {
    const { candidateProfile: {candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.createCandidateLanguage(candidate_uid, payload).then(async res => {
      dispatch("fetchCandidateLanguage");
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        let msg = "Error while uploading candidate language";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      }).finally(() => {

      });
  },
  deleteNonMedicalInfo({ commit, dispatch, getters }, candidate_info_id) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate.deleteNonMedicalInfo(customer_uid, candidate_uid, candidate_info_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Qualification deleted successfully' });
        dispatch("fetchCandidateNonMedicalQuals");
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting language' });
        }
        console.log("error in deleting Qualification", err);
        return err;
      });
  },
  deleteCandidateLanguage({ commit, dispatch, getters }, candidate_language_id) {
    const { candidateProfile: {candidate_uid } } = getters;
    return candidate.deleteLanguage(candidate_uid, candidate_language_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Language deleted successfully' });
        dispatch("fetchCandidateLanguage");
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting language' });
        }
        console.log("error in deleting language", err);
        return err;
      });
  },
  deleteCandidateSkills({ commit, dispatch, getters }, candidate_skill_id) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate.deleteSkills(customer_uid, candidate_uid, candidate_skill_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-danger text-white', message: 'Skill deleted successfully' });
        dispatch("fetchSkills");
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting Skill' });
        }
        console.log("error in deleting Skill", err);
        return err;
      });
  },
  deleteCandidateLicense({ commit, dispatch, getters }, candidate_license_id) {
    const { candidateProfile: {candidate_uid } } = getters;
    return candidate.deleteLicense(candidate_uid, candidate_license_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'License deleted successfully' });
        dispatch("fetchLicense");
        dispatch("fetchUploadedDocument")
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting license' });
        }
        console.log("error in deleting license", err);
        return err;
      });
  },
  deleteCandidateQualification({ commit, dispatch, getters }, { candidate_qualification_id, candidate_sponsor_id }) {
    const { candidateProfile: { candidate_uid } } = getters;
    let query = {}
    if (candidate_sponsor_id) {
      query = { candidate_sponsor_id}
    }
    query = getFilterQueryStringWithoutArray(query)
    return candidate.deleteQualification( candidate_uid, candidate_qualification_id, candidate_sponsor_id).then(async res => {
      if (res.status == 200) {
        commit("SET_UPLOADED", true);
        dispatch("showToast", { class: 'bg-success text-white', message: 'Qualification deleted successfully' });
        await dispatch("fetchQualification");
        dispatch("fetchUploadedDocument");
        setTimeout(() => {
          dispatch("fetchPQRLevels");
        }, 1000);
      }
      return res
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting qualification' });
        }
        console.log("error in deleting qualification", err);
        return err;
      });
  },
  fetchQualification({ commit, dispatch, getters }) {
    const { candidateProfile: { candidate_uid } } = getters;
    return candidate
      .getCandidateQualification(candidate_uid)
      .then(async res => {
        commit("SET_CANDIDATE_QUALIFICATION", res.data);
      })
      .catch(err => {
        console.log("error fetching qualification", err);
        return err;
      })
      .finally(() => {

      });
  },
  uploadQualification: ({ commit, getters }, payload) => {
    const { candidateProfile: { candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.createQualification( candidate_uid, payload).then(async res => {
      commit("SET_UPLOADED", true);
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        let msg = "Error while uploading Qualification";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      }).finally(() => {

      });
  },
  deleteCandidateSpecialInterest({ commit, dispatch, getters }, candidate_speciality_id) {
    const { candidateProfile: { candidate_uid  } } = getters;
    return candidate.deleteSpecialInterest(candidate_uid, candidate_speciality_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Special interest deleted successfully' });
        dispatch("fetchspecialInterest");
        dispatch("getCandidate", { customer: customer_uid, id: candidate_uid, delete_spl_int: true });
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting special interest' });
        }
        console.log("error in deleting special interest", err);
        return err;
      });
  },
  deleteSplInterestWithoutToast({ commit, dispatch, getters }, candidate_speciality_id) {
    const { candidateProfile: { candidate_uid } } = getters;
    return candidate.deleteSpecialInterest(candidate_uid, candidate_speciality_id).then(async res => {
      if (res.status == 200) {
        return res;
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting special interest' });
        }
        console.log("error in deleting special interest", err);
        return err;
      });
  },
  fetchspecialInterest({ commit, dispatch, getters }, payload) {
    let candidate_uid
    let customer_uid
    if (payload) {
      candidate_uid = payload.candidate_uid
    } else {
      candidate_uid = getters.candidateProfile.candidate_uid;
      customer_uid = getters.candidateProfile.customer_uid;
    }
    return candidate
      .getCandidateSpecialInterest(candidate_uid)
      .then(async res => {
        commit("SET_CANDIDATE_SPECIALINTEREST", res.data);
      })
      .catch(err => {
        console.log("error fetching special interest", err);
        return err;
      })
      .finally(() => {

      });
  },
  uploadSpecialInterest: ({ commit, getters }, payload) => {
    const { candidateProfile: { candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.createSpecialInterest(candidate_uid, payload).then(async res => {
      commit("SET_UPLOADED", true);
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        let msg = "Error while uploading Special interest";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      }).finally(() => {

      });
  },
  fetchPQRLevels({ commit, dispatch, getters }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("RESET_CANDIDATE_PQRLEVELS");
    return candidate
      .getCandidatePQRLevels(customer_uid, candidate_uid)
      .then(async res => {
        commit("SET_CANDIDATE_PQRLEVELS", res.data);
      })
      .catch(err => {
        console.log("error fetching PQR levels", err);
        return err;
      })
      .finally(() => {

      });
  },
  initCandidateDetailsFetch({ commit, dispatch, getters }) {
    const { getQualificationRegions, getQualificationCountries } = getters;
    commit("RESET_JOB_CANDIDATE_COUNT");
    commit("RESET_JOB_LIST_FOR_CANDIDATE_ID");
    let appendAction = [];
    appendAction = [...appendAction, dispatch("fetchCandidateNonMedicalQuals")];
    appendAction = [...appendAction, dispatch("fetchCandidateExperiences")];
    appendAction = [...appendAction, dispatch("fetchCandidateLanguage")];
    appendAction = [...appendAction, dispatch("fetchUploadedDocument")];
    appendAction = [...appendAction, dispatch("fetchLicense")];
    appendAction = [...appendAction, dispatch("fetchQualification")];
    appendAction = [...appendAction, dispatch("fetchPQRLevels")];
    appendAction = [...appendAction, dispatch("fetchCandidatePreferredRegionList")];
    appendAction = [...appendAction, dispatch("fetchCandidatePreferredCountryList")];
    appendAction = [...appendAction, dispatch("fetchspecialInterest")];
    if (!getQualificationRegions.length)
      appendAction = [...appendAction, dispatch("fetchQualificationRegions")];
    if (!getQualificationCountries.length)
      appendAction = [...appendAction, dispatch("fetchAllQualificationCountries")];
    Promise.all(appendAction).then(res => {
      commit("RESET_CANDIDATE_PQRLEVEL");
      return res
    })

    // dispatch("fetchCandidateNonMedicalQuals");
    // dispatch("fetchCandidateLanguage");
    // dispatch("fetchUploadedDocument");
    // // dispatch("fetchspecialInterest");
    // // dispatch("fetchQualification");
    // // dispatch("fetchSkills");
    // dispatch("fetchLicense");
    // dispatch("fetchPQRLevels");
    // dispatch("fetchCandidatePreferredRegionList");
    // dispatch("fetchCandidatePreferredCountryList");
    // // dispatch("locationLanguage");
    // // dispatch("specialInterest");
    // dispatch("fetchQualificationRegions");
    // dispatch("fetchAllQualificationCountries");
    // commit("RESET_CANDIDATE_PQRLEVEL");
    // commit("RESET_JOB_LIST_FOR_CANDIDATE_ID");
    // commit("RESET_JOB_CANDIDATE_COUNT");
    return
  },
  checkPQRLevel({ commit, dispatch, getters }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_IS_FETCHING_PQR", true);
    return candidate
      .checkPQRLevel({ customerId: customer_uid, candidateUid: candidate_uid })
      .then(res => {
        commit("SET_CANDIDATE_PQRLEVEL", res.data);
        return res.data;
      })
      .catch(err => {
        commit("SET_IS_FETCHING_PQR", false);
        console.log("error fetching PQR levels", err);
        return err;
      })
  },
  updateNonMedicalInfo: ({ getters, commit, dispatch }, { candidate_non_medical_qual_id, payload }) => {
    const { candidateProfile: { customer_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updateNonMedicalInfo(customer_uid, candidate_non_medical_qual_id, payload)
      .then(async res => {

        dispatch("fetchCandidateNonMedicalQuals");
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
        return res
      })
      .catch(err => {
        if (err?.response?.status === 422 && err?.response?.data?.detail === "expired") {
          let msg = "Can't be Saved!";
          commit("SET_UPLOADED", true);
          commit("SET_TOAST_MESSAGE", msg);
          commit("SET_TOAST_CLASS", "bg-danger text-white");
          commit("SET_TOAST_HIDE", 2000);
          return err;
        } else {
          let msg = "Error while saving";
          console.log("error updating Language", err);
          commit("SET_TOAST_MESSAGE", msg);
          commit("SET_TOAST_CLASS", "bg-danger text-white");
          commit("SET_TOAST_HIDE", 3000);
          return err;
        }
      });
  },
  updateLanguage: ({ getters, commit, dispatch }, { candidate_lang_id, langPayload }) => {
    const { candidateProfile: { candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updateLanguagesSpoken(candidate_uid, candidate_lang_id, langPayload)
      .then(async res => {
        dispatch("fetchCandidateLanguage");
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
      })
      .catch(err => {
        let msg = "Error while saving";
        console.log("error updating Language", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  updateLicense: ({ getters, commit, dispatch }, payload) => {
    const { candidateProfile: { candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updateCandidateLicense(candidate_uid, payload)
      .then(async res => {
        dispatch("fetchUploadedDocument")
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
      })
      .catch(err => {
        let msg = "Error while saving";
        if (err.response.status == 409) {
          commit("SET_TOAST_HIDE", 100);
          let errorMsg = {
            modalTitle: 'Duplicate Record',
            modalColor: "primary",
            modalContent: 'Requested Candidate License already exist! Please edit the existing record!',
            isShowPopup: true
          }
          commit("SHOW_ERROR_MSG_MODAL", errorMsg)
          return err;
        }
        console.log("error updating License", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  updateSplInterest: ({ getters, commit }, payload) => {
    const { candidateProfile: { candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updateCandidateSplInterest(candidate_uid, payload)
      .then(async res => {
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
      })
      .catch(err => {
        let msg = "Error while saving";
        console.log("error updating special interest", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  updateSkill: ({ getters, commit }, payload) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updateCandidateSkill(customer_uid, candidate_uid, payload)
      .then(async res => {
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
      })
      .catch(err => {
        let msg = "Error while saving";
        console.log("error updating skill", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  updateQualification: ({ getters, commit }, { candidate_qualification_id, qualificationPayload }) => {
    const { candidateProfile: { candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updateCandidateQualification( candidate_uid, candidate_qualification_id, qualificationPayload)
      .then(async res => {
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
      })
      .catch(err => {
        let msg = "Error while saving";
        console.log("error updating qualification", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  fetchCandidatePreferredRegionList: ({ commit, dispatch, getters }) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_CANDIDATE_PREFERRED_REGION_LIST");
    return candidate
      .getCandidatePreferredRegionList({ customerId: customer_uid, candidateUid: candidate_uid })
      .then(res => {
        commit("SET_CANDIDATE_PREFERRED_REGION_LIST", res.data);
      })
      .catch(err => {
        console.log("error fetching candidate preferred region list", err);
        return err;
      })
  },
  fetchCandidatePreferredCountryList: ({ commit, dispatch, getters }) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("RESET_CANDIDATE_PREFERRED_COUNTRY_LIST");
    return candidate
      .getCandidatePreferredCountryList({ customerId: customer_uid, candidateUid: candidate_uid })
      .then(res => {
        commit("SET_CANDIDATE_PREFERRED_COUNTRY_LIST", res.data);
      })
      .catch(err => {
        console.log("error fetching candidate preferred country list", err);
        return err;
      })
  },
  createPreferredRegion: ({ commit, getters }, payload) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .createCandidatePreferredRegions({ customerId: customer_uid, candidateUid: candidate_uid, payload })
      .then(res => {
        return res;
      })
      .catch(err => {
        let msg = "Error while create preferred Region";
        return err;
      })
  },
  createPreferredCountry: ({ commit, getters }, payload) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .createCandidatePreferredCountry({ customerId: customer_uid, candidateUid: candidate_uid, payload })
      .then(res => {
        return res;
      })
      .catch(err => {
        let msg = "Error while create preferred country";
        return err;
      })
  },
  deletePreferredRegion: ({ commit, getters }, preferred_region_id) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .deleteCandidatePreferredRegion({ customerId: customer_uid, candidateUid: candidate_uid, preferred_region_id })
      .then(res => {
        return res;
      })
      .catch(err => {
        let msg = "Error while delete preferred country";
        return err;
      })
  },
  deletePreferredCountry: ({ commit, getters }, preferred_country_id) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .deleteCandidatePreferredCountry({ customerId: customer_uid, candidateUid: candidate_uid, preferred_country_id })
      .then(res => {
        return res;
      })
      .catch(err => {
        let msg = "Error while delete preferred region";
        return err;
      })
  },
  initFetchBasicInfoOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      titles,
      getGender,
      getLocationCountryInfo,
      getNationalityCountryInfo,
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!titles.length) {
      appendFilterAction = [...appendFilterAction, dispatch("getTitles")];
    }
    if (!getGender.length) {
      appendFilterAction = [...appendFilterAction, dispatch("gender")];
    }
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    if (!getNationalityCountryInfo.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("nationalityCountryInfo")
      ];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initFetchContactInfoOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      getLocationCountryInfo,
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initFetchProfessionalInfoOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      getCandidateType,
      getSpeciality,
      getSubSpeciality,
      getCandidateLevel,
      nurseRegistrationType,
      locationCountryInfo,
      candidateProfile: { customer_uid },
      getCandidateBands,
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getCandidateType.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchCandidateType")];
    }
    if (!getSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("speciality")];
    }
    if (!getSubSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("subSpeciality")];
    }
    if (!getCandidateLevel.length) {
      appendFilterAction = [...appendFilterAction, dispatch("candidateLevel")];
    }
    if (!nurseRegistrationType.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchNurseRegistrationType", { customer_id: customer_uid })];
    }
    if (!locationCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    if (!getCandidateBands.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchCandidateBands")];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initFetchAvailabilityInfoOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      getLocationRegion,
      getRegionCountry,
      getCandidatePreferredRegionList,
      getCandidatePreferredCountryList,
      getCurrency
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getLocationRegion.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationRegion")];
    }
    if (!getRegionCountry.length) {
      appendFilterAction = [...appendFilterAction, dispatch("regionCountry")];
    }
    if (!getCandidatePreferredRegionList.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchCandidatePreferredRegionList")]
    }
    if (!getCandidatePreferredCountryList.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchCandidatePreferredCountryList")]
    }
    if (!getCurrency.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchCurrency")]
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initFetchLicenseInfoOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      getLocationCountryInfo,
      getIssueAuthority,
      // getCandidateLevel
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    if (!getIssueAuthority.length) {
      appendFilterAction = [...appendFilterAction, dispatch("issueAuthority")];
    }
    // if (!getCandidateLevel.length) {
    //   appendFilterAction = [...appendFilterAction, dispatch("candidateLevel")];
    // }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initFetchQualificationInfoOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      getLocationCountryInfo,
      getIssueAuthority
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    if (!getIssueAuthority.length) {
      appendFilterAction = [...appendFilterAction, dispatch("issueAuthority")];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initFetchExperienceInfoOptions({ commit, dispatch, getters }) {
    let {
      getCandidateExperiences,
      getCandidateExpOrgTypes,
      locationCountryInfo,
      getOrgNature,
      getOrgAdditionalInfo,
      getCandidateLevel,
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getCandidateExperiences?.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchCandidateExperiences")];
    }
    if (!getCandidateExpOrgTypes?.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchCandidateExpOrgTypes")];
    }
    if (!locationCountryInfo?.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    if (!getOrgNature?.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchOrgNature")];
    }
    if (!getOrgAdditionalInfo?.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchOrgAddtionalInfo")];
    }
    if (!getCandidateLevel.length) {
      appendFilterAction = [...appendFilterAction, dispatch("candidateLevel")];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  hideErrorMsgModal({ commit, dispatch }) {
    let data = {
      modalTitle: null,
      modalColor: null,
      modalContent: null,
      isShowPopup: false
    }
    commit("SHOW_ERROR_MSG_MODAL", data)
  },
  initFetchSplInterestOptions({ state, commit, rootState, dispatch, getters }) {
    const { getSpecialInterest } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getSpecialInterest.length) {
      appendFilterAction = [...appendFilterAction, dispatch("specialInterest")];
    }
    Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
    })
  },
  initFetchLanguageOptions({ state, commit, rootState, dispatch, getters }) {
    const { getLocationLanguage, getLanguageProficiency } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getLocationLanguage.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationLanguage")];
    }
    if (!getLanguageProficiency.length) {
      appendFilterAction = [...appendFilterAction, dispatch("languageProficiency")];
    }
    Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
    })
  },
  multiFormEdited({ commit, getters }, payload) {
    const { getMultiFormEdited } = getters;
    let data = getMultiFormEdited;
    for (const [key, value] of Object.entries(payload)) {
      data[key] = value;
    }
    commit('SET_MULTI_FORM_EDITED', data)
  },
  fetchJobListForCandidateID({ state, commit, dispatch, getters }, data) {
    dispatch("showLoader");
    const { pagination, isFavourite } = data;
    if (!pagination) {
      commit("SET_CANDIDATE_JOB_PAGINATION", {
        skip: 0,
        noMoreJobs: false
      });
      commit("RESET_JOB_LIST_FOR_CANDIDATE_ID");
      dispatch("initJobCandidateCount");
      if (isFavourite)
        dispatch("fetchFavouriteCount");
      else
        dispatch("fetchTabFilterCandidateCount");
    }
    const {
      candidateProfile,
      getCandidateJobPagination: { limit, skip },
      getJobListForCandidateID,
      getDisplayStatusSubStatus: statusSubStatus,
      getChildOrgWithCustomerQuery,
      getTabInfo: { reference_type_id, job_group_ids },
    } = getters;
    let candidate_uid = candidateProfile?.candidate_uid
    let queryPayload = { skip, limit };
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    queryString += `&${getChildOrgWithCustomerQuery}`
    if (reference_type_id) queryString += `&reference_type_id=${reference_type_id}`
    if (job_group_ids) queryString += `&group_ids__overlap={${job_group_ids?.join(',')}}`
    return candidate
      .jobListForCandidateID({ candidate_uid, statusSubStatus, queryString })
      .then(res => {
        let { data } = res;
        if (!pagination)
          commit('SET_JOB_LIST_FOR_CANDIDATE_ID', data);
        else
          commit('SET_JOB_LIST_FOR_CANDIDATE_ID', getJobListForCandidateID.concat(data));
        if (data.length < limit) {
          commit("SET_CANDIDATE_JOB_PAGINATION", {
            noMoreJobs: true
          });
        } else {
          commit("SET_CANDIDATE_JOB_PAGINATION", {
            skip: skip + limit,
            noMoreJobs: false
          });
        }
      })
      .catch(err => {
        console.log('Error while Candidate Job Fetch', err);
      })
      .finally(res => {
        dispatch("hideLoader");
        commit("IS_FETCHING_JOBS_FOR_CANDIDATE_ID", false)
      })
  },
  triggerCandidateJobMatch({ state, commit, dispatch, getters }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    dispatch("showLoader");
    return candidate
      .candidateJobMatch({ customer_uid, candidate_uid })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log('Error trigger JobCandidateMatch', err)
        return err
      })
      .finally(() => {
        dispatch("hideLoader")
      })
  },
  getCandidateIdByMail({ state, commit, dispatch, getters }, email) {
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Verifying Spouse E-Mail...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.getCandidateIDByMail(email)
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        return err
      })
      .finally(res => commit("SET_TOAST_HIDE", 3000))
  },
  initUpdateJobStatus({ state, commit, dispatch, getters }, payloadData) {
    const {
      payload: {
        status_id,
        sub_status_id,
        filter_id,
        action_id,
        tab_id,
        current_status,
        current_sub_status,
      },
      job_id,
      candidate_uid,
    } = payloadData;
    let queryPayload = { user_action: 'update' };
    if (sub_status_id)
      queryPayload['sub_status_id'] = sub_status_id;
    if (job_id)
      queryPayload['job_id'] = job_id;
    if (candidate_uid)
      queryPayload['candidate_uid'] = candidate_uid;
    if (current_status)
      queryPayload['current_status'] = current_status;
    if (current_sub_status)
      queryPayload['current_sub_status'] = current_sub_status;
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    let finalPayload = { tab_id, filter_id, action_id, queryString }
    dispatch("showLoader")
    return candidate
      .getUpdateStatusSubStatus(finalPayload)
      .then(async res => {
        const { data } = res;
        const updateStatusSubStatus = data && data.length && data[0] || null;
        await dispatch("updateCandidateJobStatus", { payloadData, updateStatusSubStatus })
        return res;
      })
      .catch(err => {
        console.log("Error while get Update status and substatus", err)
        return err;
      })
      .finally(() => {
        dispatch("hideLoader")
      })
  },
  updateCandidateJobStatus({ state, commit, dispatch, getters }, payload) {
    const { payloadData: {
      job_id,
      candidate_uid,
      payload: {
        reason_id,
        comments,
        expiry_date,
        priority_level,
        action_detail_id,
        selected_slot_date,
        selected_slot_start,
        selected_slot_end,
        interview_link,
        type_of_process,
        interview_mode,
        alloted_time,
        contact_phone,
        isFromDashBoard,
        joining_date,
        is_urgent,
        external_interviewer_ids,
      }
    }, updateStatusSubStatus } = payload;
    dispatch("clearAllToast");
    let queryPayload = {};
    if (reason_id)
      queryPayload['reason_id'] = reason_id;
    if (comments)
      queryPayload['comments'] = comments;
    if (expiry_date)
      queryPayload['expiry_date'] = expiry_date;
    if (priority_level)
      queryPayload['priority_level'] = priority_level;
    if (action_detail_id)
      queryPayload['action_detail_id'] = action_detail_id;
    if (selected_slot_date)
      queryPayload['selected_slot_date'] = selected_slot_date;
    if (selected_slot_start)
      queryPayload['selected_slot_start'] = selected_slot_start;
    if (selected_slot_end)
      queryPayload['selected_slot_end'] = selected_slot_end;
    if (interview_link)
      queryPayload['interview_link'] = interview_link;
    if (type_of_process)
      queryPayload['type_of_process'] = type_of_process;
    if (interview_mode)
      queryPayload['interview_mode'] = interview_mode;
    if (alloted_time)
      queryPayload['alloted_time'] = alloted_time;
    if (contact_phone)
      queryPayload['contact_phone'] = contact_phone;
    if (joining_date)
      queryPayload['joining_date'] = joining_date;
    if (is_urgent != undefined)
      queryPayload['is_urgent'] = is_urgent;
    if (external_interviewer_ids)
      queryPayload['external_interviewer_ids'] = external_interviewer_ids;
    // let queryString = getFilterQueryStringWithoutArray(queryPayload);

    return candidate
      .updateJobCandidateStatus({ job_id, candidate_uid, payload: { ...updateStatusSubStatus, ...queryPayload, job_id, candidate_uid } })
      .then(res => {
        const { data } = res;
        let { getRoleScopes } = getters;
        // if (!isFromDashBoard && getRoleScopes.length && !["system_candidate", "customer_candidate"].includes(getRoleScopes[0])) {
        //   dispatch("fetchJobListForCandidateID", { pagination: false });
        // }
        commit("SET_JOB_DETAILS_FOR_CANDIDATE_ID", res.data);
        let msg = `Job moved into ${data?.status?.display_text}`;
        if (data?.sub_status?.display_text)
          msg = `Job moved into  ${data?.status?.display_text} - ${data?.sub_status?.display_text}`;
        commit("SET_UPDATE_TOAST_VISIBLE", { isShow: true, msg, cssClass: 'bg-success text-white' });
        return res.data;
      })
      .catch(err => {
        const msg = "Error while Updating Job Status"
        commit("SET_UPDATE_TOAST_VISIBLE", { isShow: true, msg, cssClass: "bg-danger text-white" });
        console.log(msg, err);
        return err
      })
  },
  fetchCandidateJobPageInProcessStatus({ commit, dispatch }) {
    commit("SET_CANDIDATE_JOB_PAGE_INPROCESS_LIST", [])
    commit("IS_FETCHING_CANDIDATE_JOB_INPROCESS_LIST", true)
    dispatch("showLoader")
    return tabFilters.getDisplayStatusSubStatusByTab({ tab_id: 3 }).then(firstRes => {
      return tabFilters.getDisplayStatusSubStatusByTab({ tab_id: 4 }).then(secondRes => {
        let status = firstRes.data.concat(secondRes.data)
        commit("SET_CANDIDATE_JOB_PAGE_INPROCESS_STATUS", status)
      })
    }).catch(err => {
      const msg = "Error while getting candidate job in process list"
      console.log(msg, err);
    }).finally(res => {
      dispatch("hideLoader")
      return res
    })
  },
  fetchCandidateJobPageInProcessList({ commit, getters }, payload) {
    const { getCandidateJobPageInProcessStatus, getCandidateJobPageInProcessList, getCandidateJobInProcessPagination, getChildOrgWithCustomerQuery } = getters;
    const { job_id, pagination } = payload;
    if (!pagination) {
      commit("SET_CANDIDATE_JOB_INPROCESS_PAGINATION", {
        skip: 0,
        noMoreCandidates: false,
      })
    }
    let skip = payload.pagination ? getCandidateJobInProcessPagination.skip : 0;
    let limit = getCandidateJobInProcessPagination.limit;
    commit("IS_FETCHING_CANDIDATE_JOB_INPROCESS_LIST", true)
    return tabFilters.fetchJobCandidatesByStatusID({
      skip: skip,
      limit: limit,
      queryString: `job_id=${job_id}&${getChildOrgWithCustomerQuery}`,
      formPayload: getCandidateJobPageInProcessStatus
    }).then(res => {
      let { data } = res;
      if (data.length < limit) {
        commit("SET_CANDIDATE_JOB_INPROCESS_PAGINATION", {
          noMoreCandidates: true
        });
      } else {
        commit("SET_CANDIDATE_JOB_INPROCESS_PAGINATION", {
          skip: skip + limit,
          noMoreCandidates: false
        });
      }
      if (payload.pagination) {
        commit("SET_CANDIDATE_JOB_PAGE_INPROCESS_LIST", getCandidateJobPageInProcessList.concat(data));
      } else {
        commit("SET_CANDIDATE_JOB_PAGE_INPROCESS_LIST", data);
      }
    }).catch(err => {
      const msg = "Error while getting candidate job in process list"
      console.log(msg, err);
    }).finally(res => {
      commit("IS_FETCHING_CANDIDATE_JOB_INPROCESS_LIST", false)
      return res
    })
  },
  resetLastUploadedDocs({ commit }) {
    commit("SET_LAST_UPLOADED_DOCS", null)
  },
  fetchCandidateCommonComments: ({ commit }, candidate_id) => {
    return candidate
      .getCandidateComments(candidate_id)
      .then((res) => {
        commit('CANDIDATE_COMMENTS', res.data)
        return res.data
      })
      .catch((err) => {
        console.log('error fetching Matched candidates', err);
        return err;
      });
  },
  postCandidateComments: ({ dispatch, getters }, payload) => {
    let { candidate_id, comments } = payload;
    return candidate
      .createCandidateComment(candidate_id, { comments: comments })
      .then((res) => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Comment Added successfully'
        });
        return res;
      })
      .catch((err) => {
        console.log('error fetching Matched candidates', err);
        dispatch("showToast", {
          class: 'bg-danger text-white',
          message: 'Error While Adding'
        });
        return err;
      });
  },
  deleteCandidateComment: ({ dispatch }, payload) => {
    const { candidate_id, comment_id } = payload;
    return candidate
      .deleteCandidateComment(candidate_id, comment_id)
      .then((res) => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Comment Deleted successfully'
        });
        return res;
      })
      .catch((err) => {
        console.log('error fetching Matched candidates', err);
        dispatch("showToast", {
          class: 'bg-danger text-white',
          message: 'Error While Deleting'
        });
        return err;
      });
  },
  getCandidateFrontSheet: ({ dispatch }, candidate_id) => {
    dispatch("showLoader");
    return candidate.getCandidate(getCustomerId(), candidate_id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log('error fetching candidate', err);
      }).finally(res => {
        dispatch("hideLoader");
        return res
      })
  },
  fetchPreferredDepartments: ({ dispatch, commit, getters }) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .getPreferredDepartments(customer_uid, candidate_uid)
      .then(res => {
        commit("SET_PREFERRED_DEPARTMENTS", res.data);
        return res.data;
      })
      .catch(err => {
        console.log("error fetching Preferred Departments", err);
        return err;
      })
  },
  addPreferredDepartment({ dispatch, commit, getters }, payload) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.addPreferredDepartment(customer_uid, candidate_uid, payload).then(res => {
      commit("SET_UPLOADED", true);
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        let msg = "Error while uploading Preferred Department";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      })
  },
  updatePreferredDepartment({ dispatch, commit, getters }, { candidate_preferred_departments_id, payload }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updatePreferredDepartment({ customer_uid, candidate_uid, candidate_preferred_departments_id, payload })
      .then(res => {
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
        return res
      })
      .catch(err => {
        let msg = "Error while saving";
        console.log("error updating Preferred Department", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  deletePreferredDepartment({ dispatch, commit, getters }, candidate_preferred_departments_id) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate.deletePreferredDepartment({ customer_uid, candidate_uid, candidate_preferred_departments_id })
      .then(res => {
        if (res.status == 200) {
          dispatch("showToast", { class: 'bg-success text-white', message: 'Preferred Department deleted successfully' });
          dispatch("fetchPreferredDepartments")
        }
      })
      .catch(err => {
        let msg = "Error while Delete Preferred Department";
        console.log(msg, err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      })
  },
  initFetchSpecializationOptions({ getters, dispatch, commit }) {
    const { getSpeciality, getSubSpeciality, getNurseSubSpeciality } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("speciality")];
    }
    if (!getSubSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("subSpeciality")];
    }
    if (!getNurseSubSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchNurseSubSpeciality")];
    }
    Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
    })
  },
  getRegisteredHospitalsByEmail({ dispatch, commit, getters }, payload) {
    const { email, customer_uid, organisation_uid, check_registered } = payload;
    let queryPayload = {}
    if (email)
      queryPayload = { ...queryPayload, email: encodeURIComponent(email) };
    if (customer_uid)
      queryPayload = { ...queryPayload, customer_uid };
    if (organisation_uid)
      queryPayload = { ...queryPayload, organisation_uid };
    if (check_registered)
      queryPayload = { ...queryPayload, check_registered };
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return candidate.getRegisteredHospitalsByEmail({ queryString }).then((response) => {
      let { data } = response
      commit("SET_REGISTERED_HOSPITALS", data);
      return response;
    }).catch((err) => {
      commit("SET_REGISTERED_HOSPITALS", []);
      return err;
    });
  },
  registerCommonCandidate({ dispatch, commit, getters }, payload) {
    return candidate.registerCommonCandidate(payload).then((response) => {
      let { data } = response
      dispatch("showToast", {
        class: 'bg-success text-white',
        message: 'Registered successfully!'
      });
      // router.push(LOGIN_URL())
      return data;
    }).catch((err) => {
      dispatch("showToast", {
        class: 'bg-danger text-white',
        message: err?.response?.data?.detail
      });
      return err;
    });
  },
  registerCommonCandidateForTFW({ dispatch, commit, getters }, payload) {
    return candidate.registerCommonCandidate(payload).then((response) => {
      let { data } = response
      dispatch("showToast", {
        class: 'bg-success text-white',
        message: 'Registered successfully!'
      });
    // router.push({ path: `/tfw/login` });
      return response;
    }).catch((err) => {
      dispatch("showToast", {
        class: 'bg-danger text-white',
        message: err?.response?.data?.detail
      });
      return err;
    });
  },
  registerCommonCandidateWithoutPwd({ dispatch, commit, getters }, payload) {
    return candidate.registerCommonCandidateWithoutPwd(payload).then((response) => {
      let { data } = response
      dispatch("showToast", {
        class: 'bg-success text-white',
        message: 'Profile Shared successfully!'
      });
      return data;
    }).catch((err) => {
      dispatch("showToast", {
        class: 'bg-danger text-white',
        message: err?.response?.data?.detail
      });
      return err;
    });
  },
  generateRandomUuid({ dispatch, commit, getters }) {
    return candidate.generateRandomUuid()
      .then(res => {
        const { data } = res;
        commit("SET_RANDOM_UUID", data);
        return data
      })
      .catch(err => {
        console.log("Error while Generate Random Uuid", err)
        return err
      })
  },
  async uploadTempDoc({ dispatch, commit }, payload) {
    const { uuid, file, fileName } = payload;
    const formData = new FormData();
    formData.append("file", file, fileName);
    dispatch("showLoader")
    return await candidate.uploadTempDoc({ uuid, formData })
      .then(res => {
        const { data } = res;
        return data;
      })
      .catch(err => {
        let message = "Error While Uploading Temp Doc"
        console.log(message, err);
        dispatch("showToast", {
          class: 'bg-danger text-white',
          message: message
        });
        return err;
      }).finally(res => {
        dispatch("hideLoader")
      })
  },
  getTempDocs({ dispatch, commit, getters }, payload) {
    const { uuid, file_name } = payload;
    let queryPayload = {}
    if (uuid)
      queryPayload = { ...queryPayload, uuid }
    if (file_name)
      queryPayload = { ...queryPayload, file_name }
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return candidate.getTempDocs(queryString)
      .then(res => {
        const { data } = res;
        return data;
      })
      .catch(err => {
        console.log("Error While Getting Temp Docs", err)
        return err;
      })
  },
  deleteTempDocs({ dispatch, commit, getters }, payload) {
    return candidate.deleteTempDocs(payload)
      .then(res => {
        const { data } = res;
        return data;
      })
      .catch(err => {
        console.log("Error While Getting Temp Docs", err)
        return err;
      })
  },
  fetchCandidateExpOrgTypes({ commit }) {
    return candidate.getCandidateExpOrgTypes()
      .then(res => {
        const { data } = res
        commit("SET_CANDIDATE_EXP_ORG_TYPES", data)
        return data
      })
      .catch(err => {
        console.log("Error While fetching Candidate Experiences", err)
        return err
      })
  },
  fetchOrgNature({ commit }) {
    const { getCustomerId, getOrgID } = getters;
    let query = getCustomerId && getOrgID
      ? `customer_id=${getCustomerId}&organisation_id=${getOrgID}`
      : ``
    return candidate.getOrgNature(query)
      .then(res => {
        const { data } = res
        commit("SET_ORG_NATURE", data)
        return data
      })
      .catch(err => {
        console.log("Error while fetching Organisation Nature", err)
      })
  },
  fetchOrgAddtionalInfo({ commit }) {
    const { getCustomerId, getOrgID } = getters;
    let query = getCustomerId && getOrgID
      ? `customer_id=${getCustomerId}&organisation_id=${getOrgID}`
      : ``
    return candidate.getOrgAdditionalInfo(query)
      .then(res => {
        const { data } = res
        commit("SET_ORG_ADDITIONAL_INFO", data)
        return data
      })
      .catch(err => {
        console.log("Error while fetching Organisation Additional Info", err)
      })
  },
  fetchCandidateExperiences({ commit, getters }) {
    const candidate_uid = getters.candidateProfile.candidate_uid;
    const customer_uid = getters.candidateProfile.customer_uid;
    return candidate.getCandidateExperiences(customer_uid, candidate_uid)
      .then(res => {
        const { data } = res
        commit("SET_CANDIDATE_EXPERIENCES", data)
        return data
      })
      .catch(err => {
        console.log("Error While fetching Candidate Experiences", err)
        return err
      })
  },
  uploadCandidateExperience({ getters, commit, dispatch }, payload) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.createCandidateExperience(customer_uid, candidate_uid, payload).then(async res => {
      dispatch("fetchCandidateExperiences");
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        let msg = "Error while uploading";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      })
  },
  updateCandidateExperience({ getters, commit, dispatch }, payload) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updateCandidateExperience(customer_uid, candidate_uid, payload)
      .then(async res => {

        dispatch("fetchCandidateExperiences");
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
        return res
      })
      .catch(err => {
        let msg = "Error while saving";
        console.log("error in updating Experience", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  deleteCandidateExperience({ getters, dispatch }, experience_id) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate.deleteCandidateExperience(customer_uid, candidate_uid, experience_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Experience deleted successfully' });
        dispatch("fetchCandidateExperiences");
      }
    })
      .catch(err => {
        if (err) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting Experience' });
        }
        console.log("error in deleting Qualification", err);
        return err;
      });
  },
  fetchCandidateInterviewSchedule({ getters, dispatch, commit }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    let queryPayload = { action_id: 18, status_id__in: [54], sub_status_id__in: [55, 57, 47], completed_by__in: ["null"] };
    if (candidate_uid)
      queryPayload = {
        ...queryPayload,
        candidate_uid
      }
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return activityService.jobCandidateTransaction({ queryString })
      .then(res => {
        const { data } = res;
        commit("SET_CANDIDATE_INTERVIEW_SCHEDULES", data);
        return data;
      })
      .catch(err => {
        console.log("error in while fetching interview schedules", err);
        return err;
      })

  },


  getOffersForCandidate({ commit, getters, dispatch }, offerDetails) {
    commit("RESET_OFFER_DETAILS_FOR_CANDIDATE")
    dispatch("showLoader");
    return candidate
      .getOffers(offerDetails.job_id, offerDetails.candidate_uid)
      .then(res => {
        commit("SET_OFFER_DETAILS_FOR_CANDIDATE", res.data);
        return res;
      })
      .catch(err => {
        console.log("Error while fetching Offers For Candidate ", err)
        return err;
      })
      .finally(res => {
        dispatch("hideLoader");
      })
  },
  fetchOffersByCandidate({ commit, getters }, candidate_uid) {
    commit("RESET_CANDIDATE_OFFER")
    return candidate
      .fetchOffersforcandidate(candidate_uid)
      .then(res => {
        commit("SET_CANDIDATE_OFFER", res.data);
        return res;
      })
      .catch(err => {
        console.log("Error while fetching Offers For Candidate ", err)
        return err;
      })
  },
  fetchOffersDocsForCandidate({ commit, getters }, payload) {
    let { customer_uid, organisation_id, candidate_uid } = payload
    let query = `all_rows=true&fetch_row_count=false&action_type=offer&candidate_uid=${candidate_uid}`
    if (organisation_id) query += `&organisation_id=${organisation_id}`
    return candidate
      .fetchActionDocsForCandidate(customer_uid, query)
      .then(res => {
        commit("SET_CANDIDATE_OFFER", res.data);
        return res;
      })
      .catch(err => {
        console.log("Error while fetching Offers For Candidate ", err)
        return err;
      })
  },
  fetchOnBoardDocsForCandidate({ commit, getters }, payload) {
    let { customer_uid, organisation_id, candidate_uid } = payload
    let query = `all_rows=true&fetch_row_count=false&action_type=licensing&candidate_uid=${candidate_uid}`
    if (organisation_id) query += `&organisation_id=${organisation_id}`
    return candidate
      .fetchActionDocsForCandidate(customer_uid, query)
      .then(res => {
        commit("SET_ONBOARD_DETAILS_FOR_CANDIDATE", res.data);
        return res;
      })
      .catch(err => {
        console.log("Error while fetching onboard docs For Candidate ", err)
        return err;
      })
  },
  update_offer({ state, commit, dispatch, getters }, payload) {
    let { job_id, candidate_uid, ...rest } = payload
    const { getCustomerId, getOrgIDFromAccessToken } = getters
    return candidate
      .updateJobOffer1(job_id, candidate_uid, { ...rest, customer_uid: getCustomerId, organisation_id: getOrgIDFromAccessToken })
      .then(res => {
        let data = {
          customer_uid: getCustomerId,
          candidate_uid: candidate_uid
        }
        dispatch("fetchUploadedDocumentbyCandidateId", data)
        return res;
      }).catch(err => {

        console.log("Error while creating document", err);
        return err;
      })
  },
  fetchUploadedDocumentbyCandidateId({ commit, dispatch, getters }, payload) {
    commit("RESET_UPLOADED_DOCUMENT")
    const { customer_uid, candidate_uid } = payload;
    const { getRole } = getters;
    const { customerAdmin, customerRecruiter, candidateRole } = Role;
    let query = ''
    if (getRole && [customerRecruiter, customerAdmin, ...candidateRole].includes(getRole)) {
      query = "?latest_versions_for=2,11,14&only_latest_versions=false"
    }
    return candidate
      .getUploadedDocument(candidate_uid, query)
      .then(async res => {
        commit("SET_UPLOADED_DOCUMENT", res.data);
      })
      .catch(err => {
        console.log("error fetching 2 uploaded document ", err);
        return err;
      })
      .finally(() => {

      });
  },
  deleteDocumentByCandidateId({ commit, dispatch, getters }, payload) {
    //  const { candidateProfile: { customer_uid } } = getters;
    const { customer_uid, candidate_uid, candidate_document_id } = payload;
    return candidate.deleteDocument(customer_uid, candidate_uid, candidate_document_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Document deleted successfully' });
        dispatch("fetchUploadedDocumentbyCandidateId", payload)
        //dispatch("fetchUploadedDocument")
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting document' });
        }
        console.log("error in deleting document", err);
        return err;
      });
  },
  getOnboardForCandidateByOfferId({ commit, getters }, offer_id) {
    commit("RESET_ONBOARD_DETAILS_FOR_CANDIDATE")
    return candidate
      .getOnboardOfferId(offer_id)
      .then(res => {
        commit("SET_ONBOARD_DETAILS_FOR_CANDIDATE", res.data);
        return res;
      })
      .catch(err => {
        console.log("Error while fetching Offers For Candidate ", err)
        return err;
      })
  },
  getOnboardForCandidateByJob({ commit, getters }, payload) {
    commit("RESET_ONBOARD_DETAILS_FOR_CANDIDATE")
    return candidate
      .getOnboardByCandidateAndJob(payload)
      .then(res => {
        commit("SET_ONBOARD_DETAILS_FOR_CANDIDATE", res.data);
        return res;
      })
      .catch(err => {
        console.log("Error while fetching Offers For Candidate ", err)
        return err;
      })
  },
  //updateOnboardStatus
  update_Onboard: ({ state, commit, dispatch }, payload) => {
    let { offer_id, ...rest } = payload
    return candidate
      .updateOnboardStatus(offer_id, { ...rest })
      .then(res => {
        return res;
      }).catch(err => {
        if (err) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error On Submitting' });
        }
        console.log("Error while creating onboard", err);
        return err;
      })
  },
  updateOfferDocument_customer({ commit, dispatch, getters }, payload) {
    const { customer_uid, candidate_uid, candidate_document_id, actionable } = payload
    return candidate.updateDocument_foraction(customer_uid, candidate_uid, candidate_document_id, actionable).then(async res => {
      if (res.status == 200) {
        //dispatch("showToast", { class: 'bg-success text-white', message: 'Document updated successfully' });
        // dispatch("fetchUploadedDocument_afterOfferUpdated", payload)
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while updating document' });
        }
        console.log("error in updating document", err);
        return err;
      });
  },
  fetchUploadedDocument_afterOfferUpdated({ commit, dispatch, getters }, payload) {
    commit("RESET_UPLOADED_DOCUMENT")
    const { customer_uid, candidate_uid } = payload
    //const { candidateProfile: { customer_uid, candidate_uid }, getRole } = getters;
    const { customerAdmin, customerRecruiter } = Role;
    let query = ''
    // if (getRole && [customerRecruiter, customerAdmin].includes(getRole)) {
    query = "?latest_versions_for=2,11,14&only_latest_versions=false"
    //  }
    return candidate
      .getUploadedDocument(candidate_uid, query)
      .then(async res => {
        commit("SET_UPLOADED_DOCUMENT", res.data);
      })
      .catch(err => {
        console.log("error fetching uploaded document ", err);
        return err;
      })
      .finally(() => {

      });
  },
  fetchCandidateBands({ commit, getters }) {
    return candidate.fetchCandidateBands()
      .then(res => {
        const { data } = res
        commit("SET_CANDIDATE_BAND", data);
        return res;
      })
      .catch(err => {
        console.log("Error while fetching Candidate Bans", err)
        return err;
      })
  },
  getRegisteredOhrCandidateByEmail({ dispatch, commit, getters }, payload) {
    const { email, uniquecode } = payload;
    let original_ohr_id = uniquecode
    let queryPayload = {}
    if (original_ohr_id)
      queryPayload = { ...queryPayload, original_ohr_id };
    if (email)
      queryPayload = { ...queryPayload, email: encodeURIComponent(email) };
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return candidate.getRegisteredOhrCandidateByEmail({ queryString }).then((response) => {
      let { data } = response
      commit("SET_OHR_CANDIDATE", data);
      return data;
    }).catch((err) => {
      console.log(err)
      //  commit("SET_CANDIDATE", []);
      return err;
    });
  },
  fetchCandidateInterview({ getters, dispatch, commit }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    let queryPayload = { action_id: 18, status_id__in: [54,], sub_status_id__in: [55, 57], completed_by__isnull: true };
    if (candidate_uid)
      queryPayload = {
        ...queryPayload,
        candidate_uid
      }
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    dispatch("showLoader");
    return activityService.jobCandidateTransaction({ queryString })
      .then(res => {
        const { data } = res;
        commit("SET_CANDIDATE_INTERVIEW_SCHEDULES", data);
        return data;
      })
      .catch(err => {
        console.log("error in while fetching interview schedules", err);
        return err;
      })
      .finally(res => {
        dispatch("hideLoader");
      })
  },
  createCandidateRecord: ({ state, commit, getter, dispatch }, payload) => {
    const { job_id, candidate_uid, customer_uid, organisation_id, organisation_document_id, actionable } = payload;
    return candidate
      .createCandidateRecord(job_id, candidate_uid, customer_uid, organisation_id, organisation_document_id, actionable)
      .then(res => {
        //dispatch("fetchUploadedDocument", job_id)
        dispatch("fetchUploadedDocumentbyCandidateId", payload)
        commit("SET_UPLOADED_DOCUMENT", true);
        commit("SET_LAST_UPLOADED_DOCS", res.data)
        dispatch("showToast", { class: 'bg-success text-white', message: 'Uploaded successfully' })
        return res;
      })
      .catch(err => {
        commit("SET_UPLOADED_DOCUMENT", true);
        let msg = "Error while uploading photo";
        dispatch("showToast", { class: 'bg-danger text-white', message: msg })
        return err;
      })
      .finally(() => { });
  },
  deleteCandidaterecord: ({ state, commit, getter, dispatch }, payload) => {
    let { job_id, customer_uid, candidate_uid, document_id } = payload;
    return candidate.deleteCandidaterecord(customer_uid, candidate_uid, document_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Document deleted successfully' });
        // dispatch("fetchUploadedDocument", job_id);
        dispatch("fetchUploadedDocumentbyCandidateId", payload)
      }
    })
      .catch(err => {
        if (err) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting document' });
        }
        console.log("error in deleting document", err);
        return err;
      });
  },
  createAgencyCVRequest: ({ state, commit, getters, dispatch }, payload) => {
    return candidate
      .createAgencyCVRequest(payload)
      .then(res => {
        const { data } = res;
        dispatch("fetchAgencyTransaction");
        dispatch("showToast", { class: 'bg-success text-white', message: 'Agency CV Requested successfully' });
        return data;
      })
      .catch(err => {
        const message = "Error while Create Agency CV Req";
        console.log(message, err);
        dispatch("showToast", { class: 'bg-danger text-white', message });
        return err;
      })
  },
  fetchJobCandidateFrontSheet: ({ commit, getters, dispatch }, payload = {}) => {
    commit("RESET_JOB_CANDIDATE_FRONT_SHEET");
    const queryString = getFilterQueryStringWithoutArray(payload);
    return candidate
      .getJobCandidateFrontSheet(queryString)
      .then(res => {
        const { data } = res;
        commit("SET_JOB_CANDIDATE_FRONT_SHEET", data);
        return res;
      })
      .catch(err => {
        console.log("Error While Fetch Frontsheet")
        return err;
      })
  },
  addJobCandidateFrontSheet: ({ commit, dispatch, getters }, payload) => {
    dispatch("showLoader");
    return candidate
      .addJobCandidateFrontSheet(payload)
      .then(res => {
        const { data } = res;
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Frontsheet Saved Successfully",
        })
        return res;
      })
      .catch(err => {
        const message = "Error While Save Frontsheet";
        dispatch("showToast", {
          class: "bg-danger text-white",
          message,
        })
        return err;
      })
      .finally(() => {
        dispatch("hideLoader")
      })
  },
  fetchJCPNegativeFlows: ({ commit, dispatch, getters }, payload = {}) => {
    commit("RESET_JCP_NEGATIVE_FLOW")
    const { job_id, candidate_uid } = payload;
    let queryPayload = {}
    if (job_id)
      queryPayload = { ...queryPayload, job_id }
    if (candidate_uid)
      queryPayload = { ...queryPayload, candidate_uid }
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    commit("SET_FETCHING", true);
    return candidate
      .jcpNegativeFlow(queryString)
      .then(res => {
        const { data } = res;
        commit("SET_JCP_NEGATIVE_FLOW", data)
        return res;
      })
      .catch(err => {
        const message = "Error While Fetch Negative Flows";
        dispatch({
          class: "bg-danger text-white",
          message
        })
        console.log(message, err);
        return err;
      })
      .finally(() => {
        commit("SET_FETCHING", false);
      })
  },
  fetchDocsForCandidate({ commit, getters, dispatch }, payload = {}) {
    let { customer_uid } = payload
    let queryPayload = {
      ...payload,
      all_rows: true,
      fetch_row_count: false,
    };
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    return candidate
      .fetchActionDocsForCandidate(customer_uid, queryString)
      .then(res => {
        const { data } = res;
        const preOfferDocs = data.filter(({ action_type }) => action_type === 'pre_offer') || []
        const offerDocs = data.filter(({ action_type }) => action_type === 'offer') || []
        const licensingDocs = data.filter(({ action_type }) => action_type === 'licensing') || []
        const visaDocs = data.filter(({ action_type }) => action_type === 'visa') || []
        const joiningDocs = data.filter(({ action_type }) => action_type === 'joining') || [];
        commit("SET_PRE_OFFER_CANDIDATE_DOCS", preOfferDocs);
        commit("SET_CANDIDATE_OFFER", offerDocs);
        commit("SET_ONBOARD_DETAILS_FOR_CANDIDATE", licensingDocs);
        commit("SET_VISA_CANDIDATE_DOCS", visaDocs);
        commit("SET_JOINING_CANDIDATE_DOCS", joiningDocs);
        return res;
      })
      .catch(err => {
        const message = "Error while fetching Candidate Documents";
        dispatch("showToast", { message, class: "text-white bg-danger" })
        console.log(message, err)
        return err;
      })
  },
  getJobListForCandidate: ({ getters, commit, dispatch }, payload = {}) => {
    const queryString = getFilterQueryStringWithoutArray(payload);
    return candidate
      .jobListForCandidate(queryString)
      .then(res => {
        const { data } = res;
        commit("SET_JOB_LIST_FOR_SPECIFIC_CANDIDATE", data);
        return res;
      })
      .catch(err => {
        const message = "Error while fetching job list";
        dispatch("showToast", {
          message,
          class: "text-white bg-danger"
        })
        return err;
      })
  },
  checkCandidateInTFW: ({ commit, dispatch }, payload = {}) => {
    const queryString = getFilterQueryStringWithoutArray(payload);
    return candidate
      .checkCandidateInTFW(queryString)
      .then(res => {
        if(res?.data)
        {
          dispatch("showToast", { class: 'text-white bg-danger', message: "You are Already Register with TalentFind World" });
          return res;
        }
        return 0;
      })
      .catch(err => {
        const message = "Error while Checking Candidate Exist in TFW";
        console.log(message, err)
        return err;
      })
  },
  fetchCandidateOrganisation:({ commit }) => {
    return candidate
      .getCandidateOrganisations()
      .then(res => {
        const { data } = res;
        commit("SET_CANDIDATE_ORGANISATION", data);
        return res;
      })
      .catch(err => {
        const message = "Error while getting Candidates Organisations";
        console.log(message, err)
        return err;
      })
  },
  fetchCandidateOrganisationByAdmin:({ commit }, user_id) => {
    return candidate
      .getCandidateOrganisationsByAdmin(user_id)
      .then(res => {
        const { data } = res;
        commit("SET_CANDIDATE_ORGANISATION_BY_ADMIN", data);
        return res;
      })
      .catch(err => {
        const message = "Error while getting Candidates Organisations by admin";
        console.log(message, err)
        return err;
      })
  },
  fetchStudentQualifications:({ commit }, professional_type) => {
    return candidate
      .getStudentQualifications(professional_type)
      .then(res => {
        const { data } = res;
        commit("SET_QUALIFICATIONS", data);
        return res;
      })
      .catch(err => {
        const message = "Error while getting Qualifications";
        console.log(message, err)
        return err;
      })
  },
  fetchSponsorsList :({ commit }) => {
    return candidate
      .getSponsorsList()
      .then(res => {
        const { data } = res;
        commit("SET_SPONSORS_LIST", data);
        return res;
      })
      .catch(err => {
        const message = "Error while getting Sponsers";
        console.log(message, err)
        return err;
      })
  },
  fetchCourseTestTypes :({ commit }) => {
    return candidate
      .getCourseTestTypes()
      .then(res => {
        const { data } = res;
        commit("SET_COURSE_TEST_TYPES", data);
        return res;
      })
      .catch(err => {
        const message = "Error while getting Course Types";
        console.log(message, err)
        return err;
      })
  },
  fetchSponsoredCandidates:({commit}, candidate_uid) => {
    return candidate.getSponsoredDetails(candidate_uid)
    .then(res => {
      const { data } = res;
      commit("SET_SPONSORED_CANDIDATES", data);
      return res;
    })
    .catch(err => {
      const message = "Error while Sponsored Candidates";
      console.log(message, err)
      return err;
    })
  },
  fetchLicenseRegType:({commit}) => {
    return candidate.getLicenseRegTypeDetails()
    .then(res => {
      const { data } = res;
      commit("SET_LICENSE_REG_TYPE", data);
      return res;
    })
    .catch(err => {
      const message = "Error while fetch license reg";
      console.log(message, err)
      return err;
    })
  },
  fetchCourseCount:({commit},payload) => {
    const { customer_uid, organisation_id } = payload
    return candidate.getCourseCount( customer_uid, organisation_id )
    .then(res  => {
      const { data } = res;
      commit("SET_COURSE_COUNT_STATS", data);
      return res;
    })
    .catch(err => {
      const message = "Error while fetching candidate course stats count";
      console.log(message, err)
      return err;
    })
  },
  createCandidateByAdmin:({ dispatch, getters }, payload) => {
    const { getCustomerId } = getters
    payload = {
      ...payload,
      customer_id: getCustomerId,
    };
    return candidate.createCandidateByAdmin(payload)
    .then(res  => {
      const { data } = res;
      if (res.status == 200) {
        dispatch("showToast", { class: 'text-white bg-success', message: "Candidate created successfully" });
      }
      return res;
    })
    .catch(err => {
      const message = "Error while creating temproary candidate";
      console.log(message, err)
      return err;
    })
  }
};

export default { state, getters, mutations, actions };
