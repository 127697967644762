<template>
  <div class="add-organisation mb-5">
    <h3 class="text-primary main-title m-1 pt-1">Add Organisation</h3>
    <ValidationObserver ref="addCustomer" v-slot="{ handleSubmit }">
      <form id="addCustomer" @submit.prevent="handleSubmit(onSubmit)">
        <CCard class="mt-2">
          <CCardBody>
            <CRow>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Organisation Type</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="organisation_type_id"
                        :value="customer.organisation_type_id"
                        :options="
                          options && options['organization_type']
                            ? options['organization_type']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <!-- <CCol lg="6" v-if="customer['organisation_type_id'] && customer['organisation_type_id']['code'] != 4">
                <CRow class="mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Child Organisation ?</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <RadioButton
                        name="is_child_organisation"
                        :value="customer.is_child_organisation"
                        :options="options && options['is_child_organisation'] ? options['is_child_organisation'] : []"
                        @change="handleRadioButton"
                      />
                  </div>
                </CRow>
              </CCol> -->
              <CCol lg="6" v-if="isPartnerAgency">
                <CRow class="mb-3">
                  <label class="col-lg-12 col-md-12"> 
                    Candidate Portal
                    </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <CSwitch
                      name="is_candidate_portal"
                      :color="'primary'"
                      :checked="customer.is_candidate_portal"
                      shape="pill"
                      variant="3d"
                      @change.native="
                        handleChangeSelect(
                          'is_candidate_portal',
                          $event.target.checked
                        )
                      "
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow name="institution_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Institution Name</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="name"
                        :value="customer.name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="address" class="mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Description
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="description"
                        :value="customer.description"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow class="mb-3" name="country">
                  <label class="required col-lg-12 col-md-12">Country</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="country_ids"
                        :value="customer.country_ids"
                        :options="
                          options && options['country']
                            ? options['country']
                            : []
                        "
                        :multiple="true"
                        :clearable="false"
                        @input="handleChangeMultiSelectAsArray"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label name="location" class="required col-lg-12 col-md-12"
                    >City</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="location_ids"
                        :value="customer.location_ids"
                        :options="
                          options && options['location']
                            ? options['location']
                            : []
                        "
                        :multiple="true"
                        :clearable="false"
                        @input="handleChangeMultiSelectAsArray"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow name="district" class="mb-3">
                  <label class="col-lg-12 col-md-12">Districts</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="district_ids"
                      :value="customer.district_ids"
                      :options="
                        options && options['districts']
                          ? options['districts']
                          : []
                      "
                      :multiple="true"
                      :clearable="false"
                      @input="handleChangeMultiSelectAsArray"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="license_authority" class="mb-3">
                  <label class="col-lg-12 col-md-12">License Authority</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="issue_auth_ids"
                      :value="customer.issue_auth_ids"
                      :options="
                        options && options['license_authority']
                          ? options['license_authority']
                          : []
                      "
                      :multiple="true"
                      :clearable="false"
                      @input="handleChangeMultiSelectAsArray"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <h5 class="pb-2 text-primary">Main Contact</h5>
            <CRow>
              <CCol lg="6">
                <CRow name="first_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12">First Name</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="first_name"
                        :value="customer.first_name"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="last_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Last Name</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="last_name"
                        :value="customer.last_name"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow name="phone" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Phone</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="phone"
                      :rules="{
                        required: true,
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInput
                        name="phone"
                        :value="customer.phone"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        :error="errors[0]"
                        @change="handleInput"
                        ref="phoneNo"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="email" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Email</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="email"
                        :value="customer.email"
                        :error="errors[0]"
                        @input="handleInput"
                        autocomplete="new-email"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="password" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Password</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="password"
                      :rules="{
                        required: true,
                        password_length: 8,
                        password_strength:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PasswordInput
                        name="password"
                        :value="customer.password"
                        @input="handleInput"
                        :error="errors[0]"
                        autocomplete="new-password"
                      />
                      <small class="hint">
                        Password format: 8 characters - containing upper & lower
                        case letters, numbers and a special character. No words.
                      </small>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="confirm_password" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Confirm Password</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|confirmed:password"
                      v-slot="{ errors }"
                    >
                      <PasswordInput
                        name="confirmPassword"
                        :value="confirmPassword"
                        @input="handleConfirmPassword"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <div class="text-center mx-0 mt-2 pt-4 action-area">
          <CButton
            variant="outline"
            size="lg"
            type="button"
            color="primary"
            shape="square"
            class="px-5 m-1 mt-3"
            @click="resetForm"
            >Clear All</CButton
          >
          <CButton
            type="submit"
            size="lg"
            color="primary"
            shape="square"
            class="px-5 m-1 mt-3"
            >Submit</CButton
          >
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import Vue from "vue";
import { CONSTANT } from "../../helpers/helper.js"

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";

import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  regex,
  min,
  numeric,
} from "vee-validate/dist/rules";
import { mapGetters, mapActions } from "vuex";

extend("required", { ...required, message: "This field is required" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("email", { ...email, message: "Invalid email" });
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});
export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    PasswordInput,
    RadioButton,
  },
  data() {
    return {
      customer: {
        customer_type_id: null,
        name: null,
        address: null,
        location_id: null,
        country_ids: null,
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        password: null,
      },
      payload: {},
      confirmPassword: null,
    };
  },
  computed: {
    ...mapGetters([
      "locationCountryInfo",
      "dialingCodeWithCountryCode",
      "getOrganisationTypeList",
      "licenseIssuedByCountrys",
      "getLocationByCountrysID",
      "getDistrictsByLocationsID",
    ]),
    options() {
      return {
        country: this.customCountry || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        location: this.customLocation || [],
        is_parent_admin: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        organization_type: this.getOrganisationTypeList || [],
        license_authority: this.license_authority || [],
        districts: this.customDistricts || [],
        is_child_organisation: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
    license_authority() {
      let opt_license_auth =
        this.licenseIssuedByCountrys({
          country_id: this.payload?.country_ids,
        }) || [];
      return this.payload["issue_auth_ids"]
        ? opt_license_auth.filter(
            ({ code }) => !this.payload["issue_auth_ids"].includes(code)
          )
        : opt_license_auth;
    },
    customCountry() {
      return Array.isArray(this.payload["country_ids"]) && !this.isParentAdmin
        ? this.locationCountryInfo.filter(
            ({ code }) => !this.payload["country_ids"].includes(code)
          )
        : this.locationCountryInfo;
    },
    customLocation() {
      let opt_location =
        this.getLocationByCountrysID({
          country_id: this.payload?.country_ids,
        }) || [];
      return Array.isArray(this.payload["location_ids"])
        ? opt_location.filter(
            ({ code }) => !this.payload["location_ids"].includes(code)
          )
        : opt_location;
    },
    customDistricts() {
      let opt_district =
        this.getDistrictsByLocationsID({
          location_id: this.payload?.location_ids,
        }) || [];
      return Array.isArray(this.payload["district_ids"])
        ? opt_district.filter(
            ({ code }) => !this.payload["district_ids"].includes(code)
          )
        : opt_district;
    },
    isPartnerAgency() {
      return this.customer?.organisation_type_id?.code === CONSTANT.ORG_TYPES.partner_agency || false;
    },
  },
  methods: {
    ...mapActions(["initOrganisationFetchOptions", "showToast", "scrollTop"]),
    handleInput(name, value) {
      let hasError = {
        phone: "phone",
      };
      if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
        Vue.set(this.customer, name, value);
        Vue.set(this.payload, name, value);
      }
      if (name === hasError.phone && value === "") {
        this.clearFieldErrors(name, hasError);
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.customer, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    resetForm() {
      this.customer = {
        customer_type_id: null,
        name: null,
        address: null,
        location_ids: null,
        country_ids: null,
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        password: null,
      };
      this.payload = {};
      this.confirmPassword = null;
      this.$refs.phoneNo.resetPhoneno();
    },
    async onSubmit() {
      const isValid = await this.$refs.addCustomer.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      if (this.payload?.organisation_type_id == 4) {
        // 4 - Advertiser, so delete the issue_auth_ids and child organisation type
        delete this.payload["issue_auth_ids"];
        // delete this.payload['is_child_organisation']
      }
      let finalPayload = {
        ...this.payload,
      };
      this.$emit("submit", finalPayload);
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        }
      });
    },
    handleRadioButton(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }

      Vue.set(this.customer, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
    handleChangeMultiSelectAsArray(name, value) {
      Vue.set(this.customer, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };
    },
    checkDepententSelect({ changedField, depententField, depentent }) {
      let value =
        this.customer[depententField] && !this.isParentAdmin
          ? this.customer[depententField].filter((val) =>
              this.payload[changedField].includes(val[depentent])
            )
          : [];
      this.handleChangeMultiSelectAsArray(depententField, value);
    },
  },
  mounted() {
    this.initOrganisationFetchOptions();
    this.scrollTop();
  },
  watch: {
    "customer.country_ids"() {
      this.checkDepententSelect({
        changedField: "country_ids",
        depententField: "location_ids",
        depentent: "country_id",
      });
    },
    "customer.location_ids"() {
      this.checkDepententSelect({
        changedField: "location_ids",
        depententField: "district_ids",
        depentent: "location_id",
      });
    },
  },
};
</script>