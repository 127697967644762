var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',[(_vm.isFetchingGenericFilter)?_c('LoadingOverlay'):_vm._e(),_c('CCol',{staticClass:"m-1 pt-1",attrs:{"md":"12"}},[_c('GenericFilter',{attrs:{"basicFilterlength":_vm.basicFilterlength,"filterSchema":_vm.currentPageSchema,"selectedFilters":_vm.selectedFilters,"filterDependencyObj":_vm.filterDependencyObj,"isFetchingFilter":_vm.isFetchingGenericFilter,"callback":_vm.updateRouterURL,"searchCallback":_vm.searchCallback,"filterAction":_vm.filterAction},on:{"changeOrder":_vm.changeFilterOrder}}),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CandidateCount',{attrs:{"totalCount":_vm.getTotalCount,"filterCount":_vm.getCandidateCount}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('Pagination',{attrs:{"total":_vm.getCandidateCount,"perPage":_vm.getCandidatePaginationLimit,"maxVisibleButtons":_vm.isMobile ? 3 : 5,"position":_vm.isMobile ? 'left' : 'end'},on:{"pagechanged":_vm.pagechanged}})],1)],1)],1)],1),_c('CRow',[_c('CCol',{staticClass:"mb-5",attrs:{"md":"12"}},[_c('CRow',[_c('CCol',{attrs:{"col":"12"}}),_vm._l((_vm.getCandidates),function(item,index){return _c('CCol',{key:index,attrs:{"sm":"6","md":"3"}},[(_vm.isFetchingCandidates)?_c('div',{staticClass:"skeleton-card"}):_c('CandidateList',{attrs:{"item":item,"index":index}})],1)}),(
            !_vm.isFetchingGenericFilter &&
            !_vm.isFetchingCandidates &&
            !_vm.getCandidates.length
          )?_c('CCol',{staticClass:"d-flex justify-content-center"},[_c('h1',[_vm._v("No Data found!")])]):_vm._e()],2),(_vm.isFetchingCandidates)?_c('CRow',{staticClass:"d-flex justify-content-around align-items-center",staticStyle:{"height":"200px"}},[_c('div',{staticClass:"has-loading-overlay"},[_c('div',{staticClass:"skeleton-card"})])]):_vm._e(),(
          _vm.getCandidates.length &&
          _vm.getCandidates.length < _vm.getCandidatePaginationLimit &&
          !_vm.isFetchingGenericFilter &&
          !_vm.isFetchingCandidates
        )?_c('div',{staticClass:"d-flex justify-content-center"},[_c('span',{staticClass:"btn-pill btn-primary p-2"},[_vm._v("No more records to display!")])]):_vm._e()],1)],1),(_vm.isScrollTop)?_c('button',{staticClass:"btn btn-primary btn-floating btn-lg",attrs:{"id":"btn-back-to-top","title":"Go to top"},on:{"click":function($event){return _vm.scrollToTop()}}},[_c('i',{staticClass:"fas fa-arrow-up"})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }