import { getFilterQueryStringWithoutArray, Role, getSkipLimitBypage, getPageNumberByIndexSkipLimitTotal } from "@/helpers/helper.js";
import customer from "@/services/customer";
import m from "moment";
import _ from 'lodash'
import Vue from 'vue'
import jobBoard from "../services/jobBoard";
import router from "@/router/index";

const ageingDates = {
  'ageing_less_then_7_days': {
    gt: -1,
    lte: 7
  },
  'ageing_less_then_15_days': {
    gt: 7,
    lte: 15
  },
  'ageing_less_then_30_days': {
    gt: 15,
    lte: 30
  },
  'ageing_less_then_90_days': {
    gt: 30,
    lte: 90
  },
  'ageing_less_then_180_days': {
    gt: 90,
    lte: 180
  },
  'ageing_less_then_365_days': {
    gt: 180,
    lte: 365
  }
}

export function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}
// initial state

const getDefaultState = () => {
  return {
    fullPathCandidateListBackUrl: [],
    isFetchingCandidates: false,
    isFetchingCandidatesDetails: false,
    candidates: [],
    profiles: [],
    selectedCandidate: {},
    candidateDetails: {},
    candidateListFilter: {},
    candidatePagination: {
      limit: 10,
      skip: 0,
      noMoreDataFromCandidateList: false
    },
    candidateTotal: 0,
    getTotalCount: 0,
    backcount: 0,
    shortlistCandidates: [],
    shortlistedCount: 0,
    interviewlistCandidates: [],
    recruitlistCandidates: [],
    shortliststatus: {},
    interviewstatus: {},
    releasestatus: {},
    recruitstatus: {},
    reasons: [],
    jobs: [],
    currentNavigationCandidatesPage: [],
    dashboardMetrics: {},
    candidateExpiryCount: {},
    getAllCandidateJobStatus: null,
    getAllJobs: null,
    selectedCandidateCustomerID: null,
  }
}
const state = getDefaultState();

// create getters for all state variables
const getters = {
  getAllCandidateJobStatus: (state) => state.getAllCandidateJobStatus,
  getAllJobsByExpiryDate: (state) => {
    const jobsByExpiryDate = {}
    _.keys(ageingDates).forEach(key => {
      jobsByExpiryDate[key] = state.getAllJobs?.filter(job => {
        const today = m()
        const dateDiff = m(job.expiry_review_date).diff(today.format('YYYY-MM-DD'), 'days')
        return dateDiff > ageingDates[key].gt && dateDiff <= ageingDates[key].lte
      })
    });
    return jobsByExpiryDate
  },
  candidateListBackUrl: (state, getters, rootState) => {
    let { getRoleScopes } = getters;
    let candidateListBackUrl = {
      admin: "/candidate-list?active=%5Btrue,null%5D&deleted=%5Bfalse%5D",
      facility: "/candidate-list"
    }
    const backURL = state.fullPathCandidateListBackUrl;
    if (!backURL.length) {
      if (getRoleScopes[0] === Role.systemAdmin) {
        return candidateListBackUrl.admin
      }
      else {
        return candidateListBackUrl.facility
      }
    }
    else {
      return backURL[backURL.length - 1]
    }
  },
  isFetchingCandidates: (state, getters, rootState) =>
    state.isFetchingCandidates,
  isFetchingCandidatesDetails: (state, getters, rootState) =>
    state.isFetchingCandidatesDetails,
  getCandidates: (state, getters, rootState) => state.candidates,
  getCandidateCount: (state, getters, rootState) => state.candidateTotal,
  noMoreDataFromCandidateList: (state, getters, rootState) =>
    state.candidatePagination.noMoreDataFromCandidateList,
  candidateListFilter: (state, getters, rootState) => state.candidateListFilter,
  getCandidatePaginationSkip: (state, getters, rootState) =>
    state.candidatePagination.skip,
  getCandidatePaginationLimit: (state, getters, rootState) =>
    state.candidatePagination.limit,
  getCandidatePaginationPageNumber: (state, getters, rootState) => {
    const {
      getCandidatePaginationSkip: skip,
      getCandidatePaginationLimit: limit,
      getCandidateIndex: index,
      getCandidates
    } = getters;
    return getPageNumberByIndexSkipLimitTotal({ skip, limit, index, total: getCandidates.length });
  },
  getCandidateDetail: (state, getters, rootState) => state.candidateDetails,
  getInProcessProfile: (state, getters, rootState) => state.profiles,
  getSelectedCandidate: (state, getters, rootState) => state.selectedCandidate,
  selectedJobId: (state, getters, rootState) => {
    let { getSelectedCandidate } = getters;
    return getSelectedCandidate.job_id;
  },
  currentNavigationCandidatesPage: (state, getters, rootState) => state.currentNavigationCandidatesPage,
  getPreviousCandidateId: (state, getters, rootState) => {
    let { getCandidates, candidateProfile, currentNavigationCandidatesPage, getJobCandidatesByStatusID } = getters;
    let _currentPage = {
      jobCandidateStatus: "Job Candidates Status"
    }
    let candidateIndex;
    if (currentNavigationCandidatesPage.currentPage === _currentPage.jobCandidateStatus) {
      candidateIndex = getJobCandidatesByStatusID.findIndex(e => e.candidate.candidate_uid === candidateProfile?.candidate_uid);
      return candidateIndex > 0 ? getJobCandidatesByStatusID[candidateIndex - 1].candidate?.candidate_uid : 0;
    }
    candidateIndex = getCandidates.findIndex(e => e.candidate_uid === candidateProfile.candidate_uid);
    return candidateIndex > 0 ? getCandidates[candidateIndex - 1].candidate_uid : 0;
  },
  getNextCandidateId: (state, getters, rootState) => {
    let { getCandidates, candidateProfile, currentNavigationCandidatesPage, getJobCandidatesByStatusID } = getters;
    let _currentPage = {
      jobCandidateStatus: "Job Candidates Status"
    }
    let candidateIndex;
    if (currentNavigationCandidatesPage.currentPage === _currentPage.jobCandidateStatus) {
      candidateIndex = getJobCandidatesByStatusID.findIndex(e => e.candidate.candidate_uid === candidateProfile?.candidate_uid);
      return candidateIndex + 1 == getJobCandidatesByStatusID.length ? 0 : getJobCandidatesByStatusID[candidateIndex + 1].candidate?.candidate_uid;
    }
    candidateIndex = getCandidates.findIndex(e => e.candidate_uid === candidateProfile?.candidate_uid);
    return candidateIndex + 1 == getCandidates.length ? 0 : getCandidates[candidateIndex + 1].candidate_uid;
  },
  getBackCount: (state, getters, rootState) => state.backcount,
  getTotalCount: (state, getters, rootState) => state.getTotalCount,
  reasonsFilterByReasonType: (state, getters, rootState) => ({ reasonType }) => {
    return state.reasons && state.reasons.length
      ? state.reasons
        .filter(reasonTypeFilter => {
          return reasonTypeFilter.reason_type.reason_type === reasonType
        })
        .map(val => ({
          label: val.reason,
          code: val.reason_id
        }))
      : []
  },
  getCandidateJobs: (state, getters, rootState) =>
    state?.jobs?.map(val => ({
      // label: val.job_title + '-' + val.job_id,
      label: `${val.job_title} - ${val.display_uid} | ${val.organisation_name}`,
      code: val.job_id,
    })),

  //selected Candidate getters
  getshortlistcandidates: (state, getters, rootState) => state.shortlistCandidates,

  /////

  getinterviewlistcandidates: (state, getters, rootstate) => state.interviewlistCandidates,
  getShortliststatus: (state, getters, rootstate) => state.shortliststatus,
  getInterviewstatus: (state, getters, rootstate) => state.interviewstatus,
  getReleasestatus: (state, getters, rootstate) => state.releasestatus,
  getRecruitstatus: (state, getters, rootstate) => state.recruitstatus,
  getrecruitedlistcandidates: (state, getters, rootstate) => state.recruitlistCandidates,
  getDashboardMetrics: (state, getters, rootState) => state.dashboardMetrics,
  getCandidateExpiryCount: (state, getters, rootState) => state.candidateExpiryCount,
  getReasons: (state, getters, rootState) => state.reasons && state.reasons.length
    ? state.reasons
      .map(val => ({
        label: val.reason,
        code: val.reason_id,
        reason_type: val?.reason_type?.reason_type
      }))
    : [],
  getSelectedCandidateCustomerID: state => state.selectedCandidateCustomerID?.customer_uid,
  getCandidateIndex: (state, getters, rootState) => {
    const { candidateProfile, getCandidates, } = getters;
    const candidateIndex = getCandidates.findIndex(e => e.candidate_uid === candidateProfile?.candidate_uid);
    return candidateIndex;
  }
};

// actions
const actions = {
  setCandidateListBackUrl({ state, commit }, url) {
    commit("SET_CANDIDATE_LIST_BACK_URL", url);
  },
  updateCandidateListBackUrl({ state, commit }, { oldBackURL, newBackURL }) {
    const backURLList = state.fullPathCandidateListBackUrl;
    const index = _.findLastIndex(backURLList, e => e === oldBackURL);
    commit("UPDATE_CANDIDATE_LIST_BACK_URL", { index, newBackURL });
  },
  getDocumentsAction({ state, commit, rootState, dispatch, getters }, { candidate_uid, document_name }) {
    return customer
      .getCandidateDocuments(candidate_uid)
      .then(res => {
        let { data } = res;
        return data
      }).then((data) => {
        const link_to_doc = data[data.length - 1].link_to_doc.replace('/api/v1/', '')
        dispatch('downloadDocument', { url: link_to_doc, document_name })
      })
  },
  downloadDocument(
    { state, commit, rootState, dispatch, getters },
    { url, document_name }
  ) {
    dispatch("showFixedToast",
      { class: 'bg-secondary text-white', message: 'Preparing to Download...' })
    return customer
      .downloadDocumentFile(url)
      .then(res => {
        let { data } = res;
        const fileName = document_name;
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(data);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        dispatch("hideFixedToast")
      })
      .catch(err => {
        dispatch("hideFixedToast")
        dispatch("showToast",
          { class: 'bg-danger text-white', message: 'Download failed. Try again.' })
        console.error("error in Candidate Details", err);
        return err;
      });
  },
  resetCandidateList({ state, commit }, payload) {
    commit("RESET_CANDIDATE_LIST")
  },
  getCandidateDetails: async (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    const { getCustomerId } = getters;
    let customer_uid = getCustomerId;
    if (!customer_uid) {
      await dispatch("getCustomerIDByCandidateID", payload);
      const { getSelectedCandidateCustomerID } = getters;
      customer_uid = getSelectedCandidateCustomerID;
    }
    commit("SET_IS_FETCHING_CANDIDATE_DETAILS", false);
    return customer
      .getCandidateDetail({ candidate_uid: payload, customer_uid })
      .then(res => {
        let { data } = res;
        dispatch("commitCandidateInfo", data);
        return data;
      })
      .catch(err => {
        console.error("error in Candidate Details", err);
        return err;
      })
      .finally(res => {
        commit("SET_IS_FETCHING_CANDIDATE_DETAILS", true);
      })
  },
  getJobByCandidate({ state, commit, rootState, dispatch, getters }, payload) {
    return customer.getJobByCandidate(payload).then(res => {
      let { data } = res;
      if (data.length) {
        dispatch("setSelectedCandidate", data[0]);
      }
      commit("SET_CANDIDATE_DETAIL_PROFILE", data);
    });
  },
  createshortlist({ state, commit, rootState, dispatch, getters }, { shortlistPayload, countPayload }) {
    let { getshortlistcandidates, getCandidates, getQueryResult, noMoreDataFromCandidateList, isCandidateListFetching } = getters;

    return customer.saveshortlistCandidates(shortlistPayload).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate shortlisted successfully' });
        if (getCandidates.length >= 0) {
          for (var i = 0; i < getCandidates.length; i++) {
            if (getCandidates[i].candidate_uid == shortlistPayload.candidate_id) {
              getCandidates.splice(i, 1);
              let data = { lastSlicedCandidateIndex: i }
              dispatch("setCurrentNavigationCandidatesPage", data)
              i = i - 1;
            }
          }
          commit("SET_CANDIDATE_LIST", getCandidates);
          if (countPayload) {
            let queryString = getFilterQueryStringWithoutArray(countPayload);
            dispatch("getFilteredTotalCandidates", queryString);
            dispatch("getTotalSelectedCandidatesCount")
          }

          if (noMoreDataFromCandidateList || isCandidateListFetching) return;
          if (getCandidates.length > 0 && getCandidates.length <= 2) {
            dispatch("candidatesListAction", { pagination: true, query: getQueryResult.queryUrl });
          }
        }
        return res;
      }
    })
      .catch(err => {
        const { status, data } = err.response;
        if (err && status == 500 && data.detail == "MAX SHORTLIST COUNT REACHED") {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'You have reached maximum shortlist count.' });
        }
        console.log("error in ShortlistCandidates", err);
        return err;
      });
  },
  createInterviewlist({ state, commit, rootState, dispatch, getters }, payload) {
    let { getshortlistcandidates } = getters;
    return customer.updateShortlistStatus(payload).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate has been selected for Interview successfully' });
        if (getshortlistcandidates.length >= 0) {
          for (var i = 0; i < getshortlistcandidates.length; i++) {
            if (getshortlistcandidates[i].candidate_uid == payload.candidate_id) {
              getshortlistcandidates.splice(i, 1);
              let data = { lastSlicedCandidateIndex: i }
              dispatch("setCurrentNavigationCandidatesPage", data)
              i = i - 1;
            }
          }
          commit("SET_SHORTLIST_CANDIDATES", getshortlistcandidates);
          dispatch("getTotalSelectedCandidatesCount")
        }
        return res;
      }
    })
      .catch(err => {
        const { status, data } = err.response;
        if (err && status == 500 && data.detail == "MAX INTERVIEW COUNT REACHED") {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'You have reached maximum interview count.' });
        }
        console.log("error in updating Interviewstatus", err);
        return err;
      });
  },
  createRecruitList({ state, commit, rootState, dispatch, getters }, payload) {
    let { getinterviewlistcandidates } = getters;
    return customer.updateShortlistStatus(payload).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate has been selected to Recruit successfully' });
        if (getinterviewlistcandidates.length >= 0) {
          for (var i = 0; i < getinterviewlistcandidates.length; i++) {
            if (getinterviewlistcandidates[i].candidate_uid == payload.candidate_id) {
              getinterviewlistcandidates.splice(i, 1);
              let data = { lastSlicedCandidateIndex: i }
              dispatch("setCurrentNavigationCandidatesPage", data)
              i = i - 1;
            }
          }
          commit("SET_INTERVIEWLIST_CANDIDATES", getinterviewlistcandidates);
          dispatch("getTotalSelectedCandidatesCount")
        }
        return res;
      }
    })
      .catch(err => {
        const { status, data } = err.response;
        if (err && status == 500 && data.detail == "MAX RECRUIT COUNT REACHED") {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'You have reached maximum recruit count.' });
        }
        console.log("error in updating Recruitstatus", err);
        return err;
      });
  },
  releaseCandidate({ state, commit, rootState, dispatch, getters }, payload) {
    let { getshortlistcandidates, getinterviewlistcandidates, getrecruitedlistcandidates, getShortliststatus, getInterviewstatus, getRecruitstatus } = getters;
    let releasefrom = payload.releasefrom;
    delete payload.releasefrom;
    return customer.updateShortlistStatus(payload).then(async res => {
      if (res.status == 200) {
        if (releasefrom === getShortliststatus.status_id) {
          dispatch("showToast", { class: 'bg-success text-white', message: 'Shortlisted candidate released successfully' });
          if (getshortlistcandidates.length >= 0) {
            for (var i = 0; i < getshortlistcandidates.length; i++) {
              if (getshortlistcandidates[i].candidate_uid == payload.candidate_id) {
                getshortlistcandidates.splice(i, 1);
                let data = { lastSlicedCandidateIndex: i }
                dispatch("setCurrentNavigationCandidatesPage", data)
                i = i - 1;
              }
            }
            commit("SET_SHORTLIST_CANDIDATES", getshortlistcandidates);
            dispatch("getTotalSelectedCandidatesCount")
          }
          return res;
        }
        else if (releasefrom === getInterviewstatus.status_id) {
          dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate selected for Interview has been released successfully' });
          if (getinterviewlistcandidates.length >= 0) {
            for (var i = 0; i < getinterviewlistcandidates.length; i++) {
              if (getinterviewlistcandidates[i].candidate_uid == payload.candidate_id) {
                getinterviewlistcandidates.splice(i, 1);
                let data = { lastSlicedCandidateIndex: i }
                dispatch("setCurrentNavigationCandidatesPage", data)
                i = i - 1;
              }
            }
            commit("SET_INTERVIEWLIST_CANDIDATES", getinterviewlistcandidates);
            dispatch("getTotalSelectedCandidatesCount")
          }
        }
        else if (releasefrom === getRecruitstatus.status_id) {
          dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate selected for Recruit has been released successfully' });
          if (getrecruitedlistcandidates.length >= 0) {
            for (var i = 0; i < getrecruitedlistcandidates.length; i++) {
              if (getrecruitedlistcandidates[i].candidate_uid == payload.candidate_id) {
                getrecruitedlistcandidates.splice(i, 1);
                let data = { lastSlicedCandidateIndex: i }
                dispatch("setCurrentNavigationCandidatesPage", data)
                i = i - 1;
              }
            }
            commit("SET_RECRUITLIST_CANDIDATES", getrecruitedlistcandidates);
            dispatch("getTotalSelectedCandidatesCount")
          }
        }
      }
    })
      .catch(err => {
        console.log("error in release candidates", err);
        return err;
      });
  },

  //For setting getNextCandidateId and getPreviousCandidateId function according to current page
  setCurrentNavigationCandidatesPage({ state, commit, rootState, dispatch, getters }, payload) {
    commit("SET_CURRENT_NAVIGATION_PAGE", payload)
  },

  deleteCandidate({ state, commit, rootState, dispatch, getters }, payload) {
    let { candidate_id, reason_id, comments, customer_uid } = payload;
    payload = { reason_id: reason_id, comments: comments }
    return customer.deleteCandidates({ candidate_id, customer_uid, payload }).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-danger text-white', message: 'Candidate deleted successfully' });
        dispatch("sliceCandidateInGetCandidates", candidate_id)
        return res;
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting' });
        }
        console.log("error in Delete Candidate", err);
        return err;
      });
  },
  restoreCandidate({ state, commit, rootState, dispatch, getters }, payload) {
    let { candidate_id, reason_id, comments, customer_uid } = payload;
    payload = { reason_id: reason_id, comments: comments }
    return customer.restoreCandidates({ candidate_id, payload, customer_uid }).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-primary text-white', message: 'Candidate restored successfully' });
        dispatch("sliceCandidateInGetCandidates", candidate_id)
        return res;
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while restoring' });
        }
        console.log("error in Restore Candidate", err);
        return err;
      });
  },
  deactivateCandidate({ state, commit, rootState, dispatch, getters }, payload) {
    let { candidate_id, reason_id, comments, customer_uid } = payload;
    payload = { reason_id: reason_id, comments: comments }
    return customer.deactivateCandidates({ candidate_id, payload, customer_uid }).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-danger text-white', message: 'Candidate deactivated successfully' });
        dispatch("sliceCandidateInGetCandidates", candidate_id)
        return res;
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deactivating candidate' });
        }
        console.log("error in Deactivate Candidate", err);
        return err;
      });
  },
  reactivateCandidate({ state, commit, rootState, dispatch, getters }, payload) {
    let { candidate_id, reason_id, comments, customer_uid } = payload;
    payload = { reason_id: reason_id, comments: comments }
    return customer.reactivateCandidates({ candidate_id, payload, customer_uid }).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-primary text-white', message: 'Candidate reactivated successfully' });
        dispatch("sliceCandidateInGetCandidates", candidate_id)
        return res;
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while reactivating candidate' });
        }
        console.log("error in Reactivate Candidate", err);
        return err;
      });
  },

  sliceCandidateInGetCandidates({ state, commit, rootState, dispatch, getters }, candidate_uid) {
    let { getCandidates } = getters;
    if (getCandidates.length >= 0) {
      for (var i = 0; i < getCandidates.length; i++) {
        if (getCandidates[i].candidate_uid == candidate_uid) {
          getCandidates.splice(i, 1);
          let data = { lastSlicedCandidateIndex: i }
          dispatch("setCurrentNavigationCandidatesPage", data)
          i = i - 1;
        }
      }
      commit("SET_CANDIDATE_LIST", getCandidates);
    }
  },

  getReasons({ state, commit, rootState, dispatch, getters }) {
    return customer.getReasons().then(res => {
      let { data } = res;
      commit("SET_REASONS", data);
      return res;
    })
      .catch(err => {
        console.log("error fetching reasons", err);
        return err;
      });
  },

  async candidatesListAction({ commit, getters, dispatch }, payload) {
    const {
      getCandidatePaginationSkip,
      getCandidatePaginationLimit,
      getCandidates,
      getRoleScopes,
      getTotalCount,
      getCandidateCount,
      // getCustomerId,
      getIsChildUser,
      getOrgIDFromAccessToken: org_id,
      getRelationshipOrgDetail,
      isChildOfIndependentOrg,
      isCandidateSupplierFromAccessToken,
      getUserContactId,
      getOrderByColum
    } = getters;
    commit("SET_IS_FETCHING_CANDIDATE", true);
    dispatch("showLoader");
    let skip = getCandidatePaginationSkip;
    const limit = getCandidatePaginationLimit;
    if (!payload.pagination) {
      const skipLimit = await getSkipLimitBypage(payload?.pageNumber, getCandidatePaginationLimit)
      skip = skipLimit.skip;
      commit("SET_CANDIDATE_LIST_PAGINATION", {
        skip,
        noMoreDataFromCandidateList: false,
      });
    }
    let { query } = payload;

    let relationshipOrgDetail = [];
    if (getRelationshipOrgDetail?.length) {
      getRelationshipOrgDetail.forEach(item => {
        relationshipOrgDetail.push({
          org_id: item?.org_id,
          customer_id: item?.customer_id
        })
      });

    }
    // if (isCandidateSupplierFromAccessToken) {
    //   query += `&candidate_contacts___contact_id__in=${getUserContactId}`
    // }
    const currentRole = getRoleScopes?.[0];
    if(currentRole !=  'customer_admin'){
      query = `is_private__in=false,null&${query}`
    }
    const { isTFWPool } = payload;
    commit("SET_CANDIDATE_LIST_FILTER", query);
    if (!payload.pagination && !payload.isPageChanged) {
      dispatch("getFilteredTotalCandidates", query);
      if (!getTotalCount || isTFWPool) {
        dispatch("getTotalCandidates");
      }
    }
    return customer
      .getCandidatesList({
        role: getRoleScopes.length ? getRoleScopes[0] : '',
        skip,
        limit,
        org_id: isCandidateSupplierFromAccessToken || isTFWPool
          ? org_id
          : getIsChildUser && !isChildOfIndependentOrg ? org_id : null,
        query,
        isTFWPool,
        getOrderByColum,
      },
        relationshipOrgDetail?.length ? relationshipOrgDetail : null).then(res => {
          let { data } = res;
          if (data.length < getCandidatePaginationLimit) {
            commit("SET_CANDIDATE_LIST_PAGINATION", {
              noMoreDataFromCandidateList: true
            });
          }
          commit("SET_CANDIDATE_LIST_PAGINATION", {
            skip: skip + getCandidatePaginationLimit,
            noMoreDataFromCandidateList: false
          });
          if (payload.pagination) {
            commit("SET_CANDIDATE_LIST", getCandidates.concat(data));
          } else {
            commit("SET_CANDIDATE_LIST", data);
            commit("SET_SELECTED_CANDIDATE", data[0]);
          }
          commit("SET_IS_FETCHING_CANDIDATE", false);
        })
      .catch(err => {
        commit("SET_IS_FETCHING_CANDIDATE", false);
        console.log("error in fetching candidate data", err);
        return err;
      })
      .finally(() => {
        dispatch("hideLoader");
      });
  },

  getCandidateList({ state, commit, rootState, dispatch, getters }, payload) {
    let {
      getCandidatePaginationLimit,
      getCandidates,
      candidateLevel,
      getTotalCount,
      getCustomerId
    } = getters;
    // need to remove this section ==== start
    let licensingLevelId;
    if (payload && payload.licensing_level_id__in) {
      licensingLevelId = JSON.parse(`[${payload.licensing_level_id__in}]`)[0];
    }
    //======= end
    let { getRoleScopes } = getters;
    commit("SET_CANDIDATE_LIST_FILTER", payload);
    commit("SET_IS_FETCHING_CANDIDATE", true);

    let queryString = getFilterQueryStringWithoutArray(payload);
    dispatch("getFilteredTotalCandidates", queryString);
    if (!getTotalCount) {
      dispatch("getTotalCandidates");
    }
    if (getRoleScopes[0] === Role.systemAdmin) {
      return customer
        .allCandidates(queryString)
        .then(res => {
          let { data } = res;
          commit("SET_CANDIDATE_LIST", data);
          // need to remove this section ==== start
          if (licensingLevelId) {
            let candidateLevelName = candidateLevel.filter(
              val => val.code == licensingLevelId
            )[0].label;
            data = data.map(val => {
              let { level } = val;
              level["candidate_level"] = candidateLevelName;
              return { ...val, level };
            });
          } else {
            data = data.map(val => {
              let { level } = val;
              if (level) {
                level["candidate_level"] = "";
                return { ...val, level };
              }
              return val;
            });
          }
          // ======= end
          if (data.length < getCandidatePaginationLimit) {
            commit("SET_CANDIDATE_LIST_PAGINATION", {
              noMoreDataFromCandidateList: true
            });
          }
          if (payload.skip) {
            commit("SET_CANDIDATE_LIST", getCandidates.concat(data));
          } else {
            commit("SET_CANDIDATE_LIST", data);
            commit("SET_SELECTED_CANDIDATE", data[0]);
          }
          commit("SET_IS_FETCHING_CANDIDATE", false);
          return res;
        })
        .catch(err => {
          commit("SET_IS_FETCHING_CANDIDATE", false);
          console.log("error in candidateType", err);
          return err;
        });
    }
    else {
      dispatch("getallshortliststatus");
      dispatch("fetchFacilityConfig", getCustomerId);
      queryString += "&doh_candidate_status___customer_id__in__and=null";
      return customer
        .allunshortlistCandidates(queryString)
        .then(res => {
          let { data } = res;
          commit("SET_CANDIDATE_LIST", data);
          // need to remove this section ==== start
          if (licensingLevelId) {
            let candidateLevelName = candidateLevel.filter(
              val => val.code == licensingLevelId
            )[0].label;
            data = data.map(val => {
              let { level } = val;
              level["candidate_level"] = candidateLevelName;
              return { ...val, level };
            });
          } else {
            data = data.map(val => {
              let { level } = val;
              if (level) {
                level["candidate_level"] = "";
                return { ...val, level };
              }
              return val;
            });
          }
          // ======= end
          if (data.length < getCandidatePaginationLimit) {
            commit("SET_CANDIDATE_LIST_PAGINATION", {
              noMoreDataFromCandidateList: true
            });
          }
          if (payload.skip) {
            commit("SET_CANDIDATE_LIST", getCandidates.concat(data));
          } else {
            commit("SET_CANDIDATE_LIST", data);
            commit("SET_SELECTED_CANDIDATE", data[0]);
          }
          commit("SET_IS_FETCHING_CANDIDATE", false);
          return res;
        })
        .catch(err => {
          commit("SET_IS_FETCHING_CANDIDATE", false);
          console.log("error in candidateType", err);
          return err;
        });
    }
  },

  fetchMoreCandidateList({ state, commit, rootState, dispatch, getters }) {
    let {
      candidateListFilter,
      noMoreDataFromCandidateList,
      currentNavigationCandidatesPage,
      getCandidates,
      getJobCandidatesByStatusID,
      getJobCandidateStatusPagination,
    } = getters;
    let _currentPage = {
      jobCandidateStatus: "Job Candidates Status"
    }
    if (!(getCandidates?.length || getJobCandidatesByStatusID?.length)) return;
    if (currentNavigationCandidatesPage.currentPage === _currentPage.jobCandidateStatus) {
      if (!getJobCandidateStatusPagination.noMoreCandidates) {
        return dispatch("fetchJobCandidatesByStatusID", { pagination: true })
      }
    }
    else if (!noMoreDataFromCandidateList) {
      return dispatch("candidatesListAction", {
        pagination: true,
        query: candidateListFilter,
      });
    }
  },

  getTotalCandidates({ state, commit, rootState, dispatch, getters }) {
    const { getIsChildUser, getOrgIDFromAccessToken: org_id, getRoleScopes, getRelationshipOrgDetail, isChildOfIndependentOrg, getUser, getUserContactId, isCandidateSupplierFromAccessToken } = getters;
    let payload = ''
    let role = getRoleScopes.length ? getRoleScopes[0] : '';
    let relationshipOrgDetail = [];
    if (getRelationshipOrgDetail?.length) {
      getRelationshipOrgDetail.forEach(item => {
        relationshipOrgDetail.push({
          org_id: item?.org_id,
          customer_id: item?.customer_id
        })
      });
    }
    const currentRole = getRoleScopes?.[0];
    if(currentRole !=  'customer_admin'){
      const privateQry = `is_private__in=false,null`;
      payload = payload ? `${payload}&${privateQry}`:privateQry
    }
    let query = null
    // if (isCandidateSupplierFromAccessToken) {
    //     query =  `candidate_contacts___contact_id__in=${getUserContactId}`
    //   }
    const isTFWPool = router?.currentRoute?.params?.customer_type === 'super_customer';
    let organisation_id = isCandidateSupplierFromAccessToken || isTFWPool
      ? org_id
      : getIsChildUser && !isChildOfIndependentOrg ? org_id : null
    const finalPayload = {
      payload,
      org_id: organisation_id,
      role,
      relationshipOrgDetail: relationshipOrgDetail?.length ? relationshipOrgDetail : null,
      isTFWPool,
    }
    return customer.totalCandidates(finalPayload).then(res => {
      let { data } = res;
      commit("SET_TOTAL_COUNT", data);
    })
      .catch(err => {
        console.error("error in Candidate Details", err);
        return err;
      });
  },

  getFilteredTotalCandidates({ state, commit, rootState, dispatch, getters }, payload) {
    let { getRoleScopes, getIsChildUser, getOrgIDFromAccessToken: org_id, isChildOfIndependentOrg, isCandidateSupplierFromAccessToken } = getters;
    if (getRoleScopes[0] === Role.customerAdmin || getRoleScopes[0] === Role.customerRecruiter) {
      payload += "&doh_candidate_status___customer_id__in__and=null"
    }
    let role = getRoleScopes.length ? getRoleScopes[0] : '';
    const isTFWPool = router?.currentRoute?.params?.customer_type === 'super_customer';
    let organisation_id = isCandidateSupplierFromAccessToken || isTFWPool
      ? org_id
      : getIsChildUser && !isChildOfIndependentOrg ? org_id : null
    const query = ''

    const finalPayload = {
      payload,
      org_id: organisation_id,
      role,
      isTFWPool,
    }

    return customer.totalCandidates(finalPayload).then(res => {
      let { data } = res;
      commit("SET_CANDIDATE_TOTAL", data);
    })
      .catch(err => {
        console.error("error in filtered Candidate Details", err);
        return err;
      });
  },

  getAllCandidateJobStatus({ commit }) {
    return customer.getAllCandidateJobStatus()
      .then(res => {
        let {
          data
        } = res;
        commit("SET_ALL_CANDIDATE_JOB_STATUS", data);
        return data;
      }).catch(err => {
        return err;
      })
  },

  getAllJobs({ commit, getters }, status) {
    const { getRoleScopes } = getters;
    let role = getRoleScopes.length ? getRoleScopes[0] : '';
    return customer
      .getAllJobs(role).then(res => {
        let { data } = res;
        commit("SET_ALL_JOBS", data)
        return res;
      })
      .catch(err => {
        console.log('Error While Fetch All Jobs', err);
        return err;
      })
  },

  // getCandidatesCountsByStatus({ state, commit, dispatch, getters }, params){
  //   let { getCustomerId, getInterviewstatus, getShortliststatus, getRecruitstatus } = getters;
  //   let payload = null
  //   let queryString = null

  //   //Shortlist Status
  //   if(params.status_id) {
  //     payload = {
  //       doh_candidate_status___customer_id: getCustomerId,
  //       status_id: params.status_id,
  //       doh_candidate_status___expired: false
  //     };
  //     queryString = getFilterQueryStringWithoutArray(payload);
  //     customer.getCandidateCountByStatus(params.status).then(res => {
  //       let { data } = res;
  //       commit("SET_DASHBOARD_METRICS",{ status: params.status, value: data})
  //     })
  //     .catch(err => {
  //       console.error("error in Candidate Details", err);
  //       return err;
  //     });
  //   }
  // },

  getCandidateExpiryCountsByStatus({ state, commit, getters }, params) {
    let { getCustomerId } = getters;
    let payload = null
    let queryString = null

    payload = {
      doh_candidate_status___customer_id: getCustomerId,
      status_id: params.status.status_id,
      days_to_expire__gt: params.expiry_gt,
      days_to_expire__lte: params.expiry_lte
    }
    queryString = getFilterQueryStringWithoutArray(payload);
    customer.allCandidates(queryString).then(async res => {
      let { data } = res;
      commit("SET_CANDIDATE_EXPIRY_COUNT", {
        expire: params.expiry_lte,
        length: data.length,
        status: params.status.status
      })
    });
  },

  getTotalSelectedCandidatesCount({ state, commit, getters }) {
    let { getCustomerId, getallshortliststatus, getRoleScopes } = getters;
    let payload = {
      doh_candidate_status___customer_id: getCustomerId,
      status_id: state.shortliststatus.status_id,
      doh_candidate_status___expired: false
    };
    if (payload.status_id === undefined) {
      payload.status_id = getallshortliststatus.status_id
    }
    let queryString = getFilterQueryStringWithoutArray(payload);
    let role = getRoleScopes.length ? getRoleScopes[0] : '';

    const finalPayload = {
      payload: queryString,
      role,
    }
    return customer.totalCandidates(finalPayload).then(res => {
      let { data } = res;
      commit("SET_SELECTED_CANDIDATE_TOTAL", data);
    })
      .catch(err => {
        console.error("error in Candidate Details", err);
        return err;
      });
  },
  candidateListPagination(
    { state, commit, rootState, dispatch, getters },
    payload
  ) {
    let { getCandidatePaginationLimit } = getters;
    commit("SET_CANDIDATE_LIST_PAGINATION", {
      skip: 0,
      noMoreDataFromCandidateList: false
    });

    dispatch("getCandidateList", {
      ...payload,
      limit: getCandidatePaginationLimit,
      skip: 0
    });
  },
  setSelectedCandidate({ state, commit, rootState, dispatch, getters }, data) {
    let { selectedJobId } = getters;
    if (selectedJobId !== data.job_id) {
      commit("SET_SELECTED_CANDIDATE", data);
    }
  },
  setSelectedcandidateById: async ({ state, commit, rootState, dispatch, getters }, candidateId) => {
    let { getCandidates, getBackCount, getshortlistcandidates, getinterviewlistcandidates, getrecruitedlistcandidates } = getters;
    let candidateDetailById;
    let candidateDetails;

    if (getCandidates.length && (getCandidates.findIndex(e => e.candidate_uid == candidateId)) >= 0 && getCandidates[getCandidates.findIndex(e => e.candidate_uid == candidateId)]) {
      candidateDetailById = getCandidates;
    }
    else if (getshortlistcandidates.length && (getshortlistcandidates.findIndex(e => e.candidate_uid == candidateId)) >= 0 && getshortlistcandidates[getshortlistcandidates.findIndex(e => e.candidate_uid == candidateId)]) {
      candidateDetailById = getshortlistcandidates;
    }
    else if (getinterviewlistcandidates.length && (getinterviewlistcandidates.findIndex(e => e.candidate_uid == candidateId)) >= 0 && getinterviewlistcandidates[getinterviewlistcandidates.findIndex(e => e.candidate_uid == candidateId)]) {
      candidateDetailById = getinterviewlistcandidates;
    }
    else if (getrecruitedlistcandidates.length && (getrecruitedlistcandidates.findIndex(e => e.candidate_uid == candidateId)) >= 0 && getrecruitedlistcandidates[getrecruitedlistcandidates.findIndex(e => e.candidate_uid == candidateId)]) {
      candidateDetailById = getrecruitedlistcandidates;
    }
    if (candidateDetailById) {
      candidateDetails = await dispatch("getCandidateDetails", candidateId);
      dispatch("commitCandidateInfo", candidateDetails);
    }
    else {
      candidateDetails = await dispatch("getCandidateDetails", candidateId);
    }
    return candidateDetails;
  },
  resetBackCount({ state, commit, rootState, dispatch, getters }) {
    commit("SET_BACK_COUNT", 0);
  },
  resetCandidateDetail({ commit, getters }) {
    commit("SET_CANDIDATE_DETAILS", {});
  },
  getallshortliststatus({ state, commit, rootState, dispatch, getters }) {
    return customer.allShortlistStatus().then(async res => {
      let { data } = res;
      for (var i = 0; i < data.length; i++) {
        if (data[i].status == "SHORTLIST")
          commit("SET_SHORTLIST_STATUS", data[i]);
        else if (data[i].status == "RELEASE")
          commit("SET_RELEASE_STATUS", data[i]);
        else if (data[i].status == "INTERVIEW")
          commit("SET_INTERVIEW_STATUS", data[i]);
        else if (data[i].status == "RECRUIT")
          commit("SET_RECRUIT_STATUS", data[i]);
      }
      return data;
    })
      .catch(err => {
        console.log("error in shortlist status", err);
        return err;
      });
  },
  fetchJobs({ state, commit, rootState, dispatch, getters }) {
    const { getRoleScopes } = getters;
    let role = getRoleScopes.length ? getRoleScopes[0] : '';
    return customer.getAllJobs(role).then(res => {
      let { data } = res;
      commit("SET_JOBS", data);
      return res;
    })
      .catch(err => {
        console.error("error fetching jobs", err);
        return err;
      });
  },
  setSelectedCandidateUsingId({ state, commit, rootState, dispatch, getters }, payload) {
    let id = payload;
    let { getCandidateListForJobsList } = getters;
    let selectedData = getCandidateListForJobsList.filter(item => item.candidate_uid == id)
    commit('SET_SELECTED_CANDIDATE_FOR_JOBS_LIST', selectedData[0])
  },
  updateCandidateInfoByID: async ({ commit, getters, dispatch }, candidate_uid) => {
    const { getCandidates, candidateProfile, getCustomerId } = getters;
    let customer_uid = getCustomerId;
    if (!customer_uid) {
      await dispatch("getCustomerIDByCandidateID", candidate_uid);
      const { getSelectedCandidateCustomerID } = getters;
      customer_uid = getSelectedCandidateCustomerID;
    }
    return customer
      .getCandidateDetail({ candidate_uid, customer_uid })
      .then(res => {
        if (getCandidates.length) {
          const CandidateIndex = getCandidates.findIndex(e => e.candidate_uid === candidate_uid);
          const CandidateInfo = res.data
          if (CandidateIndex >= 0) {
            commit("REPLACE_CANDIDATE_BY_INDEX", { CandidateIndex, CandidateInfo })
          }
        }
        if (candidate_uid === candidateProfile.candidate_uid)
          dispatch("commitCandidateInfo", res.data);
        return res.data;
      })
      .catch(err => {
        console.error("error in Candidate Details", err);
        return err;
      });
  },
  getCustomerIDByCandidateID: ({ getters, dispatch, commit }, candidate_uid) => {
    return customer
      .getCustomerIDByCandidateID(candidate_uid)
      .then(res => {
        const { data } = res;
        commit("SET_SELECTED_CANDIDATE_CUSTOMER_ID", data[0]);
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching CustomerID", err)
        return err
      })
  },
  fetchAllJobsByShortlistProcess({ getters, commit, dispatch }, candidate_id) {
    const { getCustomerId, getOrgIDFromAccessToken, isCandidateSupplierFromAccessToken, isUKMainAgency, getIsParentUser } = getters;
    commit("SET_FETCHING", true);
    let queryPayload = {}
    let query = ''
    if (getOrgIDFromAccessToken && !isUKMainAgency && !getIsParentUser)
      queryPayload = { ...queryPayload, organisation_uid: getOrgIDFromAccessToken }
    if (isCandidateSupplierFromAccessToken && getOrgIDFromAccessToken) {
      query = `job_allocation___allocated_organisation_id__in=${getOrgIDFromAccessToken}`;
    } else {
      query = getFilterQueryStringWithoutArray(queryPayload);
    }
    return jobBoard.fetchAllJobsByShortlistProcess({ candidate_id, query })
      .then(res => {
        const { data } = res;
        commit("SET_JOBS", data);
        return data;
      })
      .catch(err => {
        const message = "Error While Fetching Jobs for Candidate"
        dispatch("showToast", { class: 'bg-danger text-white', message });
        console.log(message, err);
        return err;
      })
      .finally(() => {
        commit("SET_FETCHING", false);
      })
  }
}

// mutations
const mutations = {
  ["SET_ALL_JOBS"](state, payload) {
    state.getAllJobs = payload
  },
  ["SET_ALL_CANDIDATE_JOB_STATUS"](state, payload) {
    state.getAllCandidateJobStatus = payload
  },
  ["SET_DASHBOARD_METRICS"](state, payload) {
    Vue.set(state.dashboardMetrics, payload.status, payload.value)
  },
  ["SET_CANDIDATE_EXPIRY_COUNT"](state, payload) {
    if (payload.status) {
      if (!state.candidateExpiryCount[payload.status]) {
        Vue.set(state.candidateExpiryCount, payload.status, {})
      }
      Vue.set(state.candidateExpiryCount[payload.status], `ageing_less_then_${payload.expire}_days`, payload.length)
    }
  },
  ["SET_SHORTLIST_CANDIDATES"](state, payload) {
    state.shortlistCandidates = payload;
  },
  ["SET_SHORTLIST_STATUS"](state, payload) {
    state.shortliststatus = payload;
  },
  ["SET_RELEASE_STATUS"](state, payload) {
    state.releasestatus = payload;
  },
  ["SET_INTERVIEW_STATUS"](state, payload) {
    state.interviewstatus = payload;
  },
  ["SET_RECRUIT_STATUS"](state, payload) {
    state.recruitstatus = payload;
  },
  ["SET_INTERVIEWLIST_CANDIDATES"](state, payload) {
    state.interviewlistCandidates = payload;
  },
  ["SET_RECRUITLIST_CANDIDATES"](state, payload) {
    state.recruitlistCandidates = payload;
  },
  ["SET_CANDIDATE_TOTAL"](state, payload) {
    state.candidateTotal = payload;
  },
  ["SET_CANDIDATE_DETAILS"](state, data) {
    state.candidateDetails = Object.assign(data, {
      title: data.title || {},
      speciality: data.speciality || {},
      sub_speciality: data.sub_speciality || {},
      candidate_level: data.candidate_level || {},
      nationality: data.nationality || {},
      doh_candidate_status: data.doh_candidate_status || null,
      main_qualification: data.main_qualification || {},
      gender: data.gender || { gender: "" },
      location: data.location || {},
      candidate_uid: data.candidate_uid || null,
      whatsapp: data.whatsapp || "",
      available: data.available || null,
      profile_picture: data.profile_picture || null,
      local_license:
        data.local_license == null
          ? "--"
          : data.local_license
            ? "Yes"
            : "No",
      dataflow_complete:
        data.dataflow_complete == null
          ? "--"
          : data.dataflow_complete
            ? "Yes"
            : "No",
      flexible:
        data.flexible == null ? "--" : data.flexible ? "Yes" : "No",
      extra_hours_available: data.extra_hours_available || "--",
      retired_clinician:
        data.retired_clinician == null
          ? "--"
          : data.retired_clinician == "Yes" || data.retired_clinician == true
            ? "Yes"
            : "No",
      volunteer:
        data.volunteer == null
          ? "--"
          : data.volunteer == "Yes" || data.volunteer == true
            ? "Yes"
            : "No"
    });
  },
  ["SET_SELECTED_CANDIDATE"](state, payload) {
    state.selectedCandidate = payload;
  },
  ["SET_CANDIDATE_LIST"](state, payload) {
    state.candidates = payload;
  },
  ["SET_IS_FETCHING_CANDIDATE"](state, data) {
    state.isFetchingCandidates = data;
  },
  ["SET_CANDIDATE_LIST_FILTER"](state, data) {
    state.candidateListFilter = data;
  },
  ["SET_CURRENT_NAVIGATION_PAGE"](state, data) {
    state.currentNavigationCandidatesPage = data
  },
  ["SET_CANDIDATE_DETAIL_PROFILE"](state, data) {
    state.profiles = data;
  },
  ["SET_CANDIDATE_LIST_PAGINATION"](state, data) {
    state.candidatePagination = { ...state.candidatePagination, ...data }
  },
  ['RESET_CANDIDATE_LIST'](state, data) {
    Object.assign(state, getDefaultState())
  },
  ["SET_BACK_COUNT"](state, data) {
    state.backcount = data;
  },
  ["SET_TOTAL_COUNT"](state, payload) {
    state.getTotalCount = payload;
  },
  ["SET_CANDIDATE_LIST_BACK_URL"](state, url) {
    state.fullPathCandidateListBackUrl = [...state.fullPathCandidateListBackUrl, url];
  },
  ["RESET_CANDIDATE_LIST_BACK_URL"](state) {
    state.fullPathCandidateListBackUrl = [];
  },
  ["REMOVE_CANDIDATE_LIST_BACK_URL"](state, url) {
    let _index = _.findLastIndex(state.fullPathCandidateListBackUrl, e => e === url);
    if (_index > -1)
      state.fullPathCandidateListBackUrl.splice(_index, 1)
  },
  ["UPDATE_CANDIDATE_LIST_BACK_URL"](state, data) {
    const { index, newBackURL } = data;
    let backURLList = state.fullPathCandidateListBackUrl;
    backURLList[index] = newBackURL;
    state.fullPathCandidateListBackUrl = [...backURLList];
  },
  ["SET_REASONS"](state, payload) {
    state.reasons = payload;
  },
  ["SET_JOBS"](state, payload) {
    state.jobs = payload;
  },
  ["SET_IS_FETCHING_CANDIDATE_DETAILS"](state, payload) {
    state.isFetchingCandidatesDetails = payload
  },
  ['REPLACE_CANDIDATE_BY_INDEX'](state, payload) {
    if (payload) {
      const { CandidateIndex, CandidateInfo } = payload;
      state.candidates[CandidateIndex] = CandidateInfo;
    }
  },
  ['SET_SELECTED_CANDIDATE_CUSTOMER_ID'](state, payload) {
    state.selectedCandidateCustomerID = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
